import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'
import zulip from 'zulip-js'
import { INotificationItem } from './notifications'
import { ISponsor } from './sponsors'

export interface IStream {
  name: string
  stream_id: number
}

export interface IPrivateRoom {
  avatar_url: string
  email: string
  full_name: string
  user_id: number
  is_active: boolean
}

export interface IRecipient {
  email: string
  full_name: string
  id: number
  is_mirror_dummy: boolean
}

export interface IMessage {
  avatar_url: string
  content: string
  id: number
  is_me_message: boolean
  sender_email: string
  recepient_id: number
  sender_full_name: string
  sender_id: number
  timestamp: number
  stream_id?: number
  display_recipient: IRecipient[]
  flags: string[]
  type?: string
}

export interface IZulipConfig {
  username: string
  apiKey: string
  realm: string
}

export interface IProfile {
  msg: string
  email: string
  user_id: number
  avatar_version: number
  is_admin: boolean
  is_owner: boolean
  is_guest: boolean
  is_bot: boolean
  full_name: string
  timezone: string
  is_active: boolean
  date_joined: string
  avatar_url: string
  profile_data: { [key: string]: string }
  max_message_id: number
}

export interface ISubscribeResult {
  already_subscribed?: { [key: string]: string[] }
  msg: string
  result: 'success' | 'error'
  subscribed?: { [key: string]: string[] }
  unauthorized?: string[]
}

export type EventType =
  | 'message'
  | 'subscription'
  | 'subscription op:add'
  | 'update_message_flags'
  | 'heartbeat' // here all zulip events

export interface IEvent {
  id: number
  type: EventType
  message?: IMessage
  subscriptions?: IStream[]

  [key: string]: any
}

export interface IRegisterQueueResponse {
  result: string
  queue_id: number
  last_event_id: number
  unread_msgs: {
    pms: Array<{
      sender_id: number
      unread_message_ids: number[]
    }>
  }
}

export interface IRetriveEventsResponse {
  result: string
  msg: string
  events: IEvent[]
  queue_id: string
}

export interface IFullPrivateRoom extends IPrivateRoom {
  isOwn: boolean
  sponsor?: ISponsor
  unreadCount: number
}

export interface IFullStream extends IStream {
  sponsor?: ISponsor
}

let justAddedTimeout: number = 0

export const isReaded = (message: IMessage) => (message.flags || []).includes('read')

const isVisibleSponsor = (visited: string[], sponsor?: ISponsor) =>
  sponsor && visited.includes(sponsor.id)

export default types
  .model('ChatStore', {
    // don't use this state var (only for sponsor page purpose)
    forceFullChatExpand: types.optional(types.boolean, false),
    // otherwise use action with this state var
    visibleChatWindow: types.optional(types.boolean, false),

    currentPrivateRoomId: types.optional(types.number, -1),
    currentStreamId: types.optional(types.number, -1),

    allStreams: types.frozen<IStream[]>([]),
    loadStreamsStatus: LoadingStatusType,
    streams: types.frozen<IStream[]>([]),

    loadPrivateRoomsStatus: LoadingStatusType,
    privateRooms: types.frozen<IPrivateRoom[]>([]),

    allMessages: types.frozen<IMessage[]>([]),
    loadMessagesStatus: LoadingStatusType,

    connection: types.frozen<any>(),
    connectionStatus: LoadingStatusType,

    sendStatus: LoadingStatusType,

    justAdded: types.optional(types.boolean, false),
  })

  .views((self) => ({
    privateRoom: (id: number) => {
      return self.privateRooms.find((room) => room.user_id === id)
    },
    stream: (id: number) => {
      return self.streams.find((stream) => stream.stream_id === id)
    },

    ownChatId: () => getParent<IRootStore>(self).appStore.currentUser.chatUserId,
  }))

  .views((self) => ({
    hiddenChatsIds: () =>
      (getParent<IRootStore>(self).appStore.currentUser.metadataObject.hiddenChats ||
        []) as number[],

    getPrivateRoomMessages: (id: number) => {
      const ownId = self.ownChatId()
      return self.allMessages.filter(
        (m) =>
          m.type === 'private' &&
          (m.display_recipient.find((r) => r.id === id && ownId !== r.id) ||
            (m.display_recipient.length === 1 &&
              m.display_recipient[0].id === ownId &&
              id === ownId))
      )
    },

    getStreamMessages: (id: number) =>
      self.allMessages.filter((m) => m.stream_id === id && m.type === 'stream'),

    showChatWindow: () => self.forceFullChatExpand || self.visibleChatWindow,
  }))

  .views((self) => ({
    unreadCountPrivateRoom: (id: number) => {
      const ownId = self.ownChatId()
      if (id === ownId) {
        return 0
      }
      return self.getPrivateRoomMessages(id).filter((m) => !isReaded(m)).length
    },
    unreadCountStream: (id: number) =>
      self.getStreamMessages(id).filter((m) => !isReaded(m)).length,
  }))

  .views((self) => ({
    visiblePrivateRooms: (activePrivateRoom: number) => {
      const ownId = self.ownChatId()
      const visited = getParent<IRootStore>(self).sponsorsStore.visitedSponsors()

      return self.privateRooms
        .map((room) => {
          const unreadCount = self.unreadCountPrivateRoom(room.user_id)
          const isOwn = room.user_id === ownId
          const isActive = room.user_id === activePrivateRoom
          const sponsor = getParent<IRootStore>(
            self
          ).sponsorsStore.getSponsorByRepresentativeChatId(room.user_id)
          const visibleSponsor = isVisibleSponsor(visited, sponsor)
          const visibleRoom = !self.hiddenChatsIds().includes(room.user_id)
          const representative = sponsor
            ? getParent<IRootStore>(self).sponsorsStore.assignedRepresentative(sponsor.id)
            : undefined
          const hasOtherRepresentative =
            representative && representative.chatUserId !== room.user_id

          const show =
            isActive ||
            isOwn ||
            unreadCount > 0 ||
            (visibleRoom && visibleSponsor && !hasOtherRepresentative)

          if (show) {
            return {
              ...room,
              isOwn,
              sponsor,
              unreadCount,
            }
          }

          return null
        })
        .filter((r) => r) as IFullPrivateRoom[]
    },

    visibleStreams: (activeStream: number) => {
      const visited = getParent<IRootStore>(self).sponsorsStore.visitedSponsors()

      return self.streams
        .map((stream) => {
          const sponsor = getParent<IRootStore>(self).sponsorsStore.getSponsorByChatName(
            stream.name
          )
          const visible = isVisibleSponsor(visited, sponsor)
          const isActive = stream.stream_id === activeStream
          const isGeneral = stream.name === 'general'

          if (isGeneral || isActive || visible) {
            return {
              ...stream,
              sponsor,
            }
          }

          return null
        })
        .filter((r) => r) as IFullStream[]
    },
  }))

  .actions((self) => ({
    setChatWindowVisibility: (v: boolean) => (self.visibleChatWindow = v),
    setForceFullChatExpand: (v: boolean) => (self.forceFullChatExpand = v),

    setJustAdded: (value: boolean) => (self.justAdded = value),
  }))

  .actions((self) => ({
    formatMessage(message: IMessage) {
      // maybe not action
      return {
        ...message,
        is_me_message: self.ownChatId() === message.sender_id,
      } as IMessage
    },
  }))

  .actions((self) => ({
    hideRoom: (roomId: number) => {
      const hidden = self.hiddenChatsIds()
      if (!hidden.includes(roomId)) {
        getParent<IRootStore>(self).appStore.updatePartialUserMetadata({
          hiddenChats: [...hidden, roomId],
        })
      }
    },
    showRoom: (roomId: number) => {
      const hidden = self.hiddenChatsIds()
      if (hidden.includes(roomId)) {
        getParent<IRootStore>(self).appStore.updatePartialUserMetadata({
          hiddenChats: hidden.filter((id) => id !== roomId),
        })
      }
    },
  }))

  .actions((self) => ({
    setCurrentStream: (id: number) => {
      self.currentStreamId = id
      self.currentPrivateRoomId = -1
    },

    setCurrentPrivateRoom: (id: number) => {
      self.currentStreamId = -1
      self.currentPrivateRoomId = id
      self.showRoom(id)
    },
  }))

  .actions((self) => {
    return {
      fetchStreams: createAxiosAction(
        flow(function*() {
          const { streams } = (yield self.connection.streams.retrieve()) as {
            streams: IStream[]
          }
          self.allStreams = streams

          const { subscriptions } = (yield self.connection.streams.subscriptions.retrieve()) as {
            subscriptions: IStream[]
          }
          const general = streams.find((s) => s.name === 'general')
          if (general && !subscriptions.find((s) => s.name === 'general')) {
            subscriptions.unshift(general)
          }
          self.streams = subscriptions
        }),
        (s) => (self.loadStreamsStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to load streams (chats)')
      ),

      fetchPrivateRooms: createAxiosAction(
        flow(function*() {
          const { members } = (yield self.connection.users.retrieve()) as {
            members: IPrivateRoom[]
          }
          self.privateRooms = members.filter((m) => m.is_active)
        }),
        (s) => (self.loadPrivateRoomsStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to load private rooms (chats)')
      ),

      sendMessage: createAxiosAction(
        flow(function*(to: string | number, type: string, subject: string, content: string) {
          yield self.connection.messages.send({
            content,
            subject,
            to,
            type,
          })
        }),
        (s) => (self.sendStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to send message')
      ),

      subscribe: createAxiosAction(
        flow(function*(stream: IStream) {
          if (self.streams.find((s) => s.stream_id === stream.stream_id)) {
            return
          }

          self.streams = [...self.streams, stream]
          yield self.connection.users.me.subscriptions.add({
            subscriptions: JSON.stringify([{ name: stream.name }]),
          })
        }),
        () => void 0,
        () => getParent<IRootStore>(self).showError('Failed to subscribe to stream')
      ),

      unsubscribe: createAxiosAction(
        flow(function*(stream: IStream) {
          yield self.connection.users.me.subscriptions.remove({
            subscriptions: JSON.stringify([stream.name]),
          })
          self.streams = self.streams.filter((s) => s.stream_id !== stream.stream_id)
        }),
        () => void 0,
        () => getParent<IRootStore>(self).showError('Failed to unsubscribe to stream')
      ),

      sendHelloMessage: flow(function*(representativeId: string) {
        yield axios.post(`/attendee/chat/welcome-message?representativeId=${representativeId}`)
      }),
    }
  })

  .actions((self) => ({
    addChatNotification: (message: IMessage, shouldShowNotification?: boolean) => {
      clearTimeout(justAddedTimeout)
      self.setJustAdded(true)
      justAddedTimeout = setTimeout(() => self.setJustAdded(false), 1200)

      const newNotification: INotificationItem = {
        date: new Date().toISOString(),
        id: `${message.id}`,
        is_new: shouldShowNotification,
        message: `New message from ${message.sender_full_name}`,
        sender_id: message.sender_id,
        type: 'Chat',
      }

      getParent<IRootStore>(self).notificationsStore.appendChatItem(newNotification)
    },
  }))

  .actions((self) => ({
    appendMessage(message: IMessage, shouldShowNotification?: boolean) {
      const added = self.allMessages.find((m) => m.id === message.id)
      const ownId = self.ownChatId()
      const ownMessage = message.sender_id === ownId

      if (!added) {
        // dirty but ok
        const inList = self.privateRooms.find((u) => u.user_id === message.sender_id)
        if (!ownMessage && !isReaded(message) && message.type === 'private' && inList) {
          self.addChatNotification(message, shouldShowNotification)
        }

        self.allMessages = [...self.allMessages, { ...message, is_me_message: ownMessage }]
      }
    },
  }))

  .actions((self) => ({
    handleEvent: (event: IEvent) => {
      if (event.type === 'message' && event.message) {
        self.appendMessage(event.message, true)
      }
      // handle other events here
    },
  }))

  .actions((self) => ({
    initConnection: createAxiosAction(
      flow(function*(config: IZulipConfig) {
        self.connection = yield zulip(config)
        self.connection.callOnEachEvent(self.handleEvent)
      }),
      (s) => (self.connectionStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to init chat connection')
    ),
  }))

  .actions((self) => ({
    fetchMessages: createAxiosAction(
      flow(function*(type?: string, id?: number | number[]) {
        const { messages } = (yield self.connection.messages.retrieve({
          anchor: 'first_unread',
          apply_markdown: false,
          narrow: type ? [{ operand: id, operator: type }] : [],
          num_after: 200,
          num_before: 200,
        })) as { messages: IMessage[] }
        messages.forEach((m) => self.appendMessage(m))
      }),
      (s) => (self.loadMessagesStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch messages')
    ),

    markAsReaded: flow(function*(ids: number[]) {
      ids.forEach((id) => getParent<IRootStore>(self).notificationsStore.removeChatItem(`${id}`))
      self.allMessages = self.allMessages.map((m) => {
        return ids.includes(m.id)
          ? {
              ...m,
              flags: [...(m.flags || []), 'read'],
            }
          : m
      })
      yield self.connection.messages.flags.add({ flag: 'read', messages: ids })
    }),
  }))

  .actions((self) => ({
    fetchPrivateRoomMessages: (id: number) => self.fetchMessages('pm-with', [id]),
    fetchStreamMessages: (id: number) => self.fetchMessages('stream', id),
  }))
