import React from 'react'
import Lazyload from 'react-lazyload'
import { Link } from 'react-router-dom'
import { ISponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'

const Container = styled.div`
  position: relative;
  text-align: center;
  padding: 0 20px;
  max-width: 1300px;
  margin: auto;
`

const SponsorsTitle = styled.h2`
  margin: 0 auto;
  padding: 30px 0;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
`

const SponsorsList = styled.ul`
  margin: 0 -10px;
  padding: 0 0 40px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const SponsorItem = styled.li`
  margin: 0 0 20px;
  padding: 0 10px;
  width: 25%;
  height: 130px;
  position: relative;

  ${tablet} {
    width: 50%;
  }
`

const SponsorTitle = styled.p`
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  position: absolute;
  top: -15px;
  background: white;
  border: 1px solid #00b9b4;
  color: #00b9b4;
  border-radius: 3px;
  padding: 0 10px;
  opacity: 0;
  max-width: 100%;
  display: block;
  transition: opacity 0.15s;
`

const SponsorLink = styled(Link)`
  margin: 0;
  padding: 15px 10px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: rgb(235 241 243);
  border: 1px solid rgb(206 218 222);
  transition: border-color 0.15s;
  border-radius: 10px;

  img {
    margin: 0 auto;
    width: auto;
    max-width: 70%;
    max-height: 100px;
    transition: transform 0.15s;
    transform: scale(1);
  }

  &:hover {
    border-color: #00b9b4;

    ${SponsorTitle} {
      opacity: 1;
    }
  }

  &:hover img {
    transform: scale(1.2);
  }
`

interface ISponsorsBannersProps {
  sponsors: ISponsor[]
  title: string
}

const SponsorCard = ({ sponsor }: { sponsor: ISponsor }) => (
  <SponsorItem>
    <SponsorLink to={`/sponsor/${sponsor.id}/`}>
      <Lazyload once offset={300}>
        <img src={sponsor.logoUrl} alt={sponsor.company} />
      </Lazyload>
      <SponsorTitle>{sponsor.company}</SponsorTitle>
    </SponsorLink>
  </SponsorItem>
)

export default function SponsorsBanners({ sponsors, title }: ISponsorsBannersProps) {
  if (!sponsors.length) {
    return null
  }

  return (
    <Container>
      <SponsorsTitle>{title}</SponsorsTitle>
      <SponsorsList>
        {sponsors
          .filter((s) => s.status === 'Published')
          .map((sponsor) => (
            <SponsorCard key={sponsor.id} sponsor={sponsor} />
          ))}
      </SponsorsList>
    </Container>
  )
}
