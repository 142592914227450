import _dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

export const dayjs = _dayjs

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
