import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'
import { AUTH_TYPE, SAML_URL } from 'utils/config'

export default observer(() => {
  const { accessToken } = useRootData((store) => store.appStore)
  const history = useHistory()

  useEffect(() => {
    if (accessToken) {
      history.replace('/')
    }
    // eslint-disable-next-line
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) {
      if (AUTH_TYPE === 'saml_no_request') {
        window.location.href = SAML_URL
      } else {
        console.error('Auth type', AUTH_TYPE, 'not supported. Use saml_no_request instead!')
      }
    }
    // eslint-disable-next-line
  }, [])

  return <Wrapper>We are redirecting you to the auth provider</Wrapper>
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
`
