import { types } from 'mobx-state-tree'

import agendaStore from './agenda'
import appStore from './app'
import chatStore from './chat'
import faqStore from './faq'
import notificationsStore from './notifications'
import settingsStore from './settings'
import sponsorsStore from './sponsors'
import swagbagStore from './swagbag'

export default types
  .model('RootStore', {
    agendaStore: types.optional(agendaStore, {}),
    appStore: types.optional(appStore, {}),
    chatStore: types.optional(chatStore, {}),
    faqStore: types.optional(faqStore, {}),
    notificationsStore: types.optional(notificationsStore, {}),
    settingsStore: types.optional(settingsStore, {}),
    sponsorsStore: types.optional(sponsorsStore, {}),
    swagbagStore: types.optional(swagbagStore, {}),
  })
  .actions((self) => ({
    showError: (message: string, delay?: number) => {
      self.appStore.showError(message, delay)
    },
    showMessage: (message: string, delay?: number) => {
      self.appStore.showMessage(message, delay)
    },
  }))
