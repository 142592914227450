import { observer } from 'mobx-react-lite'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { tablet } from 'utils/responsive'

const FooterWrapper = styled.div`
  min-height: calc(100vh - 60px);
  padding-bottom: 35px;
  position: relative;

  ${tablet} {
    min-height: calc(100vh - 50px);
  }
`

const Footer = styled.footer`
  background-color: #456dde;
  padding: 0 10px;
  text-align: center;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  left: 0;
  bottom: 0;
  width: 100%;

  a {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-shadow: 0 0 1px #fff;
    }
  }
`

export default observer(({ children }) => {
  return (
    <FooterWrapper>
      {children}
      <Footer>
        <a href="https://nextv.io/" target="_blank" rel="noopener noreferrer">
          Solution by NextV
        </a>
      </Footer>
    </FooterWrapper>
  )
})
