import {
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponse,
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseAgendaSession,
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseLocation,
  IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseSpeaker,
} from 'api-types'
import { AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatusType } from 'utils/store'

export type ISessionsResponse = IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponse

export type ISessionItem = IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseAgendaSession
export type ILocationItem = IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseLocation
export type IAgendaSpeaker = IAbstractionsContractsQueriesAgendaSessionsAgendaSessionsWithPublishedSpeakersResponseSpeaker

export default types
  .model('AgendaStore', {
    fetchSessionsStatus: LoadingStatusType,
    sessions: types.frozen<ISessionItem[]>([]),
  })

  .actions((self) => ({
    fetchSessions: createAxiosAction(
      flow(function*(force = true) {
        if (!force && self.sessions.length > 0) {
          return
        }

        const { data } = (yield axios.get(`/public/agenda/sessions?${allQuery}`)) as AxiosResponse<
          ISessionsResponse
        >
        self.sessions = data.agendaSessions
      }),
      (s) => (self.fetchSessionsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch sessions')
    ),
  }))
