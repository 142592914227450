import React from 'react'
import { Link } from 'react-router-dom'
import { ICategoryWithSponsors } from 'stores/sponsors'
import styled from 'styled-components'

export const CategoryCard = ({ category }: { category: ICategoryWithSponsors }) => (
  <CategoryWrapper to={`/category/${category.id}/`}>
    <CategoryCount>{category.sponsors.length}</CategoryCount>
    <CategoryTitle>{category.displayName}</CategoryTitle>
  </CategoryWrapper>
)

export default CategoryCard

const CategoryWrapper = styled(Link)`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #000;
  }
`

const CategoryCount = styled.span`
  width: 40px;
  height: 40px;
  background: #777;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  margin-right: 10px;
  border-radius: 50%;
`

const CategoryTitle = styled.span`
  font-weight: bold;
`
