import React, { memo } from 'react'
import { Play } from 'react-feather'
import { ISponsorContentItem } from 'stores/sponsors'
import { ISwagItem } from 'stores/swagbag'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'
import AddToGoodyBag from './AddToGoodyBag'

interface IVideoCardProps {
  addSwagItem: (item: ISwagItem) => Promise<any>
  video: ISponsorContentItem
  onVideoOpen?: (video: ISponsorContentItem) => any
  addedToSwag: boolean
}

export default memo(({ video, addSwagItem, onVideoOpen, addedToSwag }: IVideoCardProps) => {
  return (
    <CardWrapper>
      <Video
        className="ga_video_click"
        onClick={() => {
          return onVideoOpen ? onVideoOpen(video) : null
        }}
        data-ga-id={video.id}
      >
        {video.thumbnail && <img src={video.thumbnail} alt={video.title} />}
        <Play color="#fff" size="48" />
      </Video>
      <Details>
        <Title>{video.title}</Title>
        <AddToGoodyBag
          gaClass="ga_bag_video_click"
          onClick={addedToSwag ? undefined : () => addSwagItem(video)}
          addedToSwag={addedToSwag}
          data-ga-id={video.id}
        />
      </Details>
    </CardWrapper>
  )
})

const CardWrapper = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  overflow: hidden;

  ${tablet} {
    max-width: 50%;
  }
`

const Video = styled.div`
  position: relative;
  cursor: pointer;
  opacity: 0.8;
  background-color: #ccc;
  transition: opacity 0.15s;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  &:hover {
    opacity: 1;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 5px;
  margin: 10px 0 0;
  align-items: flex-start;
`

const Title = styled.div`
  margin: 0 0 15px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-weight: 600;
  text-overflow: ellipsis;

  ${tablet} {
    font-size: 14px;
  }
`
