import React, { memo } from 'react'
import { Briefcase } from 'react-feather'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'

interface IAddToGoodyBagProps {
  gaClass: string
  onClick: undefined | (() => void)
  addedToSwag: boolean
}

export default memo(({ gaClass, onClick, addedToSwag }: IAddToGoodyBagProps) => (
  <Add
    className={`${gaClass} ${addedToSwag && 'disabled'}`}
    onClick={onClick}
    data-tooltip={addedToSwag ? 'Already added to Goody Bag' : 'Add to Goody Bag'}
  >
    <Briefcase color="#fff" />
    <span>Goody Bag</span>
  </Add>
))

const Add = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  background-color: #3c62d9;
  border-radius: 20px;
  padding: 0 20px;
  height: 40px;

  span {
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
    top: 1px;
    position: relative;
  }

  &.disabled {
    background-color: #ccc;
    cursor: default;
  }

  ${tablet} {
    padding: 0 15px;
  }
`
