import { useEffect } from 'react'
import { useRootData } from '../stores'
import { LoadingStatus } from '../utils/store'

export default () => {
  const swagbag = useRootData((store) => store.swagbagStore)

  useEffect(() => {
    if (swagbag.loadingStatus === LoadingStatus.not_loaded) {
      swagbag.fetchItems()
    }
  }, [swagbag.fetchItems, swagbag.loadingStatus, swagbag])

  return swagbag
}
