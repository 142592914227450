import BoothesWithSidePanel from 'components/BoothesWithSidePanel'
import LiveAgenda from 'components/LiveAgenda'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import ErrorPage from 'pages/Error'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import { LoadingStatus } from 'utils/store'

export default observer(() => {
  const { floorId = '' } = useParams()

  if (!floorId) {
    return <ErrorPage />
  }

  useEffect(() => {
    if (loadingSponsorsStatus !== LoadingStatus.success) {
      fetchFullSponsors()
    }
    // eslint-disable-next-line
  }, [])

  const { fullSponsorsLoadingStatus, fullFloor, fetchFullSponsors } = useRootData(
    (store) => store.sponsorsStore
  )
  const floor = fullFloor(floorId)!
  const loadingSponsorsStatus = fullSponsorsLoadingStatus()

  if (loadingSponsorsStatus === LoadingStatus.success && !floor) {
    return <ErrorPage />
  }

  return (
    <>
      <ContentLoader
        loadingStatus={loadingSponsorsStatus}
        error="Failed to load info about sponsor"
      >
        {() => <BoothesWithSidePanel title={floor.title} allSponsors={floor.sponsors} />}
      </ContentLoader>
      <LiveAgenda />
    </>
  )
})
