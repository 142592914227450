import ExhibitorsIndex from 'components/ExhibitorsIndex'
import ModesSelect from 'components/ModesSelect'
import PerspectiveView from 'components/PerspectiveView'
import SponsorsBoothes from 'components/SponsorsBoothes'
import fuzzy from 'fuzzysort'
import React, { useMemo, useState } from 'react'
import { Box, Grid, Square } from 'react-feather'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components'
import useWindowDimensions from 'utils/dimensions'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'

interface IProps {
  title: string
  allSponsors: IFullSponsor[]
}

export default function BoothesWithSidePanel({ title, allSponsors }: IProps) {
  const [name, setName] = useState('')
  // tslint:disable-next-line:variable-name
  const [mode, _setMode] = useState(localStorage.vrExhibitorsMode || '2d')
  const { width } = useWindowDimensions()
  const isMobile = width <= 1000

  const setMode = (mode2: string) => {
    localStorage.vrExhibitorsMode = mode2
    _setMode(mode2)
  }

  const sponsors = useMemo(() => {
    const filtered = allSponsors
      .filter((s) => s.status === 'Published')
      .map((s) => ({ ...s, categories: s.sponsorTags.map((c) => c.displayName).join(', ') }))
    return name.length > 0
      ? fuzzy.go(name, filtered, { keys: ['company', 'categories'] }).map((e) => e.obj)
      : filtered
  }, [allSponsors, name])

  usePreventBodyScroll(true)

  return (
    <MainContent>
      <BannersWrapper>
        {['2d', '2d-large'].indexOf(mode) > -1 && (
          <SponsorsBoothes large={mode === '2d-large'} sponsors={sponsors} title={title} />
        )}
        {mode === '3d' && <PerspectiveView foundSponsors={sponsors} allSponsors={allSponsors} />}
      </BannersWrapper>

      <ModesSelect
        modes={[
          { name: '2d-large', icon: Square },
          { name: '2d', icon: Grid },
          { name: '3d', icon: Box },
        ]}
        mode={mode}
        setMode={setMode}
      />

      {!isMobile && (
        <ExhibitorsIndexWrapper>
          <Title>Exhibitors Index</Title>
          <SearchInput
            type="text"
            placeholder="Search by name or category"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <ExhibitorsScroll>
            <ExhibitorsIndex sponsors={sponsors!} />
          </ExhibitorsScroll>
        </ExhibitorsIndexWrapper>
      )}
    </MainContent>
  )
}

const ExhibitorsIndexWrapper = styled.div`
  width: 350px;
  flex-shrink: 0;
  height: 100%;
  padding: 0 20px;
`

const ExhibitorsScroll = styled.div`
  height: calc(100% - 95px);
  overflow-y: auto;
`

const SearchInput = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 0 10px;
`

const Title = styled.h1`
  font-weight: 900;
  font-size: 20px;
  height: 45px;
  line-height: 50px;
`

const MainContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 60px);

  ${tablet} {
    height: calc(100vh - 50px);
  }
`

const BannersWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  position: relative;
`
