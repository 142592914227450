import { css, keyframes } from 'styled-components'

export default (color?: string) => {
  const bgColor = '#d4d4d4'
  color = color || 'red'
  const blink = keyframes`
    0% {
      filter: drop-shadow(0 0 3px ${bgColor});
      stroke: ${color};
    }
    50% {
      filter: drop-shadow(0 0 0px ${bgColor});
      stroke: white;
    }
    100% {
      filter: drop-shadow(0 0 3px ${bgColor});
      stroke: ${color};
    }
  `

  return css`
    animation: ${blink} 0.5s linear infinite;
  `
}
