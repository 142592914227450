import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { CornerDownLeft, FilePlus, Plus, Video } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import { useRootData } from 'stores'
import { IPrivateRoom, isReaded, IStream } from 'stores/chat'
import styled from 'styled-components/macro'
import { CHAT_REALM } from 'utils/config'
import niceShadow from 'utils/niceShadow'
import { tablet } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'
import uuid from 'uuid'
import { Message } from './Message'

interface IChannelMessagesProps {
  privateRoom?: IPrivateRoom
  stream?: IStream
  isVisibleWindow: boolean
}

export default observer(({ privateRoom, stream, isVisibleWindow }: IChannelMessagesProps) => {
  const [message, setMessage] = useState('')
  const [visibleFor, setVisibleFor] = useState<any>(null)
  const [showActionsPopup, setShowActionsPopup] = useState(false)
  const messagesRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const hidden = visibleFor !== (privateRoom || stream)

  const {
    getStreamMessages,
    getPrivateRoomMessages,
    fetchPrivateRoomMessages,
    fetchStreamMessages,
    sendHelloMessage,
    loadMessagesStatus,
    sendMessage,
    markAsReaded,
    connection,
  } = useRootData((store) => store.chatStore)
  const { representativeByUserId } = useRootData((store) => store.sponsorsStore)

  const streamMessages = stream ? getStreamMessages(stream.stream_id) : []
  const privateMessages = privateRoom ? getPrivateRoomMessages(privateRoom.user_id) : []
  const messages = (stream ? streamMessages : privateMessages) || []
  const privateRoomId = privateRoom ? privateRoom.user_id : -1

  useEffect(() => {
    if (privateRoomId > -1) {
      fetchPrivateRoomMessages(privateRoomId)
    }
    // eslint-disable-next-line
  }, [privateRoomId])

  useEffect(() => {
    if (stream) {
      fetchStreamMessages(stream.stream_id)
    }
    // eslint-disable-next-line
  }, [stream])

  useEffect(() => {
    const ids = messages.filter((m) => !isReaded(m)).map((m) => m.id)
    if (ids.length > 0 && isVisibleWindow) {
      markAsReaded(ids)
    }
    // eslint-disable-next-line
  }, [messages, isVisibleWindow])

  useEffect(() => {
    const div = messagesRef.current
    if (div && loadMessagesStatus === LoadingStatus.success) {
      setVisibleFor(privateRoom || stream)
      div.scrollTo({
        top: div.scrollHeight,
      })
    }
  }, [loadMessagesStatus, privateRoom, privateMessages, stream, setVisibleFor])

  useEffect(() => {
    if (!hidden && privateRoomId > -1 && messages.length < 1) {
      const representative = representativeByUserId(privateRoomId)
      if (representative) {
        sendHelloMessage(representative.id)
      }
    }
    // eslint-disable-next-line
  }, [hidden, privateRoomId])

  useEffect(() => {
    const div = messagesRef.current!
    div.scrollTop = div.scrollHeight
  }, [message, messagesRef])

  const textChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(e.currentTarget.value)
    },
    [setMessage]
  )

  const sendChatMessage = useCallback(async () => {
    if (!stream && !privateRoom) {
      return
    }

    if (stream) {
      await sendMessage(stream.name, 'stream', stream.name, message)
    } else if (privateRoom) {
      await sendMessage(privateRoom.email, 'private', privateRoom.full_name, message)
    }

    setMessage('')
  }, [message, stream, privateRoom, sendMessage])

  const keyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.keyCode === 13 && !(event.ctrlKey || event.metaKey || event.shiftKey)) {
        sendChatMessage()
        event.preventDefault()
      }
    },
    [sendChatMessage]
  )

  const attachFile = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      if (connection && event.currentTarget.files) {
        const file = event.currentTarget.files[0]
        const { uri } = await connection.callEndpoint('/user_uploads', 'POST', {
          [file.name]: file,
        })
        setMessage(`${message} [${file.name}](${CHAT_REALM}${uri})`)
        setShowActionsPopup(false)
      }
    },
    [connection, message]
  )

  const clickVideoChat = () => {
    const link = 'https://meet.jit.si/' + uuid()
    setMessage(`${message} [Join video chat](${link})`)
    setShowActionsPopup(false)
  }

  return (
    <ChatWindowWrapper>
      <ChatWindowMessages ref={messagesRef} className={hidden ? 'hidden' : ''}>
        {messages.length === 0 ? (
          <ChatWindowPlaceholder>No messages</ChatWindowPlaceholder>
        ) : (
          messages.map((message2) => (
            <Message message={message2} key={message2.id} realm={CHAT_REALM} />
          ))
        )}
      </ChatWindowMessages>

      <ChatWindowCompose title="Press ctrl + enter to send message">
        <input type="file" style={{ display: 'none' }} ref={inputFileRef} onChange={attachFile} />
        <MoreActionsWrapper>
          <MoreActionsButton onClick={() => setShowActionsPopup(!showActionsPopup)}>
            <Plus />
          </MoreActionsButton>
          <OutsideClickHandler onOutsideClick={() => setShowActionsPopup(false)}>
            <MoreActionsPopup show={showActionsPopup}>
              <ActionButton onClick={() => inputFileRef?.current?.click()}>
                <FilePlus size="25" /> Attach file
              </ActionButton>
              <ActionButton onClick={clickVideoChat}>
                <Video className="video-icon" size="25" /> Video chat
              </ActionButton>
            </MoreActionsPopup>
          </OutsideClickHandler>
        </MoreActionsWrapper>
        <ChatWindowComposeInput
          async={true}
          value={message}
          onKeyDown={keyDown}
          onChange={textChange}
          ref={inputRef}
        />
        <ChatWindowComposeSend onClick={sendChatMessage} disabled={message.trim() === ''}>
          <CornerDownLeft size="18" />
        </ChatWindowComposeSend>
      </ChatWindowCompose>
    </ChatWindowWrapper>
  )
})

const ChatWindowMessages = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1 1 auto;

  &.hidden {
    opacity: 0;
  }
`

const ChatWindowPlaceholder = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ccc;
`

const ChatWindowWrapper = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;

  ${tablet} {
    width: 100vw;
  }

  .fullChat & {
    width: 100%;
  }
`

const ChatWindowCompose = styled.div`
  position: relative;
  border-top: 1px solid #ccc;
  max-height: 50%;
`

const ChatWindowComposeInput = styled(TextareaAutosize)`
  margin: 0;
  padding: 10px 45px 10px 45px;
  width: 100%;
  min-height: 44px;
  background-color: #fff;
  border: none;
  resize: none;
  appearance: none;
  max-height: 100%;
  overflow: hidden !important;
`

const Button = styled.button`
  position: absolute;
  bottom: 4px;
  width: 38px;
  height: 38px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  color: #fff;
  background-color: #456dde;
  background-clip: padding-box;
  border: 5px solid #fff;
  border-radius: 8px;
  cursor: pointer;

  &:disabled {
    background-color: #f3f3f3;
    color: #ccc;
    cursor: default;

    svg {
      stroke: #c2c2c2;
    }
  }
`

const ChatWindowComposeSend = styled(Button)`
  right: 4px;
`

const MoreActionsWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
`

const MoreActionsButton = styled(Button)`
  background-color: transparent;
  border: none;

  svg {
    stroke: #c2c2c2;
  }

  &:hover {
    svg {
      stroke: #456dde;
    }
  }
`

const MoreActionsPopup = styled.div<{ show?: boolean }>`
  ${niceShadow}
  position: absolute;
  left: 0;
  bottom: 100%;
  background: white;
  width: 180px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  ${({ show }) =>
    show
      ? `
        visibility: visible;
        opacity: 1;
      `
      : ''}
`

const ActionButton = styled.button`
  width: 100%;
  background: transparent;
  display: flex;
  text-align: left;
  align-items: center;
  border: none;
  cursor: pointer;
  height: 40px;
  color: #333;

  svg {
    margin-right: 10px;
  }

  .video-icon {
    padding-left: 3px;
  }

  &:hover {
    color: #000;
  }
`
