import { getLayout, renderSponsorBooth } from 'components/Booth3D'
import { chunk } from 'lodash'
import React, { MutableRefObject, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'
import * as THREE from 'three'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { environment, traverseMaterials } from 'utils/GltfViewer'
import MapControls from 'utils/MapControls'
import niceShadow from 'utils/niceShadow'

const loader = new GLTFLoader()
const hallUrl = '/3d/Hall Large.glb'
const logoUrl = '/texture.png'

const hGeometry = new THREE.TorusGeometry(10, 0.6, 16, 100)
const hMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 })
const hoverObject = new THREE.Mesh(hGeometry, hMaterial)
hoverObject.rotation.set(Math.PI / 2, 0, 0)

const getWH = (container: HTMLDivElement) => {
  const { width, height } = container.getBoundingClientRect()
  return [width, height]
}

const getId = (name: string) => {
  const [, id] = name.split('_')
  return id
}

const getCenter = (obj: THREE.Object3D) => {
  const bbox = new THREE.BoxHelper(obj)
  bbox.geometry.computeBoundingBox()
  const { min, max } = bbox.geometry.boundingBox!
  const cx = (min.x + max.x) / 2
  const cy = (min.y + max.y) / 2
  const cz = (min.z + max.z) / 2
  return [cx, cy, cz]
}

interface IProps {
  allSponsors: IFullSponsor[]
  foundSponsors: IFullSponsor[]
}

export default function PerspectiveView({ allSponsors, foundSponsors }: IProps) {
  const sceneRef = useRef<THREE.Scene | undefined>(undefined)
  const foundSponsorsRef = useRef<IFullSponsor[]>([])
  foundSponsorsRef.current = foundSponsors
  const boothesRef = useRef<THREE.Object3D[]>([])

  const ref = useRef<HTMLDivElement>(null)
  const popup = useRef<HTMLDivElement>(null)
  const rafRef = useRef<any>(null) as MutableRefObject<any>
  const mouseStart = useRef<string>('') as MutableRefObject<string>
  const selectedObject = useRef<THREE.Object3D | null>(
    null
  ) as MutableRefObject<THREE.Object3D | null>
  const history = useHistory()

  const grid = useMemo(() => chunk(allSponsors, Math.round(Math.sqrt(allSponsors.length))), [
    allSponsors,
  ])

  const table = useMemo(() => {
    const t: { [key: string]: IFullSponsor } = {}
    for (const s of allSponsors) {
      t[s.id] = s
    }
    return t
  }, [allSponsors])

  useEffect(() => {
    const container = ref.current!
    const sponsorName = popup.current!
    let hall: any
    if (!container || !sponsorName) {
      return
    }

    const [width, height] = getWH(container)

    const renderer = new THREE.WebGLRenderer({ antialias: true })
    renderer.physicallyCorrectLights = true
    renderer.outputEncoding = THREE.sRGBEncoding
    renderer.setClearColor(0x8fbcd4)
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height)

    sceneRef.current = new THREE.Scene()
    const scene = sceneRef.current
    scene.background = new THREE.Color(0x8fbcd4)

    // const axis = new THREE.AxesHelper(1000)
    // scene.add(axis)

    const cameraOffset = new THREE.Vector3(-10, 10, 15)
    const camera = new THREE.PerspectiveCamera(50, width / height, 1, 2000)
    camera.position.set(cameraOffset.x, cameraOffset.y, cameraOffset.z)

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5)
    scene.add(ambientLight)

    scene.add(hoverObject)

    const light = new THREE.HemisphereLight(0xffffff, 0x080820, 1)
    scene.add(light)

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()

    new RGBELoader().setDataType(THREE.UnsignedByteType).load(environment, (t) => {
      const envMap = pmremGenerator.fromEquirectangular(t).texture
      pmremGenerator.dispose()

      scene.environment = envMap
      scene.background = null
    })

    const minPan = new THREE.Vector3(-40, -1000, -70)
    const maxPan = new THREE.Vector3(70, 1000, 40)

    const controls = new MapControls(camera, renderer.domElement, minPan, maxPan) as any
    controls.enableDamping = true
    controls.dampingFactor = 0.8
    controls.zoomSpeed = 5
    controls.enablePan = true
    controls.panSpeed = 2
    controls.keyPanSpeed = 30
    controls.enableRotate = false
    controls.minDistance = 5
    controls.maxZoom = 3
    controls.minZoom = 0.7
    controls.maxDistance = 60
    controls.target.set(0, 0, 0)
    controls.update()

    const textureLoader = new THREE.TextureLoader()
    const texture = textureLoader.load(logoUrl)
    texture.anisotropy = 16

    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('/draco/')
    loader.setDRACOLoader(dracoLoader)

    loader.load(hallUrl, (gltf) => {
      hall = gltf.scene
      hall.rotation.set(0, (Math.PI / 180) * 270, 0)
      hall.position.set(30, -10, -35)
      scene.add(hall)
    })

    const resolution = new THREE.Vector2(width, height)

    function onResize() {
      const [w, h] = getWH(container!)

      camera.aspect = w / h

      camera.updateProjectionMatrix()
      renderer.setSize(w, h)
      resolution.set(w, h)
    }

    const raycaster = new THREE.Raycaster()

    function getXY(event: any) {
      const { top, left } = container!.getBoundingClientRect()
      let x = event.clientX - left
      let y = event.clientY - top

      if (event.changedTouches) {
        x = event.changedTouches[0].pageX - left
        y = event.changedTouches[0].pageY - top
      }

      x = (x / width) * 2 - 1
      y = -(y / height) * 2 + 1
      return { x, y }
    }

    function onTouchMove(event: any) {
      const { x, y } = getXY(event)
      selectedObject.current = getHoveredBooth(x, y)
    }

    function onClickStart(event: any) {
      const { x, y } = getXY(event)
      mouseStart.current = `${x}_${y}`
    }

    function onClickEnd(event: any) {
      const { x, y } = getXY(event)
      if (mouseStart.current !== `${x}_${y}`) {
        return
      }
      const object = getHoveredBooth(x, y)

      if (object) {
        const [, id] = object.name.split('_')
        history.push(`/sponsor/${id}/`)
      }
    }

    const getBooth = (object: THREE.Object3D): THREE.Object3D | null => {
      if (!object.parent) {
        return null
      }
      if (object.parent === scene) {
        if (object.name && object.name.split('_')[0] === 'booth') {
          return object
        } else {
          return null
        }
      } else {
        return getBooth(object.parent)
      }
    }

    function getHoveredBooth(x: number, y: number) {
      const objects = []
      raycaster.setFromCamera(new THREE.Vector2(x, y), camera)

      const intersects = raycaster.intersectObject(scene, true)

      if (intersects.length > 0) {
        const b = getBooth(intersects[0].object)
        if (b) {
          objects.push(b)
        }
      }

      const booth = objects[objects.length - 1] as THREE.Object3D

      if (!booth) {
        return null
      }

      const id = getId(booth.name)
      if (foundSponsorsRef.current.find((s) => s.id === id)) {
        return booth
      }

      return null
    }

    function animate() {
      rafRef.current = requestAnimationFrame(animate)
      if (selectedObject.current) {
        const [cx, cy, cz] = getCenter(selectedObject.current)
        const id = getId(selectedObject.current.name)

        hoverObject.visible = true
        hoverObject.position.set(cx, cy, cz)
        const sponsor = table[id]
        if (sponsor) {
          sponsorName.innerHTML = sponsor.company
          sponsorName.setAttribute('style', 'display: block')
        }
      } else {
        hoverObject.visible = false
        sponsorName.setAttribute('style', 'display: none')
      }
      renderer.render(scene, camera)
    }

    container.appendChild(renderer.domElement)
    animate()

    window.addEventListener('resize', onResize)
    window.addEventListener('mousemove', onTouchMove)
    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('mousedown', onClickStart)
    window.addEventListener('touchstart', onClickStart)
    window.addEventListener('mouseup', onClickEnd)
    window.addEventListener('touchend', onClickEnd)

    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('mousemove', onTouchMove)
      window.removeEventListener('touchmove', onTouchMove)
      window.removeEventListener('mousedown', onClickStart)
      window.removeEventListener('mouseup', onClickEnd)
      window.removeEventListener('touchstart', onClickStart)
      window.removeEventListener('touchend', onClickEnd)

      container.removeChild(renderer.domElement)

      cancelAnimationFrame(rafRef.current)
      texture.dispose()
      while (scene.children.length) {
        const obj = scene.children[0]
        scene.remove(obj)
      }
      renderer.dispose()
      scene.dispose()
    }
    // eslint-disable-next-line
  }, [])

  const recomputeOpacity = () => {
    const changeOpacity = (booth: THREE.Object3D, opacity: number) => {
      traverseMaterials(booth, (material: THREE.Material) => {
        material.transparent = true
        material.opacity = opacity
      })
    }

    for (const booth of boothesRef.current) {
      const id = getId(booth.name)
      if (foundSponsorsRef.current.find((s) => id === s.id)) {
        changeOpacity(booth, 1)
      } else {
        changeOpacity(booth, 0.1)
      }
    }
  }

  useEffect(() => {
    const scene = sceneRef.current
    if (!scene) {
      return
    }

    boothesRef.current.forEach((booth) => {
      scene.remove(booth)
    })
    boothesRef.current = []

    const boothScale = 2
    const addBooth = (sponsor: IFullSponsor, gltf: GLTF, i: number, j: number) => {
      const source = gltf.scene
      const copy = source.clone()
      copy.name = `booth_${sponsor.id}`
      copy.position.set(75 - 16 * j * boothScale, -10, -70 + 16 * i * boothScale)
      copy.rotation.set(0, -((Math.PI / 180) * 30), 0)
      copy.scale.set(boothScale, boothScale, boothScale)

      const sphere = new THREE.SphereGeometry(5)
      const obj = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xffff00 }))
      obj.visible = false
      copy.add(obj)

      renderSponsorBooth(sponsor, copy)

      scene.add(copy)
      boothesRef.current.push(copy)
    }

    const promises: Array<Promise<any>> = []

    grid.forEach((row, i) =>
      row.forEach((sponsor, j) => {
        const layout = getLayout(sponsor.boothLayout)

        promises.push(
          new Promise((resolve, reject) =>
            loader.load(layout.url, (gltf) => {
              addBooth(sponsor, gltf, i, j)
              resolve()
            })
          )
        )
      })
    )

    Promise.all(promises).then(recomputeOpacity)
  }, [grid])

  useEffect(recomputeOpacity, [foundSponsors])

  return (
    <Wrapper ref={ref}>
      <SponsorPopup ref={popup} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const SponsorPopup = styled.div`
  ${niceShadow}
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
  padding: 10px 20px;
  border-radius: 3px;
  background: white;
  font-size: 22px;
`
