import { observer } from 'mobx-react-lite'
import React, { memo } from 'react'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'
import niceShadow from 'utils/niceShadow'

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  max-width: 100%;
  z-index: 300;
`

const Message = styled.div<{ isError?: boolean }>`
  ${niceShadow}
  background: ${({ isError }) => (isError ? '#f44336' : '#4caf50')};
  padding: 20px 15px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  margin-top: 10px;
`

export default memo(
  observer(() => {
    const { errorMessage, visibleError, infoMessage, visibleMessage } = useRootData(
      (store) => store.appStore
    )

    return (
      <Wrapper>
        {visibleMessage && <Message>{infoMessage}</Message>}
        {visibleError && <Message isError>{errorMessage}</Message>}
      </Wrapper>
    )
  })
)
