import React from 'react'
import { Link } from 'react-router-dom'
import { IFullSponsor } from 'stores/sponsors'
import styled, { css } from 'styled-components/macro'
import { desktop, notebook, tablet } from 'utils/responsive'
import ScenePreview from './ScenePreview'

interface ISponsorsBannersProps {
  sponsors: IFullSponsor[]
  title: string
  large?: boolean
}

export default function SponsorsBanners({ large, sponsors, title }: ISponsorsBannersProps) {
  const BoothComponent = large ? LargeBoothWrapper : BoothWrapper

  return (
    <Container>
      <SponsorsTitle>{title}</SponsorsTitle>
      <SponsorsList>
        {sponsors.length < 1 && 'Nothing found'}
        {sponsors.map((sponsor) => (
          <BoothComponent to={`/sponsor/${sponsor.id}/`} key={sponsor.id}>
            <ScenePreview sponsor={sponsor} />
            <SponsorTitle>{sponsor.company}</SponsorTitle>
          </BoothComponent>
        ))}
      </SponsorsList>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  text-align: center;
  padding: 0 20px;
  margin: auto;
  width: 100%;
`

const SponsorsTitle = styled.h2`
  margin: 0 auto;
  padding: 0 0 30px;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
`

const SponsorsList = styled.ul`
  margin: 0 -10px;
  padding: 0 0 40px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const SponsorTitle = styled.h2`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: block;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid #456dde;
  color: #456dde;
  border-radius: 3px;
  font-size: 18px;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  z-index: 1;
`

const boothStyles = css`
  padding: 20px;
  padding-bottom: 50px;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  width: 300px;
  margin-top: 40px;

  &:hover {
    background: #456dde;

    ${SponsorTitle} {
      visibility: visible;
      opacity: 1;
    }
  }

  ${desktop} {
    width: 25%;
  }

  ${notebook} {
    width: 33.333%;
  }

  ${tablet} {
    width: 50%;
  }
`

const BoothWrapper = styled(Link)`
  ${boothStyles}
`

const LargeBoothWrapper = styled(Link)`
  ${boothStyles}
  width: 500px;

  ${desktop} {
    width: 50%;
  }

  ${notebook} {
    width: 50%;
  }

  ${tablet} {
    width: 100%;
  }
`
