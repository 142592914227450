import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Minus } from 'react-feather'
import { useRootData } from 'stores'
import styled, { css } from 'styled-components'
import { tablet } from 'utils/responsive'
import { IPrivateRoom, IStream } from '../../stores/chat'
import { ISponsor } from '../../stores/sponsors'

interface IChatSidebarProps {
  activePrivateRoom: number
  activeStream: number
  onPrivateRoomSelect: (id: number) => any
  onStreamSelect: (stream: IStream) => any
}

interface IPrivateItemProps {
  activePrivateRoom: number
  user: IPrivateRoom
  onPrivateRoomSelect: (id: number) => any
  sponsor: ISponsor | undefined
  isOwn?: boolean
  unreadCount: number
  onRemove: (id: number) => void
}

const PrivateItem = ({
  activePrivateRoom,
  user,
  onPrivateRoomSelect,
  sponsor,
  isOwn,
  unreadCount,
  onRemove,
}: IPrivateItemProps) => {
  const isActive = activePrivateRoom === user.user_id
  const name = sponsor ? user.full_name : isOwn ? 'My Notes' : user.full_name
  return (
    <ItemWrapper
      active={isActive}
      key={user.user_id}
      onClick={() => onPrivateRoomSelect(user.user_id)}
      className={sponsor ? 'sponsor' : ''}
      // data-tooltip={`${sponsor ? `${sponsor.company} / ` : ''}${user.full_name}`}
    >
      {isOwn || isActive ? (
        <span className="space" />
      ) : (
        <Minus
          onClick={(event) => {
            event.stopPropagation()
            onRemove(user.user_id)
          }}
        />
      )}
      {sponsor && <img src={sponsor.logoWhiteUrl || sponsor.logoUrl} alt="" />}
      <Name>{name}</Name>
      {unreadCount > 0 && <Count>{unreadCount}</Count>}
    </ItemWrapper>
  )
}

interface IStreamItemProps {
  activeStream: number
  subscription: IStream
  onStreamSelect: (stream: IStream) => any
  sponsor: ISponsor | undefined
  onRemove: (room: IStream) => void
}

const StreamItem = ({
  activeStream,
  subscription,
  onStreamSelect,
  sponsor,
  onRemove,
}: IStreamItemProps) => {
  const isActive = activeStream === subscription.stream_id
  const name = sponsor
    ? sponsor.company
    : subscription.name === 'general'
    ? 'Event Notifications'
    : subscription.name
  return (
    <ItemWrapper
      active={isActive}
      key={subscription.stream_id}
      onClick={() => onStreamSelect(subscription)}
      className={sponsor ? 'sponsor' : ''}
    >
      {isActive || subscription.name === 'general' ? (
        <span className="space" />
      ) : (
        <Minus
          onClick={(event) => {
            event.stopPropagation()
            onRemove(subscription)
          }}
        />
      )}
      {sponsor && <img src={sponsor.logoWhiteUrl || sponsor.logoUrl} alt="" />}
      <span>{name}</span>
    </ItemWrapper>
  )
}

const findAndMakeFirst = <T extends unknown>(arr: T[], rule: (a: T) => boolean) => {
  const item = arr.find(rule)
  return (item ? [item, ...arr.filter((e) => e !== item)] : arr) as T[]
}

export const ChatSidebar = observer(
  ({ activePrivateRoom, activeStream, onPrivateRoomSelect, onStreamSelect }: IChatSidebarProps) => {
    const { visibleStreams, hideRoom, unsubscribe, visiblePrivateRooms, ownChatId } = useRootData(
      (store) => store.chatStore
    )
    const ownId = ownChatId()

    const [showPrivateChats, setShowPrivateChats] = useState<boolean>(true)
    const [showStreams, setShowStreams] = useState<boolean>(false)

    const rooms = findAndMakeFirst(
      visiblePrivateRooms(activePrivateRoom).sort((a, b) => a.full_name.localeCompare(b.full_name)),
      (r) => r.user_id === ownId
    )
    const streams = findAndMakeFirst(visibleStreams(activeStream), (s) => s.name === 'general')

    return (
      <ChatSidebarWrapper>
        <Header onClick={() => setShowPrivateChats(!showPrivateChats)}>
          {showPrivateChats ? <ChevronUp /> : <ChevronDown />}
          <span>Private Chats</span>
        </Header>
        {showPrivateChats &&
          rooms.map((room) => (
            <PrivateItem
              {...{
                activePrivateRoom,
                isOwn: room.isOwn,
                key: room.user_id,
                onPrivateRoomSelect,
                onRemove: hideRoom,
                sponsor: room.sponsor,
                unreadCount: room.unreadCount,
                user: room,
              }}
            />
          ))}
        <Header onClick={() => setShowStreams(!showStreams)}>
          {showStreams ? <ChevronUp /> : <ChevronDown />}
          <span>COMPANY ANNOUNCEMENTS</span>
        </Header>
        {showStreams &&
          streams
            .map((subscription) => (
              <StreamItem
                {...{
                  activeStream,
                  key: subscription.stream_id,
                  onRemove: unsubscribe,
                  onStreamSelect,
                  sponsor: subscription.sponsor,
                  subscription,
                }}
              />
            ))
            .filter((s) => s)}
      </ChatSidebarWrapper>
    )
  }
)

export default ChatSidebar

const ChatSidebarWrapper = styled.div`
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: width 0.15s, transform 0.15s, border-color 0.15s;
  z-index: 1;
  flex-shrink: 0;
  background-color: #fff;
  overflow-y: auto;

  ${tablet} {
    position: absolute;
    width: 100% !important;
    transform: translate(-100%, 0);
  }

  .withSidebar & {
    width: 220px;
    border-right: 1px solid #ccc;

    ${tablet} {
      transform: translate(0, 0);
      border-color: #fff;
    }
  }

  .fullChat & {
    transition: none;

    ${tablet} {
      transition: width 0.15s, transform 0.15s, border-color 0.15s;
    }
  }
`

const BaseItem = css`
  padding-left: 0;
  width: 100%;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  display: flex;
  align-items: center;
`

const itemSvg = css`
  width: 14px;
  height: 14px;
  margin-left: 8px;
  margin-right: 8px;
`

const Header = styled.h4`
  ${BaseItem};
  margin-top: 14px;
  padding-top: 14px;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  text-transform: uppercase;
  span {
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    min-width: 0;
  }

  svg {
    ${itemSvg};
    stroke: LightGrey;
  }
  &:hover {
    svg {
      stroke: Grey;
    }
    background-color: LightGrey;
  }
`

const ItemWrapper = styled.div<{ active?: boolean }>`
  ${BaseItem};
  cursor: pointer;
  color: ${({ active }) => (active ? '#fff' : '#333')};
  background-color: ${({ active }) => (active ? '#7691e4' : '#fff')};
  transition: color 0.15s, background-color 0.15s;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  &:hover {
    color: #fff;
    background-color: ${({ active }) => (active ? '#456dde' : '#718de3')};
  }
  &.sponsor {
    //padding-left: 0px;
    img {
      height: 20px;
      padding-right: 8px;
    }
  }
  svg {
    ${itemSvg};
    visibility: hidden;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-right: 8px;
    stroke: #353535;
    background-color: #f2f2f2;
    border-radius: 8px;
  }
  &:hover svg {
    visibility: visible;
  }
  .space {
    width: 30px;
  }
`

const Count = styled.div`
  min-width: 18px;
  height: 18px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 9px;
  background-color: #bbbbbb;
  font-size: 11px;
  font-weight: bold;
  color: white;
  padding: 4px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Name = styled.div`
  display: inline;
  flex: 1 1 auto;
  min-width: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`
