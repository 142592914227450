import Booth3D from 'components/Booth3D'
import Footer from 'components/Footer'
import { ContentLoader } from 'components/Loader'
import ModesSelect from 'components/ModesSelect'
import ScenePreview from 'components/ScenePreview'
import Content from 'components/SponsorContent'
import Links from 'components/SponsorLinksRow'
import { useSetVideo } from 'components/VideoController'
import { observer } from 'mobx-react-lite'
import ErrorPage from 'pages/Error'
import React, { useEffect, useState } from 'react'
import { Box, Square } from 'react-feather'
import TagManager from 'react-gtm-module'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import { mobile, tablet } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'
import useSwagBag from '../components/useSwagBag'

export default observer(() => {
  const { id } = useParams()

  if (!id) {
    return <ErrorPage />
  }

  // tslint:disable-next-line:variable-name
  const [mode, _setMode] = useState(localStorage.vrSponsorMode || '2d')
  const {
    setForceFullChatExpand,
    showChatWindow,
    subscribe,
    allStreams,
    setChatWindowVisibility,
    setCurrentPrivateRoom,
    loadStreamsStatus,
  } = useRootData((store) => store.chatStore)
  const { width: viewportWidth } = useWindowDimensions()
  const isMobile = viewportWidth < 1024
  const isVisibleWindow = showChatWindow()

  const { integrations } = useRootData((store) => store.settingsStore)

  const { addItem: addSwagItem } = useRootData((store) => store.swagbagStore)
  const {
    fullSponsorsLoadingStatus,
    fullSponsor,
    fetchFullSponsors,
    visitSponsor,
    assignedRepresentative,
  } = useRootData((store) => store.sponsorsStore)
  const currentSponsor = fullSponsor(id)!
  const loadingSponsorsStatus = fullSponsorsLoadingStatus()
  const representative = currentSponsor ? assignedRepresentative(currentSponsor.id) : undefined
  const streamName = currentSponsor ? currentSponsor.chatStream : undefined

  const setMode = (mode2: string) => {
    localStorage.vrSponsorMode = mode2
    _setMode(mode2)
  }

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [mode])

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'PageView',
        sponsorId: id,
      },
    })
    integrations(id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isMobile) {
      setForceFullChatExpand(true)
    }
    return () => {
      setForceFullChatExpand(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (loadingSponsorsStatus !== LoadingStatus.success) {
      fetchFullSponsors()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentSponsor) {
      visitSponsor(currentSponsor.id)
    }
    // eslint-disable-next-line
  }, [currentSponsor])

  useEffect(() => {
    if (streamName && loadStreamsStatus === LoadingStatus.success) {
      const stream = allStreams.find((s) => s.name === streamName)
      if (stream) {
        subscribe(stream)
      }
    }
    // eslint-disable-next-line
  }, [streamName, allStreams, loadStreamsStatus])

  const selectRepresentative = (forceShow = false) => {
    if (representative) {
      setCurrentPrivateRoom(representative.chatUserId)
      if (forceShow || !isMobile) {
        setChatWindowVisibility(true)
      }
    }
  }

  useEffect(() => {
    selectRepresentative()
    // eslint-disable-next-line
  }, [representative])

  if (loadingSponsorsStatus === LoadingStatus.success && !currentSponsor) {
    return <ErrorPage />
  }

  const setVideo = useSetVideo()

  const swagbag = useSwagBag()
  const swagItems = swagbag.items

  return (
    <Footer>
      <ContentLoader
        loadingStatus={loadingSponsorsStatus}
        error="Failed to load info about sponsor"
      >
        {() => (
          <VBox chatExpand={isVisibleWindow}>
            <VBoxWrapper>
              <Content
                addSwagItem={addSwagItem}
                sponsor={currentSponsor}
                onVideoOpen={(v) => setVideo(v.url)}
                swagBagContains={swagbag.contains}
                swagItems={swagItems}
              >
                <ModesSelect
                  modes={[
                    { name: '2d', icon: Square },
                    { name: '3d', icon: Box },
                  ]}
                  mode={mode}
                  setMode={setMode}
                />

                <Area>
                  <BoothWrapper>
                    {mode === '3d' && <Booth3D sponsor={currentSponsor} />}
                    {mode === '2d' && (
                      <ScenePreview
                        sponsor={currentSponsor}
                        onVideoOpen={(v) => setVideo(v.url)}
                        interactive={true}
                        onRepresentativeClick={() => selectRepresentative(true)}
                      />
                    )}
                  </BoothWrapper>
                  <CompanyTitle>{currentSponsor.company}</CompanyTitle>
                  <Links sponsor={currentSponsor} />
                </Area>
              </Content>
            </VBoxWrapper>
          </VBox>
        )}
      </ContentLoader>
    </Footer>
  )
})

const VBox = styled.div<{ chatExpand?: boolean }>`
  margin: 20px auto;
  min-height: calc(100vh - 90px);
  padding-right: ${({ chatExpand }) => (chatExpand ? 300 : 0)}px;
  width: 100%;

  ${tablet} {
    margin: 20px 0;
    box-shadow: none;
  }
`

const BoothWrapper = styled.div`
  width: 100%;
  height: 480px;

  ${tablet} {
    height: 450px;
  }

  ${mobile} {
    height: 250px;
  }
`

const VBoxWrapper = styled.div`
  margin: 0 auto;
  padding: 20px 0;
  min-height: 100vh;
  max-width: 900px;
  width: 100%;

  ${tablet} {
    width: 100%;
    padding: 20px;
  }
`

const CompanyTitle = styled.h2`
  margin: 10px auto;
  font-size: 24px;
  text-align: center;
`

const Area = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
`
