import React from 'react'
import tc from 'tinycolor2'
import { IBannerLayoutProps } from './ScenePreview'

const bannerDrawing = ({
  backColor,
  backSaturation,
  frontColor,
  sponsorId,
  frontHue,
  frontSaturation,
}: IBannerLayoutProps) => (
  <>
    <path
      d="M825.687 566.609s-44.455 18.51-51.13 21.089c-6.675 2.579-19.269 5.159-35.655 5.462-16.386.303-100.136 0-100.136 0H360.659s-83.751.304-100.137 0c-16.386-.304-28.979-2.883-35.655-5.462-6.676-2.579-51.585-21.089-51.585-21.089H72.576s67.024 32.505 77.644 38.271c10.62 5.766 29.738 7.282 79.806 7.282h539.372c50.068 0 69.185-1.517 79.806-7.282 10.621-5.765 77.597-38.271 77.597-38.271H825.687z"
      fill={`url(#paint0_linear_${sponsorId})`}
    />
    <path
      d="M825.687 566.609s-44.455 18.51-51.13 21.089c-6.675 2.579-19.269 5.159-35.655 5.462-16.386.303-100.136 0-100.136 0H360.659s-83.751.304-100.137 0c-16.386-.304-28.979-2.883-35.655-5.462-6.676-2.579-51.585-21.089-51.585-21.089H72.576s67.024 32.505 77.644 38.271c10.62 5.766 29.738 7.282 79.806 7.282h539.372c50.068 0 69.185-1.517 79.806-7.282 10.621-5.765 77.597-38.271 77.597-38.271H825.687z"
      fill={`url(#paint1_linear_${sponsorId})`}
    />
    <path
      d="M825.687 566.609s-44.455 18.51-51.13 21.089c-6.675 2.579-19.269 5.159-35.655 5.462-16.386.303-100.136 0-100.136 0H360.659s-83.751.304-100.137 0c-16.386-.304-28.979-2.883-35.655-5.462-6.676-2.579-51.585-21.089-51.585-21.089H72.576s67.024 32.505 77.644 38.271c10.62 5.766 29.738 7.282 79.806 7.282h539.372c50.068 0 69.185-1.517 79.806-7.282 10.621-5.765 77.597-38.271 77.597-38.271H825.687z"
      fill={`url(#paint2_linear_${sponsorId})`}
    />
    <path
      d="M72.576 567.406s67.024 33.644 77.644 39.409c10.62 5.765 29.738 7.283 79.806 7.283h539.372c50.068 0 69.185-1.517 79.806-7.283 10.621-5.766 77.597-39.59 77.597-39.59v-.614s-66.978 32.505-77.597 38.271c-10.619 5.766-29.738 7.282-79.806 7.282H230.026c-50.068 0-69.185-1.517-79.806-7.282-10.621-5.765-77.644-38.271-77.644-38.271v.795z"
      fill={`url(#paint3_linear_${sponsorId})`}
    />
    <path d="M173.475 253.087H72.576v313.522h100.899V253.087z" fill={`url(#paint4_linear_${sponsorId})`} />
    <path d="M926.847 253.087H825.949v313.522h100.898V253.087z" fill={`url(#paint5_linear_${sponsorId})`} />
    <path
      d="M751.495 495.224h34.917v-53.027h-34.917V337.053h34.917v-53.027h-34.917v-53.254H247.929v53.254h-34.918v53.027h34.918v105.144h-34.918v53.027h34.918v51.889h503.566v-51.889z"
      fill={`url(#paint6_radial_${sponsorId})`}
    />
    <path fill="url(#pattern0)" d="M222.133 205.501h558.926v367.865H222.133z" />
    <path
      d="M244.895 230.165v318.541h4.988l1.698-1.593V230.772l-1.698-.607h-4.988zM271.366 230.165v318.541h5.169l1.518-1.593V230.772l-1.518-.607h-5.169zM297.836 230.165v318.541h5.35l1.337-1.593V230.772l-1.337-.607h-5.35zM324.307 230.165v318.541h5.53l1.155-1.593V230.772l-1.155-.607h-5.53zM350.778 230.165v318.541h5.713l.974-1.593V230.772l-.974-.607h-5.713zM377.249 230.165v318.541h5.894l.793-1.593V230.772l-.793-.607h-5.894zM403.717 230.165v318.541h6.077l.614-1.593V230.772l-.614-.607h-6.077zM430.19 230.165v318.541h6.257l.43-1.593V230.772l-.43-.607h-6.257zM456.661 230.165v318.541h6.438l.249-1.593V230.772l-.249-.607h-6.438zM483.133 230.165v318.541h6.619l.068-1.593V230.772l-.068-.607h-6.619zM749.542 230.165l-1.7.607v316.34l1.7 1.593h4.988v-318.54h-4.988zM721.371 230.772v316.34l1.518 1.593h5.169v-318.54h-5.169l-1.518.607zM694.9 230.772v316.34l1.337 1.593h5.351v-318.54h-5.351l-1.337.607zM668.429 230.772v316.34l1.155 1.593h5.531v-318.54h-5.531l-1.155.607zM641.959 230.772v316.34l.974 1.593h5.712v-318.54h-5.712l-.974.607zM615.487 230.772v316.34l.793 1.593h5.894v-318.54h-5.894l-.793.607zM589.016 230.772v316.34l.614 1.593h6.076v-318.54h-6.076l-.614.607zM562.546 230.772v316.34l.43 1.593h6.256v-318.54h-6.256l-.43.607zM536.075 230.772v316.34l.249 1.593h6.438v-318.54h-6.438l-.249.607zM509.604 230.772v316.34l.068 1.593h6.619v-318.54h-6.619l-.068.607z"
      fill={`hsl(${tc(backColor).spin(0).toHsl().h}, ${tc(backColor).toHsl().s * 100}%, 33%)`}
    />
    <path d="M249.882 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint7_radial_${sponsorId})`} />
    <path d="M276.535 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint8_radial_${sponsorId})`} />
    <path d="M303.187 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint9_radial_${sponsorId})`} />
    <path d="M329.839 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint10_radial_${sponsorId})`} />
    <path d="M356.491 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint11_radial_${sponsorId})`} />
    <path d="M383.143 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint12_radial_${sponsorId})`} />
    <path d="M409.795 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint13_radial_${sponsorId})`} />
    <path d="M436.447 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint14_radial_${sponsorId})`} />
    <path d="M463.1 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint15_radial_${sponsorId})`} />
    <path d="M489.752 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint16_radial_${sponsorId})`} />
    <path d="M516.404 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint17_radial_${sponsorId})`} />
    <path d="M543.056 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint18_radial_${sponsorId})`} />
    <path d="M569.709 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint19_radial_${sponsorId})`} />
    <path d="M596.36 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint20_radial_${sponsorId})`} />
    <path d="M623.013 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint21_radial_${sponsorId})`} />
    <path d="M649.665 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint22_radial_${sponsorId})`} />
    <path d="M676.317 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint23_radial_${sponsorId})`} />
    <path d="M702.97 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint24_radial_${sponsorId})`} />
    <path d="M729.622 230.165h-6.733v318.54h6.733v-318.54z" fill={`url(#paint25_radial_${sponsorId})`} />
    <path d="M756.274 230.165h-6.732v318.54h6.732v-318.54z" fill={`url(#paint26_radial_${sponsorId})`} />
    <path
      d="M751.494 230.772H247.928l-9.676-51.51H761.17l-9.676 51.51z"
      fill={`url(#paint27_radial_${sponsorId})`}
    />
    <path
      d="M761.171 176H238.252v3.262h522.919V176z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
    />
    <path
      d="M200.85 253.087H34.06c-6.718 0-12.163 5.445-12.163 12.162v248.373c0 6.717 5.446 12.162 12.162 12.162H200.85c6.716 0 12.161-5.445 12.161-12.162V265.249c0-6.717-5.445-12.162-12.161-12.162z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
    />
    <path
      d="M199.952 253.087H33.162c-6.717 0-12.162 5.445-12.162 12.162v248.373c0 6.717 5.445 12.162 12.162 12.162h166.79c6.717 0 12.162-5.445 12.162-12.162V265.249c0-6.717-5.445-12.162-12.162-12.162z"
      fill={`url(#paint28_radial_${sponsorId})`}
    />
    <path
      d="M207.336 513.622V265.249a7.382 7.382 0 00-7.383-7.382H33.163a7.382 7.382 0 00-7.382 7.382v248.373a7.383 7.383 0 007.382 7.383h166.79a7.383 7.383 0 007.383-7.383z"
      fill="#fff"
    />
    <path
      d="M798.575 525.783h166.79c6.717 0 12.162-5.445 12.162-12.162V265.249c0-6.717-5.445-12.162-12.162-12.162h-166.79c-6.717 0-12.162 5.445-12.162 12.162v248.372c0 6.717 5.445 12.162 12.162 12.162z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
    />
    <path
      d="M966.263 253.087H799.472c-6.716 0-12.161 5.445-12.161 12.162v248.373c0 6.716 5.445 12.161 12.161 12.161h166.791c6.716 0 12.161-5.445 12.161-12.161V265.249c0-6.717-5.445-12.162-12.161-12.162z"
      fill={`url(#paint29_radial_${sponsorId})`}
    />
    <path
      d="M973.645 513.622V265.249a7.383 7.383 0 00-7.382-7.383H799.472a7.383 7.383 0 00-7.382 7.383v248.373a7.382 7.382 0 007.382 7.382h166.791a7.382 7.382 0 007.382-7.382z"
      fill="#fff"
    />
    <path d="M587.559 399.961v-97.88H411.865v97.88h175.694z" fill="#fff" />
    <path
      d="M669.211 593.16h-339l17.418 16.083h304.164l17.418-16.083z"
      fill={`url(#paint30_linear_${sponsorId})`}
    />
    <path d="M654.923 487.429H344.499v112.71h310.424v-112.71z" fill="#fff" />
    <path d="M661.864 481.493H337.558v5.936h324.306v-5.936z" fill={`url(#paint31_linear_${sponsorId})`} />
    <path
      d="M661.864 481.493H337.558l7.741-11.227H656.06l5.804 11.227z"
      fill={`url(#paint32_linear_${sponsorId})`}
    />
    <path d="M651.793 600.139H347.628v9.103h304.165v-9.103z" fill={`url(#paint33_linear_${sponsorId})`} />
    <path
      d="M346.245 205.681h-21.697v-3.85c0-9.306 21.697-9.306 21.697 0v3.85z"
      fill={`url(#paint34_linear_${sponsorId})`}
    />
    <path
      d="M335.396 213.188c5.991 0 10.848-3.15 10.848-7.035 0-3.885-4.857-7.034-10.848-7.034-5.991 0-10.848 3.149-10.848 7.034s4.857 7.035 10.848 7.035z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M428.402 205.681h-21.696v-3.85c0-9.306 21.696-9.306 21.696 0v3.85z"
      fill={`url(#paint35_linear_${sponsorId})`}
    />
    <path
      d="M417.554 213.188c5.992 0 10.849-3.15 10.849-7.035 0-3.885-4.857-7.034-10.849-7.034-5.991 0-10.848 3.149-10.848 7.034s4.857 7.035 10.848 7.035z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M510.56 205.681h-21.697v-3.85c0-9.306 21.697-9.306 21.697 0v3.85z"
      fill={`url(#paint36_linear_${sponsorId})`}
    />
    <path
      d="M499.712 213.188c5.991 0 10.848-3.15 10.848-7.035 0-3.885-4.857-7.034-10.848-7.034-5.992 0-10.849 3.149-10.849 7.034s4.857 7.035 10.849 7.035z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M592.717 205.681H571.02v-3.85c0-9.306 21.697-9.306 21.697 0v3.85z"
      fill={`url(#paint37_linear_${sponsorId})`}
    />
    <path
      d="M581.869 213.188c5.991 0 10.848-3.15 10.848-7.035 0-3.885-4.857-7.034-10.848-7.034-5.991 0-10.848 3.149-10.848 7.034s4.857 7.035 10.848 7.035z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M674.875 205.681h-21.697v-3.85c0-9.306 21.697-9.306 21.697 0v3.85z"
      fill={`url(#paint38_linear_${sponsorId})`}
    />
    <path
      d="M664.027 213.188c5.991 0 10.848-3.15 10.848-7.035 0-3.885-4.857-7.034-10.848-7.034-5.992 0-10.848 3.149-10.848 7.034s4.856 7.035 10.848 7.035z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <defs>
      <radialGradient
        id={`paint6_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(643.797 0 0 643.438 80.57 367.431)"
      >
        <stop
          offset=".352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint7_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(459.652 0 0 459.655 3655.14 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint8_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.597 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint9_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.595 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint10_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.6 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint11_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.597 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint12_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.595 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint13_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.6 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint14_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.597 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint15_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.602 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint16_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.6 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint17_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.598 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint18_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.596 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint19_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.601 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint20_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(459.642 0 0 459.655 -12390.8 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint21_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.602 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint22_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.601 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint23_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.598 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint24_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.603 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint25_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(9.71513 0 0 459.655 315.601 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint26_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(459.642 0 0 459.655 -19796.6 381.79)"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint27_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(398.931 0 0 396.935 -90.48 -67.574)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint28_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(717.798 0 0 717.737 5.037 289.083)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint29_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(1087.33 0 0 1087.24 237.552 158.189)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset=".352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <linearGradient
        id={`paint0_linear_${sponsorId}`}
        x1="72.576"
        y1="589.386"
        x2="926.801"
        y2="589.386"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".325"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".734"
          stopColor={`hsl(${tc(backColor).spin(22).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".753"
          stopColor={`hsl(${tc(backColor).spin(18).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".782"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".815"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".87"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${sponsorId}`}
        x1="503.96"
        y1="526.248"
        x2="503.952"
        y2="549.846"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".268"
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_${sponsorId}`}
        x1="499.688"
        y1="565.333"
        x2="499.688"
        y2="586.743"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset=".103"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 38%)`}
        />
        <stop
          offset=".456"
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_${sponsorId}`}
        x1="72.576"
        y1="590.354"
        x2="926.801"
        y2="590.354"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".428"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".582"
          stopColor={`hsl(${tc(backColor).spin(3).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".692"
          stopColor={`hsl(${tc(backColor).spin(6).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".78"
          stopColor={`hsl(${tc(backColor).spin(10).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".855"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".862"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset=".941"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 29%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 38%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_${sponsorId}`}
        x1="72.576"
        y1="409.848"
        x2="173.475"
        y2="409.848"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset=".972"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_${sponsorId}`}
        x1="825.949"
        y1="409.848"
        x2="926.847"
        y2="409.848"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 48%)`}
        />
        <stop
          offset=".972"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 46%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint30_linear_${sponsorId}`}
        x1="499.711"
        y1="609.243"
        x2="499.711"
        y2="593.16"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 100%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint31_linear_${sponsorId}`}
        x1="337.558"
        y1="484.461"
        x2="661.864"
        y2="484.461"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 69%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint32_linear_${sponsorId}`}
        x1="337.558"
        y1="-832.03"
        x2="661.864"
        y2="-832.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
        />
        <stop
          offset=".503"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint33_linear_${sponsorId}`}
        x1="347.628"
        y1="604.691"
        x2="651.793"
        y2="604.691"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 69%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint34_linear_${sponsorId}`}
        x1="324.548"
        y1="200.266"
        x2="346.245"
        y2="200.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset=".31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset=".687"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint35_linear_${sponsorId}`}
        x1="406.706"
        y1="200.266"
        x2="428.402"
        y2="200.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset=".31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset=".687"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint36_linear_${sponsorId}`}
        x1="488.863"
        y1="200.266"
        x2="510.56"
        y2="200.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset=".31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset=".687"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint37_linear_${sponsorId}`}
        x1="571.02"
        y1="200.266"
        x2="592.717"
        y2="200.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset=".31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset=".687"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint38_linear_${sponsorId}`}
        x1="653.178"
        y1="200.266"
        x2="674.875"
        y2="200.266"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset=".31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset=".687"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`}
        />
      </linearGradient>
    </defs>
  </>
)

export default bannerDrawing
