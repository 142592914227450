import { types } from 'mobx-state-tree'

export interface IQuestion {
  question: string
  answer: string
  categories: string[]
}

const questions = [
  {
    answer: '<p>Yes this platform can be used on all devices.</p>',
    categories: [],
    question: 'Is the event mobile-compatible?',
  },
  {
    answer:
      '<p>An internet connection (minimum 600 KBPS, recommended 1.5 MBPS) and a modern browser like Chrome, Firefox or Safari.</p>',
    categories: [],
    question: 'What do you need to run the event? How fast does the connection need to be?',
  },
  {
    answer: '<p>Yes of course, feel free to reach out directly on our tehnical chat.</p>',
    categories: [],
    question: 'Will there be a support while the event is happening?',
  },
  {
    answer:
      '<p>Please keep an eye on the event agenda under the Webinars section where all the details will be posted.</p>',
    categories: [],
    question: 'When do the LIVE sessions start?',
  },
  {
    answer:
      '<p>An internet connection (minimum 600 KBPS, recommended 1.5 MBPS) and a modern browser like Chrome, Firefox or Safari.</p>',
    categories: [],
    question: 'What do you need to run the event? How fast does the connection need to be? ',
  },
  {
    answer: '<p>Yes, we will have a technical help desk during the event.</p>',
    categories: ['issues'],
    question: 'Will there be a support while the event is happening?',
  },
  {
    answer:
      '<p>Please keep an eye on the event agenda under the Webinars section where all the details will be posted.</p>',
    categories: [],
    question: 'When do the LIVE sessions start?',
  },
  {
    answer:
      '<p>You will be able to engage with attendees as well as exhibitor representatives in their booth.</p>',
    categories: [],
    question: 'Who will I be able to chat with? ',
  },
  {
    answer:
      '<p>No, all tech is supported on the platform however we do recomend to have Zoom installed.</p>',
    categories: [],
    question: 'Do I need to download or install any information to be able to attend this event?',
  },
  {
    answer:
      '<p>No, you will not be visible unless you decide to activate that function during live Q&A or one-to-one calls with booth representatives.</p>',
    categories: [],
    question:
      'The device that I will use has a camera installed. Will I be visible to other participants or anyone else during the conference?',
  },
  {
    answer:
      '<p>There will be a tehnical chat available on the day. Alternatively you can email info@nextv.io</p>',
    categories: ['issues'],
    question: 'Who do I contact if I have troubleshooting issues on the day?',
  },
  {
    answer:
      '<p>Yes you will be able to save all documents form varius booths into your goody pack. From there you will be able to download it directly to your device or email it to yourself.</p>',
    categories: [],
    question: 'Will I be able to take away information or brochures from the event?',
  },
  {
    answer:
      '<p>All participants must be registered. You can share the registration link and provided they are part of relevant industries their registration will be validated.</p>',
    categories: [],
    question: 'Can I invite my friends to attend the event?',
  },
  {
    answer:
      '<p>Yes, Finance Magnates Virtual Summit will be accessible for 30 days. You can listen to content on-demand.</p>',
    categories: [],
    question: 'The times for the live events are not convenient for me. Can I still participate?',
  },
  {
    answer:
      '<p>You can visit the exhibitors’ booths in the expo hall, link to a specific exhibitor where you can chat with representatives, either as part of a group chat or through one-on-one messages.</p>',
    categories: [],
    question: 'How do I interact with exhibitors?',
  },
  {
    answer: '<p>Yes, please visit the Webinars section.</p>',
    categories: [],
    question: 'Will you have an official program with speaker sessions, discussions, etc.?',
  },
] as IQuestion[]

export default types
  .model('FaqStore', {
    questions: types.frozen<IQuestion[]>(questions),
  })

  .views((self) => ({
    categories: () => Array.from(new Set(self.questions.map((a) => a.categories).flat())),
  }))
