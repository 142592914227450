import { generateDeviceId } from './device-id'

export type AuthType = 'regular' | 'saml' | 'saml_no_request'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const CAPTCHA_CODE = '6Lcdd8YUAAAAAGoYTKGSEsnxVRV5rE-WFLnNUyUh'
export const DEVICE_ID = generateDeviceId()
export const API = process.env.REACT_APP_API
export const SITE = process.env.REACT_APP_SITE
export const SAML_URL = process.env.REACT_APP_SAML_URL!
export const CHAT_REALM = process.env.REACT_APP_CHAT_REALM || 'https://chat.nextv.company'
export const AUTH_TYPE = (process.env.REACT_APP_AUTH_TYPE || 'saml') as AuthType
