import PlayIcon from 'assets/icons/play.svg'
import React, { useCallback, useRef } from 'react'
import { ISessionItem } from 'stores/agenda'
import styled from 'styled-components/macro'
import { mobile, tablet } from 'utils/responsive'
import { useSetVideo } from './VideoController'

interface ISessionCardProps {
  active?: boolean
  session: ISessionItem
  onClick?: (id: string, top: number) => any
}

const SessionCard = ({ session, onClick, active }: ISessionCardProps) => {
  const setVideo = useSetVideo()
  const ref = useRef<HTMLDivElement>(null)
  const clickHandler = useCallback(() => {
    if (ref.current && onClick) {
      const box = ref.current.getBoundingClientRect()
      onClick(session.id, box.top + box.height)
    }
  }, [onClick, session])

  return (
    <SessionCardWrapper
      className={active ? 'active' : ''}
      ref={ref}
      onClick={clickHandler}
      color={session.location.backgroundColor || 'grey'}
    >
      <Row>
        <Time>
          {parseTime(session.startTime)}
          {session.endTime && <>-{parseTime(session.endTime)}</>}
        </Time>
        <SessionTitle>{session.title}</SessionTitle>
      </Row>

      <Row>
        {session.streamVideoUrl ? (
          <VideoButton onClick={() => setVideo(session.streamVideoUrl)}>
            <PlayIcon />
            Open stream
          </VideoButton>
        ) : (
          <StreamPlaceholder />
        )}

        <Speakers>
          {session.speakers.map((speaker) => (
            <SpeakerItem key={speaker.id}>
              <SpeakerAvatar img={speaker.image} />
              <SpeakerName>{speaker.name}</SpeakerName>
              <SpeakerCompany>{speaker.company}</SpeakerCompany>
            </SpeakerItem>
          ))}
        </Speakers>
      </Row>
    </SessionCardWrapper>
  )
}

export default SessionCard

const parseTime = (t: string) =>
  ((t || '').match(/\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}):\d{2}Z/) || [])[1] || 'Unknown'

const Time = styled.div`
  width: 90px;
  flex-shrink: 0;
  padding: 2px 10px 0 0;
  font-size: 13px;
  line-height: 22px;
  color: #8e8c8c;
  text-align: center;
`

const SessionTitle = styled.div`
  font-size: 20px;
  margin-bottom: 5px;
`

const StreamPlaceholder = styled.div`
  width: 90px;
  flex-shrink: 0;
`

const VideoButton = styled.button`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #456dde;
  text-decoration: none;
  width: 90px;
  padding-right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    fill: #456dde;
    width: 35px;
    height: auto;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Row = styled.div`
  display: flex;
`

const SessionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 20px 30px;
  line-height: 22px;
  text-align: left;
  cursor: pointer;

  ${tablet} {
    padding: 0 0 20px;
  }

  ${Time} {
    color: ${({ color }) => color};
  }

  &:hover,
  &.active {
    color: ${({ color }) => color};
  }
`

const Speakers = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
`

const SpeakerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  margin: 5px;

  ${mobile} {
    width: 70px;
  }
`

const SpeakerAvatar = styled.div<{ img: string }>`
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;

  ${mobile} {
    width: 45px;
    height: 45px;
  }
`

const SpeakerName = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: #333 !important;
`

const SpeakerCompany = styled(SpeakerName)`
  font-weight: bold;
  margin-top: 0;
`
