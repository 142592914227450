import React from 'react'
import tc from 'tinycolor2'
import { IBannerLayoutProps } from './ScenePreview'

export const bannerDrawing = ({
  backColor,
  backSaturation,
  frontColor,
  sponsorId,
  frontHue,
  frontSaturation,
}: IBannerLayoutProps) => (
  <>
    <path
      d="M795.733 581.096C795.733 581.096 781.926 587.012 775.251 589.592C768.575 592.171 755.981 594.751 739.595 595.054C723.209 595.357 639.459 595.054 639.459 595.054H361.352C361.352 595.054 277.602 595.358 261.216 595.054C244.83 594.75 232.237 592.171 225.561 589.592C218.885 587.012 205.078 581.096 205.078 581.096L117.725 589.554C117.725 589.554 140.294 601.008 150.914 606.774C161.534 612.54 180.652 614.056 230.72 614.056H770.092C820.16 614.056 839.277 612.539 849.898 606.774C860.519 601.009 883.087 589.554 883.087 589.554L795.733 581.096Z"
      fill={`url(#paint0_linear_${sponsorId})`}
    />
    <path
      d="M795.733 581.096C795.733 581.096 781.926 587.012 775.251 589.592C768.575 592.171 755.981 594.751 739.595 595.054C723.209 595.357 639.459 595.054 639.459 595.054H361.352C361.352 595.054 277.602 595.358 261.216 595.054C244.83 594.75 232.237 592.171 225.561 589.592C218.885 587.012 205.078 581.096 205.078 581.096L117.725 589.554C117.725 589.554 140.294 601.008 150.914 606.774C161.534 612.54 180.652 614.056 230.72 614.056H770.092C820.16 614.056 839.277 612.539 849.898 606.774C860.519 601.009 883.087 589.554 883.087 589.554L795.733 581.096Z"
      fill={`url(#paint1_linear_${sponsorId})`}
    />
    <path
      d="M795.733 581.096C795.733 581.096 781.926 587.012 775.251 589.592C768.575 592.171 755.981 594.751 739.595 595.054C723.209 595.357 639.459 595.054 639.459 595.054H361.352C361.352 595.054 277.602 595.358 261.216 595.054C244.83 594.75 232.237 592.171 225.561 589.592C218.885 587.012 205.078 581.096 205.078 581.096L117.725 589.554C117.725 589.554 140.294 601.008 150.914 606.774C161.534 612.54 180.652 614.056 230.72 614.056H770.092C820.16 614.056 839.277 612.539 849.898 606.774C860.519 601.009 883.087 589.554 883.087 589.554L795.733 581.096Z"
      fill={`url(#paint2_linear_${sponsorId})`}
    />
    <path
      d="M883.086 589.554C883.086 589.554 886.727 192.99 886.727 186.277C886.727 179.563 886.5 172.849 882.062 170.801C877.624 168.752 843.601 165.908 826.532 165.908C809.463 165.908 738.343 165.339 738.343 165.339H262.467C262.467 165.339 191.347 165.908 174.278 165.908C157.21 165.908 123.186 168.752 118.748 170.801C114.31 172.849 114.083 179.563 114.083 186.277C114.083 192.99 117.724 589.554 117.724 589.554L205.078 581.096C205.078 581.096 202.651 188.287 202.651 182.977C202.651 177.666 203.561 172.66 212.209 171.446C220.858 170.232 246.954 169.17 268.802 169.17H732.009C753.857 169.17 779.953 170.233 788.601 171.447C797.25 172.661 798.16 177.667 798.16 182.978C798.16 188.288 795.733 581.096 795.733 581.096L883.086 589.554Z"
      fill={`url(#paint3_linear_${sponsorId})`}
    />
    <path
      d="M883.086 589.554C883.086 589.554 886.727 192.99 886.727 186.277C886.727 179.563 886.5 172.849 882.062 170.801C877.624 168.752 843.601 165.908 826.532 165.908C809.463 165.908 738.343 165.339 738.343 165.339H262.467C262.467 165.339 191.347 165.908 174.278 165.908C157.21 165.908 123.186 168.752 118.748 170.801C114.31 172.849 114.083 179.563 114.083 186.277C114.083 192.99 117.724 589.554 117.724 589.554L205.078 581.096C205.078 581.096 202.651 188.287 202.651 182.977C202.651 177.666 203.561 172.66 212.209 171.446C220.858 170.232 246.954 169.17 268.802 169.17H732.009C753.857 169.17 779.953 170.233 788.601 171.447C797.25 172.661 798.16 177.667 798.16 182.978C798.16 188.288 795.733 581.096 795.733 581.096L883.086 589.554Z"
      fill={`url(#paint4_linear_${sponsorId})`}
    />
    <path
      d="M117.725 589.554C117.725 589.554 114.084 192.99 114.084 186.277C114.084 179.563 114.311 172.849 118.749 170.801C123.187 168.752 157.21 165.908 174.279 165.908C191.348 165.908 262.468 165.339 262.468 165.339H738.344C738.344 165.339 809.464 165.908 826.533 165.908C843.602 165.908 877.625 168.752 882.063 170.801C886.501 172.849 886.728 179.563 886.728 186.277C886.728 192.99 883.087 589.554 883.087 589.554L885.097 590.503C885.097 590.503 889.346 200.273 889.346 192.99C889.346 185.707 889.952 173.266 886.008 161.432C882.064 149.598 861.732 91.64 842.919 69.792C824.106 47.9441 796.188 40.0542 779.195 40.0542H221.617C204.624 40.0542 176.707 47.9441 157.893 69.792C139.079 91.64 118.749 149.598 114.804 161.432C110.859 173.266 111.466 185.708 111.466 192.99C111.466 200.272 115.715 590.503 115.715 590.503L117.725 589.554Z"
      fill={`url(#paint5_linear_${sponsorId})`}
    />
    <path
      d="M115.715 590.503C115.715 590.503 140.294 602.944 150.914 608.709C161.534 614.474 180.652 615.992 230.72 615.992H770.092C820.16 615.992 839.277 614.475 849.898 608.709C860.519 602.943 885.097 590.503 885.097 590.503L883.087 589.554C883.087 589.554 860.518 601.008 849.898 606.774C839.278 612.54 820.16 614.056 770.092 614.056H230.72C180.652 614.056 161.535 612.539 150.914 606.774C140.293 601.009 117.725 589.554 117.725 589.554L115.715 590.503Z"
      fill={`url(#paint6_linear_${sponsorId})`}
    />
    <path
      d="M696.354 532.772L698.168 531.463L700.094 255.5H698.29L696.354 532.772Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 77%)`}
    />
    <path
      d="M304.456 532.772L302.643 531.463L300.717 255.5H302.521L304.456 532.772Z"
      fill={`url(#paint7_linear_${sponsorId})`}
    />
    <path
      d="M696.354 532.772C565.82 525.59 434.99 525.59 304.456 532.772L302.521 255.5C433.154 254.691 567.657 254.691 698.29 255.5L696.354 532.772Z"
      fill={`url(#paint8_radial_${sponsorId})`}
    />
    <path
      d="M301.859 335.248L270.017 335.837V342.456L301.859 341.868C303.174 341.868 303.174 335.248 301.859 335.248Z"
      fill={`url(#paint9_linear_${sponsorId})`}
    />
    <path
      d="M302.094 363.128L270.017 363.83V370.448L302.094 369.746C303.409 369.746 303.409 363.128 302.094 363.128Z"
      fill={`url(#paint10_linear_${sponsorId})`}
    />
    <path
      d="M302.219 390.798L270.017 391.633V398.251L302.219 397.417C303.534 397.417 303.534 390.798 302.219 390.798Z"
      fill={`url(#paint11_linear_${sponsorId})`}
    />
    <path
      d="M302.523 418.506L270.017 419.416V426.039L302.523 425.128C303.838 425.125 303.838 418.506 302.523 418.506Z"
      fill={`url(#paint12_linear_${sponsorId})`}
    />
    <path
      d="M302.599 446.101L270.017 447.39V454.013L302.599 452.723C303.911 452.72 303.911 446.101 302.599 446.101Z"
      fill={`url(#paint13_linear_${sponsorId})`}
    />
    <path
      d="M301.859 335.248L270.017 335.837V342.456L301.859 341.868C303.174 341.868 303.174 335.248 301.859 335.248Z"
      fill={`url(#paint14_linear_${sponsorId})`}
    />
    <path
      d="M302.094 363.128L270.017 363.83V370.448L302.094 369.746C303.409 369.746 303.409 363.128 302.094 363.128Z"
      fill={`url(#paint15_linear_${sponsorId})`}
    />
    <path
      d="M302.219 390.798L270.017 391.633V398.251L302.219 397.417C303.534 397.417 303.534 390.798 302.219 390.798Z"
      fill={`url(#paint16_linear_${sponsorId})`}
    />
    <path
      d="M302.523 418.506L270.017 419.416V426.039L302.523 425.128C303.838 425.125 303.838 418.506 302.523 418.506Z"
      fill={`url(#paint17_linear_${sponsorId})`}
    />
    <path
      d="M302.599 446.101L270.017 447.39V454.013L302.599 452.723C303.911 452.72 303.911 446.101 302.599 446.101Z"
      fill={`url(#paint18_linear_${sponsorId})`}
    />
    <path
      d="M698.954 335.248L730.796 335.837V342.456L698.954 341.868C697.639 341.868 697.639 335.248 698.954 335.248Z"
      fill={`url(#paint19_linear_${sponsorId})`}
    />
    <path
      d="M698.719 363.128L730.796 363.83V370.448L698.719 369.746C697.404 369.746 697.404 363.128 698.719 363.128Z"
      fill={`url(#paint20_linear_${sponsorId})`}
    />
    <path
      d="M698.593 390.798L730.795 391.633V398.251L698.593 397.417C697.278 397.417 697.278 390.798 698.593 390.798Z"
      fill={`url(#paint21_linear_${sponsorId})`}
    />
    <path
      d="M698.29 418.506L730.796 419.416V426.039L698.29 425.128C696.975 425.125 696.975 418.506 698.29 418.506Z"
      fill={`url(#paint22_linear_${sponsorId})`}
    />
    <path
      d="M698.214 446.101L730.796 447.39V454.013L698.214 452.723C696.901 452.72 696.901 446.101 698.214 446.101Z"
      fill={`url(#paint23_linear_${sponsorId})`}
    />
    <path
      d="M698.954 335.248L730.796 335.837V342.456L698.954 341.868C697.639 341.868 697.639 335.248 698.954 335.248Z"
      fill={`url(#paint24_linear_${sponsorId})`}
    />
    <path
      d="M698.719 363.128L730.796 363.83V370.448L698.719 369.746C697.404 369.746 697.404 363.128 698.719 363.128Z"
      fill={`url(#paint25_linear_${sponsorId})`}
    />
    <path
      d="M698.593 390.798L730.795 391.633V398.251L698.593 397.417C697.278 397.417 697.278 390.798 698.593 390.798Z"
      fill={`url(#paint26_linear_${sponsorId})`}
    />
    <path
      d="M698.29 418.506L730.796 419.416V426.039L698.29 425.128C696.975 425.125 696.975 418.506 698.29 418.506Z"
      fill={`url(#paint27_linear_${sponsorId})`}
    />
    <path
      d="M698.214 446.101L730.796 447.39V454.013L698.214 452.723C696.901 452.72 696.901 446.101 698.214 446.101Z"
      fill={`url(#paint28_linear_${sponsorId})`}
    />
    <path
      d="M331.767 172.09H310.07V167.311C310.07 166.553 331.767 166.553 331.767 167.311V172.09Z"
      fill={`url(#paint29_linear_${sponsorId})`}
    />
    <path
      d="M320.919 172.564C326.91 172.564 331.767 172.352 331.767 172.09C331.767 171.829 326.91 171.617 320.919 171.617C314.927 171.617 310.07 171.829 310.07 172.09C310.07 172.352 314.927 172.564 320.919 172.564Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M421.548 172.09H399.852V167.311C399.852 166.553 421.548 166.553 421.548 167.311V172.09Z"
      fill={`url(#paint30_linear_${sponsorId})`}
    />
    <path
      d="M410.7 172.564C416.691 172.564 421.548 172.352 421.548 172.09C421.548 171.829 416.691 171.617 410.7 171.617C404.708 171.617 399.852 171.829 399.852 172.09C399.852 172.352 404.708 172.564 410.7 172.564Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M511.33 172.09H489.634V167.311C489.634 166.553 511.33 166.553 511.33 167.311V172.09Z"
      fill={`url(#paint31_linear_${sponsorId})`}
    />
    <path
      d="M500.482 172.564C506.473 172.564 511.33 172.352 511.33 172.09C511.33 171.829 506.473 171.617 500.482 171.617C494.491 171.617 489.634 171.829 489.634 172.09C489.634 172.352 494.491 172.564 500.482 172.564Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M601.112 172.09H579.416V167.311C579.416 166.553 601.112 166.553 601.112 167.311V172.09Z"
      fill={`url(#paint32_linear_${sponsorId})`}
    />
    <path
      d="M590.264 172.564C596.256 172.564 601.112 172.352 601.112 172.09C601.112 171.829 596.256 171.617 590.264 171.617C584.273 171.617 579.416 171.829 579.416 172.09C579.416 172.352 584.273 172.564 590.264 172.564Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M690.893 172.09H669.196V167.311C669.196 166.553 690.893 166.553 690.893 167.311V172.09Z"
      fill={`url(#paint33_linear_${sponsorId})`}
    />
    <path
      d="M680.045 172.564C686.037 172.564 690.894 172.352 690.894 172.09C690.894 171.829 686.037 171.617 680.045 171.617C674.054 171.617 669.197 171.829 669.197 172.09C669.197 172.352 674.054 172.564 680.045 172.564Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      opacity="0.51"
      d="M639.459 595.054H361.352L358.86 595.063L373.608 604.826C458.047 614.644 543.347 614.52 627.757 604.457L641.949 595.062L639.459 595.054Z"
      fill={`url(#paint34_linear_${sponsorId})`}
    />
    <path
      d="M643.044 479.062C548.048 485.131 452.763 485.131 357.768 479.062V485.662C452.763 491.731 548.048 491.731 643.044 485.662V479.062Z"
      fill={`url(#paint35_linear_${sponsorId})`}
    />
    <path
      d="M643.044 479.062C548.048 485.131 452.763 485.131 357.768 479.062L394.465 471.097C465.009 475.952 535.802 475.952 606.346 471.097L643.044 479.062Z"
      fill={`url(#paint36_linear_${sponsorId})`}
    />
    <path
      d="M374.666 597.279V604.688C458.292 612.881 542.519 612.881 626.146 604.688V597.279C542.485 604.728 458.327 604.728 374.666 597.279V597.279Z"
      fill={`url(#paint37_linear_${sponsorId})`}
    />
    <path
      d="M366.131 486.163V596.495C455.445 604.99 545.365 604.99 634.679 596.495V486.163C545.244 491.541 455.566 491.541 366.131 486.163V486.163Z"
      fill="#fff"
    />
    <path
      d="M254.196 564.12L45.1702 581.52C43.4939 581.66 41.8069 581.45 40.2158 580.904C38.6246 580.358 37.164 579.489 35.9263 578.349C34.6885 577.21 33.7005 575.827 33.0247 574.286C32.3489 572.746 32 571.082 32 569.4V237.994C32 236.391 32.3167 234.804 32.9319 233.324C33.5472 231.845 34.4488 230.501 35.585 229.371C36.7212 228.241 38.0696 227.346 39.5526 226.739C41.0357 226.131 42.6241 225.823 44.2267 225.832L253.25 226.949C256.465 226.966 259.541 228.255 261.808 230.534C264.074 232.813 265.347 235.896 265.347 239.11V552.001C265.347 555.051 264.2 557.991 262.135 560.235C260.07 562.48 257.236 563.867 254.196 564.12V564.12Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
    />
    <path
      d="M258.067 564.12L49.0404 581.52C47.364 581.66 45.677 581.45 44.0859 580.904C42.4948 580.358 41.0342 579.489 39.7964 578.349C38.5586 577.21 37.5706 575.827 36.8948 574.286C36.219 572.746 35.8701 571.082 35.8701 569.4V237.994C35.8701 236.391 36.1868 234.804 36.8021 233.324C37.4173 231.845 38.3189 230.501 39.4551 229.371C40.5913 228.241 41.9397 227.346 43.4228 226.739C44.9058 226.131 46.4942 225.823 48.0968 225.832L257.123 226.953C260.337 226.971 263.413 228.259 265.68 230.538C267.947 232.817 269.219 235.9 269.219 239.115V552.005C269.218 555.055 268.071 557.993 266.005 560.237C263.94 562.481 261.106 563.867 258.067 564.12Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 77%)`}
    />
    <path
      d="M258.862 564.12L49.8372 581.52C48.1609 581.66 46.4739 581.45 44.8827 580.904C43.2916 580.358 41.831 579.489 40.5933 578.349C39.3555 577.21 38.3675 575.827 37.6917 574.286C37.0159 572.746 36.667 571.082 36.667 569.4V237.994C36.667 236.391 36.9837 234.804 37.5989 233.324C38.2142 231.845 39.1158 230.501 40.252 229.371C41.3882 228.241 42.7366 227.346 44.2196 226.739C45.7027 226.131 47.2911 225.823 48.8937 225.832L257.919 226.953C261.134 226.971 264.21 228.259 266.477 230.538C268.743 232.817 270.016 235.9 270.016 239.115V552.005C270.015 555.055 268.867 557.994 266.802 560.238C264.736 562.482 261.902 563.867 258.862 564.12Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
    />
    <path
      d="M48.8111 576.78C46.8561 576.775 44.9828 575.996 43.6021 574.612C42.2214 573.228 41.4461 571.352 41.4463 569.397V237.991C41.4483 236.034 42.2265 234.157 43.6103 232.773C44.9941 231.389 46.8703 230.611 48.8275 230.608L257.893 231.729C259.843 231.743 261.708 232.527 263.083 233.909C264.458 235.292 265.231 237.161 265.236 239.111V552.001C265.226 553.85 264.526 555.629 263.274 556.989C262.022 558.35 260.307 559.194 258.466 559.358L49.4406 576.753C49.2289 576.771 49.0191 576.78 48.8111 576.78Z"
      fill="#fff"
    />
    <path
      d="M746.616 564.12L955.641 581.517C957.318 581.656 959.005 581.447 960.596 580.901C962.187 580.355 963.648 579.485 964.885 578.346C966.123 577.207 967.111 575.823 967.787 574.283C968.463 572.743 968.812 571.079 968.812 569.397V237.99C968.812 236.388 968.495 234.801 967.88 233.321C967.264 231.841 966.363 230.498 965.227 229.367C964.09 228.237 962.742 227.343 961.259 226.735C959.776 226.128 958.187 225.82 956.585 225.828L747.561 226.949C744.347 226.966 741.271 228.255 739.004 230.534C736.737 232.813 735.465 235.896 735.465 239.11V552.001C735.465 555.051 736.611 557.991 738.677 560.235C740.743 562.48 743.576 563.867 746.616 564.12V564.12Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
    />
    <path
      d="M742.745 564.12L951.771 581.52C953.448 581.66 955.135 581.45 956.726 580.904C958.317 580.358 959.777 579.489 961.015 578.349C962.253 577.21 963.241 575.827 963.917 574.286C964.593 572.746 964.942 571.082 964.942 569.4V237.994C964.942 236.391 964.625 234.804 964.01 233.324C963.394 231.845 962.493 230.501 961.356 229.371C960.22 228.241 958.872 227.346 957.389 226.739C955.906 226.131 954.317 225.823 952.715 225.832L743.689 226.953C740.475 226.971 737.398 228.259 735.132 230.538C732.865 232.817 731.593 235.9 731.593 239.115V552.005C731.594 555.055 732.741 557.993 734.806 560.237C736.872 562.481 739.705 563.867 742.745 564.12V564.12Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 77%)`}
    />
    <path
      d="M741.948 564.12L950.973 581.517C952.65 581.656 954.337 581.447 955.928 580.901C957.519 580.355 958.98 579.485 960.217 578.346C961.455 577.207 962.443 575.823 963.119 574.283C963.795 572.743 964.144 571.079 964.144 569.397V237.99C964.144 236.388 963.827 234.801 963.212 233.321C962.597 231.841 961.695 230.498 960.559 229.367C959.423 228.237 958.074 227.343 956.591 226.735C955.108 226.128 953.52 225.82 951.917 225.828L742.891 226.95C739.677 226.967 736.601 228.256 734.334 230.535C732.067 232.814 730.795 235.897 730.795 239.111V552.002C730.795 555.052 731.942 557.992 734.008 560.236C736.074 562.481 738.908 563.867 741.948 564.12Z"
      fill={`url(#paint38_radial_${sponsorId})`}
    />
    <path
      d="M952 576.78C953.955 576.775 955.828 575.996 957.209 574.612C958.59 573.228 959.365 571.352 959.365 569.397V237.991C959.363 236.034 958.585 234.157 957.201 232.773C955.817 231.389 953.941 230.611 951.984 230.608L742.918 231.729C740.969 231.743 739.104 232.527 737.729 233.909C736.354 235.292 735.58 237.161 735.575 239.111V552.001C735.585 553.85 736.285 555.629 737.537 556.989C738.789 558.35 740.504 559.194 742.345 559.358L951.371 576.753C951.582 576.771 951.792 576.78 952 576.78Z"
      fill="#fff"
    />
    <path
      d="M589.62 402.313V301.702H411.195V402.313H589.62Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
    />
    <path
      d="M588.253 400.945V303.065H412.559V400.945H588.253Z"
      fill="#fff"
    />
    <path
      d="M741.705 0.910767H259.258C252.541 0.910767 247.096 6.3558 247.096 13.0726V153.519C247.096 160.236 252.541 165.681 259.258 165.681H741.705C748.422 165.681 753.867 160.236 753.867 153.519V13.0726C753.867 6.3558 748.422 0.910767 741.705 0.910767Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
    />
    <path
      d="M741.705 0H259.258C252.541 0 247.096 5.44503 247.096 12.1618V152.608C247.096 159.325 252.541 164.77 259.258 164.77H741.705C748.422 164.77 753.867 159.325 753.867 152.608V12.1618C753.867 5.44503 748.422 0 741.705 0Z"
      fill={`url(#paint39_radial_${sponsorId})`}
    />
    <path
      d="M747.723 152.608V12.1618C747.723 8.83868 745.029 6.14474 741.706 6.14474L259.258 6.14474C255.935 6.14474 253.241 8.83868 253.241 12.1618V152.608C253.241 155.931 255.935 158.625 259.258 158.625L741.706 158.625C745.029 158.625 747.723 155.931 747.723 152.608Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${sponsorId}`}
        x1="117.725"
        y1="597.576"
        x2="883.087"
        y2="597.576"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.325"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.734"
          stopColor={`hsl(${tc(backColor).spin(22).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.753"
          stopColor={`hsl(${tc(backColor).spin(18).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.782"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.87"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${sponsorId}`}
        x1="504.233"
        y1="553.244"
        x2="504.228"
        y2="571.768"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.268"
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_${sponsorId}`}
        x1="496.579"
        y1="553.574"
        x2="496.583"
        y2="573.383"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 38%)`}
        />
        <stop
          offset="0.268"
          stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_${sponsorId}`}
        x1="500.405"
        y1="589.554"
        x2="500.405"
        y2="165.339"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.059"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.846"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.854"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.866"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.879"
          stopColor={`hsl(${tc(backColor).spin(8).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.894"
          stopColor={`hsl(${tc(backColor).spin(5).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.912"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.94"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.972"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.979"
          stopColor={`hsl(${tc(backColor).spin(22).toHsl().h}, ${backSaturation}, 41%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_${sponsorId}`}
        x1="114.083"
        y1="377.446"
        x2="226.889"
        y2="377.446"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 39%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_${sponsorId}`}
        x1="111.433"
        y1="315.278"
        x2="889.379"
        y2="315.278"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(0).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.114"
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.156"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 48%)`}
        />
        <stop
          offset="0.185"
          stopColor={`hsl(${tc(backColor).spin(3).toHsl().h}, ${backSaturation}, 47%)`}
        />
        <stop
          offset="0.209"
          stopColor={`hsl(${tc(backColor).spin(4).toHsl().h}, ${backSaturation}, 45%)`}
        />
        <stop
          offset="0.229"
          stopColor={`hsl(${tc(backColor).spin(7).toHsl().h}, ${backSaturation}, 41%)`}
        />
        <stop
          offset="0.247"
          stopColor={`hsl(${tc(backColor).spin(9).toHsl().h}, ${backSaturation}, 37%)`}
        />
        <stop
          offset="0.263"
          stopColor={`hsl(${tc(backColor).spin(14).toHsl().h}, ${backSaturation}, 33%)`}
        />
        <stop
          offset="0.275"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 29%)`}
        />
        <stop
          offset="0.828"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 29%)`}
        />
        <stop
          offset="0.829"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 32%)`}
        />
        <stop
          offset="0.832"
          stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 37%)`}
        />
        <stop
          offset="0.835"
          stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 42%)`}
        />
        <stop
          offset="0.838"
          stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 45%)`}
        />
        <stop
          offset="0.843"
          stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 47%)`}
        />
        <stop
          offset="0.848"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.862"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.941"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 29%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 38%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint6_linear_${sponsorId}`}
        x1="115.715"
        y1="602.773"
        x2="885.097"
        y2="602.773"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.428"
          stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.582"
          stopColor={`hsl(${tc(backColor).spin(3).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.692"
          stopColor={`hsl(${tc(backColor).spin(6).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.78"
          stopColor={`hsl(${tc(backColor).spin(10).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.855"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.862"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`}
        />
        <stop
          offset="0.941"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 29%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 38%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint7_linear_${sponsorId}`}
        x1="255.619"
        y1="412.436"
        x2="333.985"
        y2="412.43"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
      </linearGradient>
      <radialGradient
        id={`paint8_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(440.489 289.35) scale(457.308 457.389)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <linearGradient
        id={`paint9_linear_${sponsorId}`}
        x1="286.431"
        y1="342.456"
        x2="286.431"
        y2="335.248"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint10_linear_${sponsorId}`}
        x1="286.549"
        y1="370.448"
        x2="286.549"
        y2="363.128"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint11_linear_${sponsorId}`}
        x1="286.611"
        y1="398.251"
        x2="286.611"
        y2="390.798"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint12_linear_${sponsorId}`}
        x1="286.763"
        y1="426.039"
        x2="286.763"
        y2="418.506"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint13_linear_${sponsorId}`}
        x1="286.8"
        y1="454.013"
        x2="286.8"
        y2="446.101"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint14_linear_${sponsorId}`}
        x1="270.017"
        y1="394.628"
        x2="303.6"
        y2="394.628"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint15_linear_${sponsorId}`}
        x1="270.017"
        y1="394.628"
        x2="303.576"
        y2="394.628"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint16_linear_${sponsorId}`}
        x1="270.017"
        y1="394.629"
        x2="303.57"
        y2="394.629"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint17_linear_${sponsorId}`}
        x1="270.017"
        y1="394.62"
        x2="303.576"
        y2="394.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint18_linear_${sponsorId}`}
        x1="270.017"
        y1="394.604"
        x2="303.583"
        y2="394.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint19_linear_${sponsorId}`}
        x1="-4.22874"
        y1="335.248"
        x2="-4.22874"
        y2="335.248"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint20_linear_${sponsorId}`}
        x1="-4.11261"
        y1="363.128"
        x2="-4.11261"
        y2="363.128"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint21_linear_${sponsorId}`}
        x1="-4.0381"
        y1="390.798"
        x2="-4.0381"
        y2="390.798"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint22_linear_${sponsorId}`}
        x1="-3.89722"
        y1="418.506"
        x2="-3.89722"
        y2="418.506"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint23_linear_${sponsorId}`}
        x1="-3.80276"
        y1="446.101"
        x2="-3.80276"
        y2="446.101"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
        <stop
          offset="0.041"
          stopColor={`hsl(${tc(backColor).spin(28).toHsl().h}, ${backSaturation}, 58%)`}
        />
        <stop
          offset="0.117"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.197"
          stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.283"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.377"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.5"
          stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.645"
          stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`}
        />
        <stop
          offset="0.737"
          stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.815"
          stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`}
        />
        <stop
          offset="0.884"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`}
        />
        <stop
          offset="0.948"
          stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 57%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 60%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint24_linear_${sponsorId}`}
        x1="-20.6429"
        y1="394.628"
        x2="12.9076"
        y2="394.628"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint25_linear_${sponsorId}`}
        x1="-20.6445"
        y1="394.628"
        x2="12.9153"
        y2="394.628"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint26_linear_${sponsorId}`}
        x1="-20.6324"
        y1="394.629"
        x2="12.9214"
        y2="394.629"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint27_linear_${sponsorId}`}
        x1="-20.6435"
        y1="394.62"
        x2="12.9161"
        y2="394.62"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint28_linear_${sponsorId}`}
        x1="-20.5859"
        y1="394.604"
        x2="12.9804"
        y2="394.604"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
        />
        <stop
          offset="0.279"
          stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`}
          stopOpacity="0"
        />
      </linearGradient>
      <linearGradient
        id={`paint29_linear_${sponsorId}`}
        x1="310.07"
        y1="169.416"
        x2="331.767"
        y2="169.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint30_linear_${sponsorId}`}
        x1="399.852"
        y1="169.416"
        x2="421.548"
        y2="169.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint31_linear_${sponsorId}`}
        x1="489.634"
        y1="169.416"
        x2="511.33"
        y2="169.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint32_linear_${sponsorId}`}
        x1="579.416"
        y1="169.416"
        x2="601.112"
        y2="169.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint33_linear_${sponsorId}`}
        x1="669.196"
        y1="169.416"
        x2="690.893"
        y2="169.416"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="0.31"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 81%)`}
        />
        <stop
          offset="0.665"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 51%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint34_linear_${sponsorId}`}
        x1="500.405"
        y1="573.203"
        x2="500.405"
        y2="620.297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint35_linear_${sponsorId}`}
        x1="357.768"
        y1="484.638"
        x2="643.044"
        y2="484.638"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint36_linear_${sponsorId}`}
        x1="357.768"
        y1="477.356"
        x2="643.044"
        y2="477.356"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
        />
        <stop
          offset="0.503"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
        />
      </linearGradient>
      <linearGradient
        id={`paint37_linear_${sponsorId}`}
        x1="374.666"
        y1="604.056"
        x2="626.146"
        y2="604.056"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 69%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </linearGradient>
      <radialGradient
        id={`paint38_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1158.87 246.105) scale(689.198 689.406)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
      <radialGradient
        id={`paint39_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(223.938 -50.0901) scale(1047.5 1047.94)"
      >
        <stop
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`}
        />
        <stop
          offset="0.352"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
        />
        <stop
          offset="1"
          stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`}
        />
      </radialGradient>
    </defs>
  </>
)

export default bannerDrawing
