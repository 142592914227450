import React, { memo, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { IQuestion } from 'stores/faq'
import styled from 'styled-components/macro'

function QuestionItem({ item }: { item: IQuestion }) {
  const [open, setOpen] = useState(false)
  return (
    <QuestionWrapper open={open}>
      <Question onClick={() => setOpen(!open)}>
        <ChevronDown />
        {item.question}
      </Question>
      {open && <Answer dangerouslySetInnerHTML={{ __html: item.answer }} />}
    </QuestionWrapper>
  )
}

export default memo(QuestionItem)

const QuestionWrapper = styled.div<{ open?: boolean }>`
  position: relative;
  padding: 0;
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  svg {
    position: absolute;
    transition: transform 0.2s ease-out;
    top: -2px;
    left: 0;

    ${({ open }) => (open ? 'transform: rotate(180deg);' : '')}
  }
`

const Question = styled.p`
  cursor: pointer;
  font-weight: bold;
  color: #0f36a0;
  font-size: 18px;
  padding: 0 0 20px 35px;
  border-bottom: 1px solid #ccc;
`

const Answer = styled.div`
  padding: 20px 0;
  font-size: 16px;
  line-height: 1.4em;
`
