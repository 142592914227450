import React from 'react'
import { IAgendaSpeaker } from 'stores/agenda'
import styled from 'styled-components/macro'

const SpeakerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

const Avatar = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`

const Name = styled.span`
  font-weight: bold;
`

const Job = styled.span`
  margin: 5px 0;
`

const Company = styled.span`
  color: #00b9b4;
`

const SpeakerCard = ({ speaker }: { speaker: IAgendaSpeaker }) => (
  <SpeakerWrapper>
    <Avatar
      src={speaker.image.replace('/upload/', '/upload/w_80,h_80,c_thumb,g_face,z_0.3/q_auto/')}
      width={80}
      height={80}
      alt={speaker.name}
    />
    <Details>
      <Name>{speaker.name}</Name>
      <Job>{speaker.jobTitle}</Job>
      <Company>{speaker.company}</Company>
    </Details>
  </SpeakerWrapper>
)

export default SpeakerCard
