import React, { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'

interface IHeroProps {
  title: string
  children?: ReactNode
}

export default function Hero({ title, children }: IHeroProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {children && <>{children}</>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #dbe3fb;

  ${tablet} {
    padding: 20px;
  }
`
const Title = styled.h1`
  font-size: 30px;
  text-align: center;
`
