import 'animate.css/animate.css'
import Footer from 'components/Footer'
import { ContentLoader } from 'components/Loader'
import SponsorsBanners from 'components/SponsorsBanners'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'

const BannersWrapper = styled.div`
  width: 100%;
  height: 100%;
  /*padding-top: 20px;*/
`

const LobbySvg = styled.figure`
  position: relative;
  width: 100%;
`

const Scroll = styled.section`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
`
const ScrollDown = styled.div`
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  cursor: pointer;
`

const Arrow = styled.div`
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
  width: 20px;
  height: 20px;
`
const Lobby = styled.div`
  max-height: calc(100vh - 60px);
  overflow: hidden;
`

const LeftImg = styled.image`
  clip-path: polygon(0 0, 97% 3%, 98% 94%, 0 100%);
`

const LeftParImg = styled.image`
  clip-path: polygon(0 0, 93% 0, 97% 99%, 6% 100%);
`

const MiddleTopImg = styled.image`
  clip-path: polygon(0.5% 0.5%, 99.25% 0.25%, 99.25% 97.25%, 1% 98.25%);
`

const MiddleLeft = styled.image`
  clip-path: polygon(1% 2%, 98% 1%, 98% 97%, 2% 97%);
`

const MiddleRight = styled.image`
  clip-path: polygon(1% 1%, 98.25% 1%, 98.25% 97%, 2% 97%);
`

const RightParImg = styled.image`
  clip-path: polygon(6% 1%, 97% 0, 93% 99.75%, 3% 98.75%);
`
const RightImg = styled.image`
  clip-path: polygon(2.5% 4%, 99% 2%, 98% 97%, 1.5% 94%);
`

export default observer(function HomePage() {
  const { fullSponsorsSections, fetchFullSponsors, fullSponsorsLoadingStatus } = useRootData(
    (store) => store.sponsorsStore
  )

  useEffect(() => {
    fetchFullSponsors()
    // eslint-disable-next-line
  }, [])

  return (
    <Footer>
      <LobbySvg id="lobbysvg">
        <Scroll id="section" className="demo">
          <ScrollDown className="animate__animated animate__bounce">
            <Arrow />
          </ScrollDown>
        </Scroll>
        <Lobby>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1920 1080"
            preserveAspectRatio="xMinYMin meet"
          >
            <image width="1920" height="1080" xlinkHref="/Lobby_banner.jpg" />
            <a href="/">
              <LeftImg x="96" y="264" width="257" height="150" xlinkHref="/323x181_left.jpg" />
            </a>
            <a href="/">
              <LeftParImg x="418" y="145" width="132" height="402" xlinkHref="/171x529_left.jpg" />
            </a>
            <a href="/">
              <MiddleTopImg
                x="640"
                y="205"
                fill="#FFFFFF"
                width="640"
                height="141"
                xlinkHref="/849x183.jpg"
              />
            </a>
            <a href="/">
              <MiddleLeft
                x="643"
                y="375"
                fill="#FFFFFF"
                width="245"
                height="140"
                xlinkHref="/340x197_left.jpg"
              />
            </a>
            <a href="/">
              <MiddleRight
                x="1032"
                y="375"
                fill="#FFFFFF"
                width="245"
                height="140"
                xlinkHref="/340x197_right.jpg"
              />
            </a>
            <a href="/">
              <RightParImg
                x="1370"
                y="145"
                fill="#FFFFFF"
                width="132"
                height="402"
                xlinkHref="/171x529_right.jpg"
              />
            </a>
            <a href="/">
              <RightImg
                x="1551"
                y="264"
                fill="#FFFFFF"
                width="255"
                height="150"
                xlinkHref="/323x181_right.jpg"
              />
            </a>
          </svg>
        </Lobby>
      </LobbySvg>
      <BannersWrapper>
        <ContentLoader loadingStatus={fullSponsorsLoadingStatus()}>
          {() => (
            <>
              {fullSponsorsSections().map(({ id, title, sponsors }, index) => (
                <SponsorsBanners key={id + '_' + index} sponsors={sponsors} title={title} />
              ))}
            </>
          )}
        </ContentLoader>
      </BannersWrapper>
    </Footer>
  )
})
