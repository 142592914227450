import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'

export default observer(() => {
  const { completeLoginSaml, accessToken, samlState } = useRootData((store) => store.appStore)
  const location = useLocation()
  const history = useHistory()
  const token = useMemo(() => (queryString.parse(location.search).token || '') as string, [
    location,
  ])

  useEffect(() => {
    if (accessToken) {
      history.replace('/')
    }
    // eslint-disable-next-line
  }, [accessToken])

  useEffect(() => {
    if (!accessToken) {
      completeLoginSaml(token)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      <ContentLoader error="Failed to login with your token!" loadingStatus={samlState}>
        {() => <p>Success login</p>}
      </ContentLoader>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100vh;
`
