const mobileWidth = 640
const tabletWidth = 1000
const notebookWidth = 1366
const desktopWidth = 1600

const mobile = `@media (max-width: ${mobileWidth}px)`
const tablet = `@media (max-width: ${tabletWidth}px)`
const notebook = `@media (max-width: ${notebookWidth}px)`
const desktop = `@media (max-width: ${desktopWidth}px)`

export { mobile, tablet, mobileWidth, tabletWidth, notebook, notebookWidth, desktop, desktopWidth }
