import React, { memo, useCallback, useMemo, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { ChevronDown, ChevronUp } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from 'styled-components/macro'
import { IPrivateRoom, IStream } from '../../stores/chat'
import { IRepresentative, ISponsor } from '../../stores/sponsors'
import langCodes from '../../utils/langCodes'
import niceShadow from '../../utils/niceShadow'
import { tablet } from '../../utils/responsive'

interface IChatWindowTitleProps {
  ownId: number
  sponsor?: ISponsor
  currentStream?: IStream
  currentRoom?: IPrivateRoom
  onSelectRepresentative: (representative: IRepresentative) => void
  setChatWindowVisibility: (visibility: boolean) => void
}

interface IRepresentativesSelectProps {
  representatives: IRepresentative[]
  active?: IRepresentative
  onSelectRepresentative: (representative: IRepresentative) => void
  visible: boolean
}

interface IFlagsProps {
  localizations: string[]
}

const Flags = ({ localizations }: IFlagsProps) => (
  <>
    {localizations.map((lang, index) => {
      lang = lang === 'en' ? 'gb' : lang
      lang = lang === 'br' ? 'be' : lang
      return (
        <ReactCountryFlag
          key={lang}
          countryCode={lang}
          className="lang"
          title={langCodes[lang as keyof typeof langCodes]?.nativeName}
          style={{
            flex: '0 0 18px',
            height: '14px',
            marginBottom: '0px',
            marginLeft: index === 0 ? '8px' : '4px',
            width: 'unset',
          }}
        />
      )
    })}
  </>
)

const RepresentativesSelect = memo(
  ({ representatives, active, onSelectRepresentative, visible }: IRepresentativesSelectProps) => {
    return (
      <RepresentativesSelectWrapper show={visible}>
        {representatives.map((representative) => {
          return (
            <RepresentativeWrapper
              key={representative.id}
              className={representative.id === active?.id ? 'active' : ''}
              onClick={() => onSelectRepresentative(representative)}
            >
              {representative.firstName + ' ' + representative.lastName}
              {representative.localizations && (
                <Flags localizations={representative.localizations} />
              )}
            </RepresentativeWrapper>
          )
        })}
      </RepresentativesSelectWrapper>
    )
  }
)

export default memo(
  ({
    ownId,
    sponsor,
    currentStream,
    currentRoom,
    onSelectRepresentative,
    setChatWindowVisibility,
  }: IChatWindowTitleProps) => {
    const [visible, setVisible] = useState<boolean>(false)

    const representatives = useMemo(() => {
      if (sponsor) {
        return (sponsor.representatives || []).filter((r) => r.chatUserId)
      }
      return []
    }, [sponsor])
    const isSelect = sponsor && currentRoom && representatives.length > 1
    const currentRepresentative = currentRoom
      ? representatives.find((r) => r.chatUserId === currentRoom.user_id)
      : undefined

    const nameClick = useCallback(() => {
      setVisible(!visible)
      setChatWindowVisibility(true)
    }, [visible, setChatWindowVisibility])

    return (
      <ChatWindowTitleWrapper className={sponsor ? 'sponsor' : ''}>
        {sponsor && <img src={sponsor.logoWhiteUrl || sponsor.logoUrl} alt="" />}
        <NameWrapper
          className={isSelect ? 'select' : ''}
          onClick={isSelect ? nameClick : undefined}
        >
          {isSelect && (
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
              <RepresentativesSelect
                representatives={representatives}
                active={currentRepresentative}
                onSelectRepresentative={onSelectRepresentative}
                visible={visible}
              />
            </OutsideClickHandler>
          )}
          <span>
            {currentRoom
              ? currentRoom.user_id === ownId
                ? 'My Notes'
                : `${currentRoom.full_name}`
              : currentStream
              ? currentStream.name === 'general'
                ? 'Event Notifications'
                : `${currentStream.name}`
              : ''}
          </span>
          {currentRepresentative?.localizations && (
            <Flags localizations={currentRepresentative.localizations} />
          )}
          {isSelect && (visible ? <ChevronUp size="14" /> : <ChevronDown size="14" />)}
        </NameWrapper>
      </ChatWindowTitleWrapper>
    )
  }
)

const NameWrapper = styled.div`
  display: flex;
  min-width: 0;
  align-items: center;
  flex-grow: 1;

  .sponsor & {
    span {
      display: inline;
      flex: 0 1 auto;
      min-width: 0;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .withSidebar.isExpanded &,
    .fullChat & {
      span {
        display: inline;
      }
    }
  }

  .isExpanded & {
    svg {
      display: block;
    }
  }

  svg {
    margin: 4px 0 0 5px;
    min-width: 14px;
    display: none;
  }

  &.select {
    cursor: pointer;
  }
`

const ChatWindowTitleWrapper = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0;
  flex: 1 1 0;
  min-width: 0;
  padding-left: 13px;

  .lang {
    display: none !important;
  }

  .isExpanded & {
    padding-left: 0;

    .lang {
      display: inline-block !important;
    }

    img {
      display: block;
    }
  }

  .fullChat & {
    color: inherit;
    padding-left: 30px;
  }

  img {
    height: 20px;
    padding-right: 8px;
    display: none;
  }

  ${tablet} {
    display: none;

    .isExpanded & {
      display: flex;
    }
  }
`

const RepresentativesSelectWrapper = styled.div<{ show?: boolean }>`
  ${niceShadow};
  position: absolute;
  top: 43px;
  z-index: 10;
  padding: 10px 0;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: ${({ show }) => (show ? '10000px' : '1px')};
  transition: all 0.1s ease-out;
  background-color: #fff;
  border-radius: 2px;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  ${tablet} {
    position: absolute;
    padding: ${(props) => (props.show ? '15px 0' : 0)};
  }
`

const RepresentativeWrapper = styled.div`
  color: black;
  padding: 6px 10px;
  font-weight: normal;

  &:hover,
  &.active {
    background-color: #456dde;
    color: white;
  }

  &.active {
    cursor: default;
    background-color: #8fa7ea;
    display: none;
  }
`
