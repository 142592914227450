import React from 'react'
import tc from 'tinycolor2'
import { IBannerLayoutProps } from './ScenePreview'

const bannerDrawing = ({
  backColor,
  backSaturation,
  frontColor,
  sponsorId,
  frontHue,
  frontSaturation,
}: IBannerLayoutProps) => (
  <>
    <path
      d="M799.305 581.009C799.305 581.009 785.498 586.926 778.823 589.505C772.147 592.085 759.553 594.664 743.167 594.967C726.781 595.271 643.031 594.967 643.031 594.967H364.924C364.924 594.967 281.174 595.271 264.788 594.967C248.402 594.664 235.809 592.084 229.133 589.505C222.457 586.927 208.65 581.009 208.65 581.009L121.297 589.467C121.297 589.467 143.866 600.922 154.486 606.687C165.106 612.452 184.224 613.97 234.292 613.97H773.664C823.732 613.97 842.849 612.452 853.47 606.687C864.091 600.922 886.659 589.467 886.659 589.467L799.305 581.009Z"
      fill={`url(#paint0_linear_${sponsorId})`}
    />
    <path
      d="M799.305 581.009C799.305 581.009 785.498 586.926 778.823 589.505C772.147 592.085 759.553 594.664 743.167 594.967C726.781 595.271 643.031 594.967 643.031 594.967H364.924C364.924 594.967 281.174 595.271 264.788 594.967C248.402 594.664 235.809 592.084 229.133 589.505C222.457 586.927 208.65 581.009 208.65 581.009L121.297 589.467C121.297 589.467 143.866 600.922 154.486 606.687C165.106 612.452 184.224 613.97 234.292 613.97H773.664C823.732 613.97 842.849 612.452 853.47 606.687C864.091 600.922 886.659 589.467 886.659 589.467L799.305 581.009Z"
      fill={`url(#paint1_linear_${sponsorId})`}
    />
    <path
      d="M799.305 581.009C799.305 581.009 785.498 586.926 778.823 589.505C772.147 592.085 759.553 594.664 743.167 594.967C726.781 595.271 643.031 594.967 643.031 594.967H364.924C364.924 594.967 281.174 595.271 264.788 594.967C248.402 594.664 235.809 592.084 229.133 589.505C222.457 586.927 208.65 581.009 208.65 581.009L121.297 589.467C121.297 589.467 143.866 600.922 154.486 606.687C165.106 612.452 184.224 613.97 234.292 613.97H773.664C823.732 613.97 842.849 612.452 853.47 606.687C864.091 600.922 886.659 589.467 886.659 589.467L799.305 581.009Z"
      fill={`url(#paint2_linear_${sponsorId})`}
    />
    <path
      d="M801.465 257.537C801.465 262.847 799.305 581.009 799.305 581.009L886.658 589.467C886.658 589.467 889.936 263.744 889.936 257.03L801.465 257.537ZM118.019 256.946C118.019 263.66 121.296 589.467 121.296 589.467L208.65 581.009L206.489 257.396L118.019 256.946Z"
      fill={`url(#paint3_linear_${sponsorId})`}
    />
    <path
      d="M115.393 256.946L119.284 590.416L121.294 589.467L118.017 256.946H115.393ZM889.938 257.03C889.938 263.744 886.661 589.467 886.661 589.467L888.671 590.416C888.671 590.416 892.563 264.313 892.563 257.029L889.938 257.03Z"
      fill={`url(#paint5_linear_${sponsorId})`}
    />
    <path
      d="M119.286 590.416C119.286 590.416 143.865 602.857 154.485 608.622C165.106 614.388 184.223 615.905 234.291 615.905H773.663C823.732 615.905 842.849 614.388 853.469 608.622C864.09 602.857 888.669 590.416 888.669 590.416L886.659 589.467C886.659 589.467 864.09 600.922 853.469 606.687C842.849 612.452 823.732 613.97 773.663 613.97H234.291C184.223 613.97 165.106 612.452 154.485 606.687C143.865 600.922 121.296 589.467 121.296 589.467L119.286 590.416Z"
      fill={`url(#paint6_linear_${sponsorId})`}
    />
    <path
      d="M707.209 231.281C571.721 184.24 436.233 184.24 300.745 231.281L304.289 255.413C304.292 255.413 306.215 531.377 306.215 531.377L308.028 532.685C438.562 525.504 569.392 525.504 699.926 532.685L701.74 531.377C701.74 531.377 703.662 255.413 703.666 255.413L707.209 231.281Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 50%)`}
    />
    <path
      d="M699.926 532.685C569.392 525.504 438.562 525.504 308.028 532.685L306.092 255.413H701.862L699.926 532.685Z"
      fill={`url(#paint7_radial_${sponsorId})`}
    />
    <path
      d="M704.554 242.289L701.861 255.413H306.092L303.4 242.289C437.118 195.862 570.836 195.862 704.554 242.289Z"
      fill={`url(#paint8_radial_${sponsorId})`}
    />
    <path
      d="M707.209 231.281C571.721 184.24 436.233 184.24 300.745 231.281L303.4 242.289C437.118 195.862 570.836 195.862 704.554 242.289L707.209 231.281Z"
      fill={`url(#paint9_linear_${sponsorId})`}
    />
    <path
      d="M305.43 335.162L273.588 335.75V342.369L305.43 341.781C306.745 341.781 306.745 335.162 305.43 335.162Z"
      fill={`url(#paint10_linear_${sponsorId})`}
    />
    <path
      d="M305.665 363.041L273.588 363.743V370.361L305.665 369.66C306.98 369.66 306.98 363.041 305.665 363.041Z"
      fill={`url(#paint11_linear_${sponsorId})`}
    />
    <path
      d="M305.79 390.711L273.588 391.546V398.168L305.79 397.333C307.105 397.33 307.105 390.711 305.79 390.711Z"
      fill={`url(#paint12_linear_${sponsorId})`}
    />
    <path
      d="M306.094 418.419L273.588 419.33V425.949L306.094 425.039C307.409 425.039 307.409 418.419 306.094 418.419Z"
      fill={`url(#paint13_linear_${sponsorId})`}
    />
    <path
      d="M306.17 446.014L273.588 447.304V453.922L306.17 452.633C307.482 452.633 307.482 446.014 306.17 446.014Z"
      fill={`url(#paint14_linear_${sponsorId})`}
    />
    <g>
      <path
        d="M305.43 335.162L273.588 335.75V342.369L305.43 341.781C306.745 341.781 306.745 335.162 305.43 335.162Z"
        fill={`url(#paint15_linear_${sponsorId})`}
      />
      <path
        d="M305.665 363.041L273.588 363.743V370.361L305.665 369.66C306.98 369.66 306.98 363.041 305.665 363.041Z"
        fill={`url(#paint16_linear_${sponsorId})`}
      />
      <path
        d="M305.79 390.711L273.588 391.546V398.168L305.79 397.333C307.105 397.33 307.105 390.711 305.79 390.711Z"
        fill={`url(#paint17_linear_${sponsorId})`}
      />
      <path
        d="M306.094 418.419L273.588 419.33V425.949L306.094 425.039C307.409 425.039 307.409 418.419 306.094 418.419Z"
        fill={`url(#paint18_linear_${sponsorId})`}
      />
      <path
        d="M306.17 446.014L273.588 447.304V453.922L306.17 452.633C307.482 452.633 307.482 446.014 306.17 446.014Z"
        fill={`url(#paint19_linear_${sponsorId})`}
      />
    </g>
    <path
      d="M702.526 335.162L734.368 335.75V342.369L702.526 341.781C701.211 341.781 701.211 335.162 702.526 335.162Z"
      fill={`url(#paint20_linear_${sponsorId})`}
    />
    <path
      d="M702.29 363.041L734.368 363.743V370.361L702.29 369.66C700.975 369.66 700.975 363.041 702.29 363.041Z"
      fill={`url(#paint21_linear_${sponsorId})`}
    />
    <path
      d="M702.165 390.711L734.367 391.546V398.168L702.165 397.333C700.85 397.33 700.85 390.711 702.165 390.711Z"
      fill={`url(#paint22_linear_${sponsorId})`}
    />
    <path
      d="M701.861 418.419L734.368 419.33V425.949L701.861 425.039C700.546 425.039 700.546 418.419 701.861 418.419Z"
      fill={`url(#paint23_linear_${sponsorId})`}
    />
    <path
      d="M701.785 446.014L734.367 447.304V453.922L701.785 452.633C700.473 452.633 700.473 446.014 701.785 446.014Z"
      fill={`url(#paint24_linear_${sponsorId})`}
    />
    <path
      d="M702.526 335.162L734.368 335.75V342.369L702.526 341.781C701.211 341.781 701.211 335.162 702.526 335.162Z"
      fill={`url(#paint25_linear_${sponsorId})`}
    />
    <path
      d="M702.29 363.041L734.368 363.743V370.361L702.29 369.66C700.975 369.66 700.975 363.041 702.29 363.041Z"
      fill={`url(#paint26_linear_${sponsorId})`}
    />
    <path
      d="M702.165 390.711L734.367 391.546V398.168L702.165 397.333C700.85 397.33 700.85 390.711 702.165 390.711Z"
      fill={`url(#paint27_linear_${sponsorId})`}
    />
    <path
      d="M701.861 418.419L734.368 419.33V425.949L701.861 425.039C700.546 425.039 700.546 418.419 701.861 418.419Z"
      fill={`url(#paint28_linear_${sponsorId})`}
    />
    <path
      d="M701.785 446.014L734.367 447.304V453.922L701.785 452.633C700.473 452.633 700.473 446.014 701.785 446.014Z"
      fill={`url(#paint29_linear_${sponsorId})`}
    />
    <path
      d="M349.449 245.037H327.753V242.669C327.753 235.667 349.449 233.256 349.449 240.257V245.037Z"
      fill={`url(#paint30_linear_${sponsorId})`}
    />
    <path
      d="M658.432 245.037H680.127V242.669C680.127 235.667 658.431 233.256 658.431 240.257L658.432 245.037Z"
      fill={`url(#paint31_linear_${sponsorId})`}
    />
    <path
      d="M432.119 237.116H410.422V232.337C410.422 222.697 432.119 222.697 432.119 232.337V237.116Z"
      fill={`url(#paint32_linear_${sponsorId})`}
    />
    <path
      d="M597.458 237.116H575.762V232.337C575.762 222.697 597.458 222.697 597.458 232.337V237.116Z"
      fill={`url(#paint33_linear_${sponsorId})`}
    />
    <path
      d="M514.789 234.442H493.092V229.662C493.092 219.188 514.789 219.188 514.789 229.662V234.442Z"
      fill={`url(#paint34_linear_${sponsorId})`}
    />
    <path
      d="M338.601 251.545C344.592 251.545 349.449 248.631 349.449 245.037C349.449 241.443 344.592 238.53 338.601 238.53C332.61 238.53 327.753 241.443 327.753 245.037C327.753 248.631 332.61 251.545 338.601 251.545Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M421.271 243.623C427.262 243.623 432.119 240.71 432.119 237.116C432.119 233.522 427.262 230.609 421.271 230.609C415.279 230.609 410.422 233.522 410.422 237.116C410.422 240.71 415.279 243.623 421.271 243.623Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M503.941 241.303C509.932 241.303 514.789 238.39 514.789 234.796C514.789 231.202 509.932 228.289 503.941 228.289C497.949 228.289 493.092 231.202 493.092 234.796C493.092 238.39 497.949 241.303 503.941 241.303Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M586.61 243.623C592.601 243.623 597.458 240.71 597.458 237.116C597.458 233.522 592.601 230.609 586.61 230.609C580.619 230.609 575.762 233.522 575.762 237.116C575.762 240.71 580.619 243.623 586.61 243.623Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M669.28 251.545C675.271 251.545 680.128 248.631 680.128 245.037C680.128 241.443 675.271 238.53 669.28 238.53C663.289 238.53 658.432 241.443 658.432 245.037C658.432 248.631 663.289 251.545 669.28 251.545Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 93%)`}
    />
    <path
      d="M643.031 594.967H364.924L362.432 594.976L377.18 604.739C461.619 614.557 546.919 614.433 631.329 604.37L645.521 594.975L643.031 594.967Z"
      fill={`url(#paint35_linear_${sponsorId})`}
    />
    <path
      d="M646.616 478.976C551.621 485.045 456.335 485.045 361.34 478.976V485.575C456.335 491.644 551.621 491.644 646.616 485.575V478.976Z"
      fill={`url(#paint36_linear_${sponsorId})`}
    />
    <path
      d="M646.616 478.976C551.621 485.045 456.335 485.045 361.34 478.976L398.038 471.01C468.581 475.865 539.375 475.865 609.918 471.01L646.616 478.976Z"
      fill={`url(#paint37_linear_${sponsorId})`}
    />
    <path
      d="M378.238 597.193V604.602C461.865 612.795 546.091 612.795 629.718 604.602V597.193C546.057 604.641 461.899 604.641 378.238 597.193V597.193Z"
      fill={`url(#paint38_linear_${sponsorId})`}
    />
    <path
      d="M369.704 486.076V596.408C459.018 604.903 548.938 604.903 638.252 596.408V486.076C548.817 491.454 459.139 491.454 369.704 486.076V486.076Z"
      fill="#fff"
    />
    <path
      d="M257.778 535.821L93.9688 549.571C92.3035 549.71 90.6273 549.505 89.0447 548.968C87.4622 548.431 86.0074 547.574 84.7709 546.45C83.5345 545.325 82.543 543.958 81.8584 542.434C81.1738 540.909 80.8106 539.26 80.7917 537.589L77.7371 267.341C77.7189 265.73 78.0209 264.132 78.6255 262.638C79.2301 261.145 80.1254 259.787 81.2592 258.643C82.393 257.499 83.7429 256.591 85.2303 255.973C86.7178 255.355 88.3133 255.038 89.9242 255.042L256.785 255.405C260.006 255.413 263.092 256.697 265.367 258.977C267.642 261.257 268.92 264.346 268.92 267.567V523.702C268.92 526.751 267.775 529.689 265.711 531.933C263.648 534.178 260.816 535.565 257.778 535.821Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
    />
    <path
      d="M261.646 535.821L97.8374 549.571C96.1721 549.71 94.4959 549.505 92.9134 548.968C91.3309 548.431 89.876 547.574 88.6396 546.45C87.4031 545.325 86.4117 543.958 85.727 542.434C85.0424 540.909 84.6793 539.26 84.6604 537.589L81.6092 267.341C81.5911 265.73 81.8932 264.131 82.4979 262.638C83.1026 261.145 83.9978 259.787 85.1317 258.643C86.2656 257.499 87.6155 256.591 89.103 255.973C90.5905 255.355 92.186 255.038 93.7969 255.042L260.657 255.405C263.878 255.413 266.964 256.697 269.239 258.977C271.514 261.257 272.792 264.346 272.792 267.567V523.702C272.792 526.752 271.646 529.69 269.582 531.934C267.517 534.179 264.685 535.566 261.646 535.821V535.821Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 77%)`}
    />
    <path
      d="M262.443 535.821L98.6334 549.571C96.968 549.71 95.2918 549.505 93.7093 548.968C92.1268 548.431 90.6719 547.574 89.4355 546.45C88.1991 545.325 87.2076 543.958 86.5229 542.434C85.8383 540.909 85.4752 539.26 85.4563 537.589L82.4051 267.341C82.3869 265.73 82.6889 264.132 83.2935 262.638C83.8981 261.145 84.7934 259.787 85.9272 258.643C87.061 257.499 88.4109 256.591 89.8983 255.973C91.3858 255.355 92.9813 255.038 94.5922 255.042L261.453 255.405C264.674 255.413 267.76 256.697 270.035 258.977C272.31 261.257 273.588 264.346 273.588 267.567V523.702C273.588 526.752 272.442 529.69 270.378 531.934C268.313 534.179 265.481 535.566 262.443 535.821Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 19%)`}
    />
    <path
      d="M97.5994 544.835C95.6602 544.826 93.802 544.056 92.4247 542.691C91.0473 541.326 90.2609 539.474 90.2346 537.535L87.1841 267.287C87.1731 266.31 87.356 265.342 87.722 264.436C88.088 263.531 88.6299 262.707 89.3164 262.013C90.0029 261.319 90.8203 260.767 91.7212 260.391C92.6222 260.015 93.5889 259.821 94.5653 259.821L261.442 260.185C263.396 260.192 265.268 260.973 266.648 262.356C268.029 263.74 268.805 265.613 268.808 267.567V523.702C268.798 525.55 268.099 527.328 266.848 528.688C265.597 530.048 263.884 530.893 262.043 531.058L98.2344 544.808C98.0213 544.826 97.8097 544.835 97.5994 544.835Z"
      fill="#fff"
    />
    <path
      d="M750.177 535.821L913.986 549.571C915.652 549.71 917.328 549.505 918.91 548.968C920.493 548.431 921.948 547.574 923.184 546.45C924.421 545.325 925.412 543.958 926.097 542.434C926.781 540.909 927.144 539.26 927.163 537.589L930.215 267.341C930.233 265.73 929.931 264.132 929.326 262.638C928.722 261.145 927.826 259.787 926.693 258.643C925.559 257.499 924.209 256.591 922.721 255.973C921.234 255.355 919.638 255.038 918.027 255.042L751.164 255.405C747.943 255.413 744.856 256.697 742.581 258.977C740.306 261.257 739.028 264.346 739.028 267.567V523.702C739.029 526.752 740.175 529.691 742.24 531.935C744.305 534.18 747.138 535.567 750.177 535.821Z"
      fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`}
    />
    <path
      d="M746.308 535.821L910.117 549.571C911.782 549.71 913.458 549.505 915.041 548.968C916.623 548.431 918.078 547.574 919.315 546.45C920.551 545.325 921.542 543.958 922.227 542.434C922.912 540.909 923.275 539.26 923.294 537.589L926.345 267.341C926.363 265.73 926.061 264.131 925.456 262.638C924.852 261.145 923.956 259.787 922.822 258.643C921.689 257.499 920.339 256.591 918.851 255.973C917.364 255.355 915.768 255.038 914.157 255.042L747.297 255.405C744.076 255.413 740.99 256.697 738.715 258.977C736.44 261.257 735.162 264.346 735.162 267.567V523.702C735.162 526.752 736.308 529.69 738.372 531.934C740.437 534.179 743.269 535.566 746.308 535.821V535.821Z"
      fill={`hsl(${tc(backColor).spin(40).toHsl().h}, ${backSaturation}, 77%)`}
    />
    <path
      d="M745.512 535.821L909.321 549.571C910.987 549.71 912.663 549.505 914.245 548.968C915.828 548.431 917.283 547.574 918.519 546.45C919.756 545.325 920.747 543.958 921.432 542.434C922.116 540.909 922.479 539.26 922.498 537.589L925.55 267.341C925.568 265.73 925.266 264.132 924.661 262.638C924.056 261.145 923.161 259.787 922.027 258.643C920.894 257.499 919.544 256.591 918.056 255.973C916.569 255.355 914.973 255.038 913.363 255.042L746.499 255.405C743.278 255.413 740.191 256.697 737.916 258.977C735.641 261.257 734.363 264.346 734.363 267.567V523.702C734.364 526.752 735.51 529.691 737.575 531.935C739.64 534.18 742.473 535.567 745.512 535.821V535.821Z"
      fill={`url(#paint39_radial_${sponsorId})`}
    />
    <path
      d="M910.356 544.835C912.295 544.826 914.153 544.056 915.53 542.691C916.908 541.326 917.694 539.474 917.72 537.535L920.771 267.287C920.782 266.31 920.599 265.342 920.233 264.436C919.867 263.531 919.325 262.707 918.639 262.013C917.952 261.319 917.135 260.767 916.234 260.391C915.333 260.015 914.366 259.821 913.39 259.821L746.513 260.185C744.559 260.192 742.687 260.973 741.307 262.356C739.926 263.74 739.15 265.613 739.146 267.567V523.702C739.157 525.55 739.856 527.328 741.107 528.688C742.358 530.048 744.071 530.893 745.912 531.058L909.721 544.808C909.934 544.826 910.145 544.835 910.356 544.835Z"
      fill="#fff"
    />
    <path d="M593.193 402.224V301.613H414.768V402.224H593.193Z" fill={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
    <path d="M591.825 400.858V302.978H416.131V400.858H591.825Z" fill="#fff" />
    <defs>
      <linearGradient
        id={`paint0_linear_${sponsorId}`}
        x1="121.297"
        y1="597.489"
        x2="886.659"
        y2="597.489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.325" stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.734" stopColor={`hsl(${tc(backColor).spin(22).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.753" stopColor={`hsl(${tc(backColor).spin(18).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.782" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.87" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`} />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${sponsorId}`}
        x1="507.804"
        y1="553.157"
        x2="507.8"
        y2="571.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.268" stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint2_linear_${sponsorId}`}
        x1="500.151"
        y1="553.486"
        x2="500.155"
        y2="573.296"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 38%)`} />
        <stop offset="0.268" stopColor={`hsl(${tc(backColor).spin(30).toHsl().h}, ${backSaturation}, 49%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint3_linear_${sponsorId}`}
        x1="503.977"
        y1="589.467"
        x2="503.977"
        y2="256.946"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.16" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.846" stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.854" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.866" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.879" stopColor={`hsl(${tc(backColor).spin(8).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.894" stopColor={`hsl(${tc(backColor).spin(5).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.912" stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.94" stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.972" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.979" stopColor={`hsl(${tc(backColor).spin(22).toHsl().h}, ${backSaturation}, 41%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 49%)`} />
      </linearGradient>
      <linearGradient
        id={`paint4_linear_${sponsorId}`}
        x1="118.019"
        y1="423.207"
        x2="230.719"
        y2="423.207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 40%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(24).toHsl().h}, ${backSaturation}, 39%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint5_linear_${sponsorId}`}
        x1="115.393"
        y1="423.681"
        x2="892.563"
        y2="423.681"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.114" stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.156" stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 48%)`} />
        <stop offset="0.185" stopColor={`hsl(${tc(backColor).spin(3).toHsl().h}, ${backSaturation}, 47%)`} />
        <stop offset="0.209" stopColor={`hsl(${tc(backColor).spin(4).toHsl().h}, ${backSaturation}, 45%)`} />
        <stop offset="0.229" stopColor={`hsl(${tc(backColor).spin(7).toHsl().h}, ${backSaturation}, 41%)`} />
        <stop offset="0.247" stopColor={`hsl(${tc(backColor).spin(9).toHsl().h}, ${backSaturation}, 37%)`} />
        <stop offset="0.263" stopColor={`hsl(${tc(backColor).spin(14).toHsl().h}, ${backSaturation}, 33%)`} />
        <stop offset="0.275" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 29%)`} />
        <stop offset="0.828" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 29%)`} />
        <stop offset="0.829" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 32%)`} />
        <stop offset="0.832" stopColor={`hsl(${tc(backColor).spin(17).toHsl().h}, ${backSaturation}, 37%)`} />
        <stop offset="0.835" stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 42%)`} />
        <stop offset="0.838" stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 45%)`} />
        <stop offset="0.843" stopColor={`hsl(${tc(backColor).spin(16).toHsl().h}, ${backSaturation}, 47%)`} />
        <stop offset="0.848" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.862" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.941" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 29%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 38%)`} />
      </linearGradient>
      <linearGradient
        id={`paint6_linear_${sponsorId}`}
        x1="119.286"
        y1="602.686"
        x2="888.669"
        y2="602.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(1).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.428" stopColor={`hsl(${tc(backColor).spin(2).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.582" stopColor={`hsl(${tc(backColor).spin(3).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.692" stopColor={`hsl(${tc(backColor).spin(6).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.78" stopColor={`hsl(${tc(backColor).spin(10).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.855" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.862" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 49%)`} />
        <stop offset="0.941" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 29%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(21).toHsl().h}, ${backSaturation}, 38%)`} />
      </linearGradient>
      <radialGradient
        id={`paint7_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(443.214 289.795) scale(456.657 456.39)"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`} />
        <stop offset="0.352" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </radialGradient>
      <radialGradient
        id={`paint8_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-71.8837 107.314) scale(204.733 203.906)"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`} />
        <stop offset="0.352" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </radialGradient>
      <linearGradient
        id={`paint9_linear_${sponsorId}`}
        x1="300.745"
        y1="219.145"
        x2="707.209"
        y2="219.145"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(5).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.628" stopColor={`hsl(${tc(backColor).spin(5).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.854" stopColor={`hsl(${tc(backColor).spin(7).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(9).toHsl().h}, ${backSaturation}, 50%)`} />
      </linearGradient>
      <linearGradient
        id={`paint10_linear_${sponsorId}`}
        x1="290.002"
        y1="342.369"
        x2="290.002"
        y2="335.162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint11_linear_${sponsorId}`}
        x1="290.12"
        y1="370.361"
        x2="290.12"
        y2="363.041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint12_linear_${sponsorId}`}
        x1="290.182"
        y1="398.168"
        x2="290.182"
        y2="390.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint13_linear_${sponsorId}`}
        x1="290.334"
        y1="425.949"
        x2="290.334"
        y2="418.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint14_linear_${sponsorId}`}
        x1="290.371"
        y1="453.922"
        x2="290.371"
        y2="446.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint15_linear_${sponsorId}`}
        x1="273.588"
        y1="-483.016"
        x2="307.171"
        y2="-483.016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint16_linear_${sponsorId}`}
        x1="273.588"
        y1="-483.015"
        x2="307.148"
        y2="-483.015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint17_linear_${sponsorId}`}
        x1="273.588"
        y1="-483.419"
        x2="307.142"
        y2="-483.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint18_linear_${sponsorId}`}
        x1="273.588"
        y1="-483.022"
        x2="307.147"
        y2="-483.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint19_linear_${sponsorId}`}
        x1="273.588"
        y1="-483.021"
        x2="307.154"
        y2="-483.021"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, 53%, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint20_linear_${sponsorId}`}
        x1="-0.657235"
        y1="342.369"
        x2="-0.657235"
        y2="335.162"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint21_linear_${sponsorId}`}
        x1="-0.541139"
        y1="370.361"
        x2="-0.541139"
        y2="363.041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint22_linear_${sponsorId}`}
        x1="-0.465985"
        y1="398.168"
        x2="-0.465985"
        y2="390.711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint23_linear_${sponsorId}`}
        x1="-0.325748"
        y1="425.949"
        x2="-0.325748"
        y2="418.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint24_linear_${sponsorId}`}
        x1="-0.231073"
        y1="453.922"
        x2="-0.231073"
        y2="446.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
        <stop offset="0.041" stopColor={`hsl(${tc(backColor).spin(38).toHsl().h}, ${backSaturation}, 58%)`} />
        <stop offset="0.117" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.197" stopColor={`hsl(${tc(backColor).spin(20).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.283" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.377" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.5" stopColor={`hsl(${tc(backColor).spin(12).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.645" stopColor={`hsl(${tc(backColor).spin(13).toHsl().h}, ${backSaturation}, 50%)`} />
        <stop offset="0.737" stopColor={`hsl(${tc(backColor).spin(15).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.815" stopColor={`hsl(${tc(backColor).spin(19).toHsl().h}, ${backSaturation}, 53%)`} />
        <stop offset="0.884" stopColor={`hsl(${tc(backColor).spin(25).toHsl().h}, ${backSaturation}, 55%)`} />
        <stop offset="0.948" stopColor={`hsl(${tc(backColor).spin(34).toHsl().h}, ${backSaturation}, 57%)`} />
        <stop offset="1" stopColor={`hsl(${tc(backColor).spin(45).toHsl().h}, ${backSaturation}, 60%)`} />
      </linearGradient>
      <linearGradient
        id={`paint25_linear_${sponsorId}`}
        x1="-17.0714"
        y1="-483.016"
        x2="16.4791"
        y2="-483.016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint26_linear_${sponsorId}`}
        x1="-17.073"
        y1="-483.015"
        x2="16.4867"
        y2="-483.015"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint27_linear_${sponsorId}`}
        x1="-17.0603"
        y1="-483.419"
        x2="16.4936"
        y2="-483.419"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint28_linear_${sponsorId}`}
        x1="-17.072"
        y1="-483.022"
        x2="16.4876"
        y2="-483.022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint29_linear_${sponsorId}`}
        x1="-17.0142"
        y1="-483.02"
        x2="16.5521"
        y2="-483.02"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} />
        <stop offset="0.279" stopColor={`hsl(${tc(backColor).spin(27).toHsl().h}, ${backSaturation}, 51%)`} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id={`paint30_linear_${sponsorId}`}
        x1="327.753"
        y1="240.548"
        x2="349.449"
        y2="240.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="0.31" stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`} />
        <stop offset="0.665" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
      </linearGradient>
      <linearGradient
        id={`paint31_linear_${sponsorId}`}
        x1="551.164"
        y1="240.548"
        x2="572.861"
        y2="240.548"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="0.31" stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`} />
        <stop offset="0.665" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
      </linearGradient>
      <linearGradient
        id={`paint32_linear_${sponsorId}`}
        x1="410.422"
        y1="231.111"
        x2="432.119"
        y2="231.111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="0.31" stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`} />
        <stop offset="0.665" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
      </linearGradient>
      <linearGradient
        id={`paint33_linear_${sponsorId}`}
        x1="575.762"
        y1="231.111"
        x2="597.458"
        y2="231.111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="0.31" stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`} />
        <stop offset="0.665" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
      </linearGradient>
      <linearGradient
        id={`paint34_linear_${sponsorId}`}
        x1="493.092"
        y1="228.124"
        x2="514.789"
        y2="228.124"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="0.31" stopColor={`hsl(${frontHue}, ${frontSaturation}, 30%)`} />
        <stop offset="0.665" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
      </linearGradient>
      <linearGradient
        id={`paint35_linear_${sponsorId}`}
        x1="503.976"
        y1="573.117"
        x2="503.976"
        y2="620.209"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </linearGradient>
      <linearGradient
        id={`paint36_linear_${sponsorId}`}
        x1="361.34"
        y1="484.551"
        x2="646.616"
        y2="484.551"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </linearGradient>
      <linearGradient
        id={`paint37_linear_${sponsorId}`}
        x1="361.34"
        y1="477.269"
        x2="646.616"
        y2="477.269"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`} />
        <stop offset="0.503" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 19%)`} />
      </linearGradient>
      <linearGradient
        id={`paint38_linear_${sponsorId}`}
        x1="378.238"
        y1="603.97"
        x2="629.718"
        y2="603.97"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 69%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </linearGradient>
      <radialGradient
        id={`paint39_radial_${sponsorId}`}
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1215.18 234.716) scale(617.522 617.422)"
      >
        <stop stopColor={`hsl(${frontHue}, ${frontSaturation}, 85%)`} />
        <stop offset="0.352" stopColor={`hsl(${frontHue}, ${frontSaturation}, 40%)`} />
        <stop offset="1" stopColor={`hsl(${frontHue}, ${frontSaturation}, 8%)`} />
      </radialGradient>
    </defs>
  </>
)

export default bannerDrawing
