import Background from 'components/RegistrationBackground'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { memo } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import styled from 'styled-components'
import { mobile, tablet } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'

const passwordPattern = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[!*@#$%^&+=]).*$/i
const emptyField = 'This field cannot be empty'

interface IFormValues {
  password: string
  passwordRepeat: string
}

export const ResetForm = memo(
  observer(() => {
    const {
      handleSubmit,
      register,
      errors,
      getValues,
      formState: { isSubmitting },
    } = useForm<IFormValues>({
      mode: 'onTouched',
    })

    const { resetPassword } = useRootData((store) => store.appStore)

    const location = useLocation()
    const token = queryString.parse(location.search).token as string

    if (!token) {
      return (
        <Background>
          <SuccessWrapper>
            <SuccessHeading>Wrong reset password token!</SuccessHeading>
          </SuccessWrapper>
        </Background>
      )
    }

    const renderMessage = (fieldName: keyof IFormValues, message?: string) => {
      const error = errors[fieldName]
      if (error) {
        return <InputMessage>{error.message || message || 'Field invalid'}</InputMessage>
      }
      return null
    }

    return (
      <Background>
        <ResetFormElement
          autoComplete="off"
          onSubmit={handleSubmit(async (values: IFormValues) => {
            await resetPassword(values.password, token)
          })}
        >
          <Title>Input new password</Title>

          <InputRow>
            <InputField
              ref={register({
                pattern: passwordPattern,
                required: emptyField,
              })}
              name="password"
              type="password"
              autoComplete="off"
              placeholder="Password"
              maxLength={256}
            />
            {renderMessage(
              'password',
              'Password must contains at least one upper case letter, number and special character'
            )}
          </InputRow>

          <InputRow>
            <InputField
              ref={register({
                required: emptyField,
                validate: (value) => value === getValues('password'),
              })}
              name="passwordRepeat"
              type="password"
              autoComplete="off"
              placeholder="Repeat password"
              maxLength={256}
            />
            {renderMessage('passwordRepeat', 'Different passwords')}
          </InputRow>

          <InputRow>
            <FormButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Submit'}
            </FormButton>
          </InputRow>
        </ResetFormElement>
      </Background>
    )
  })
)

export default observer(() => {
  const { resetPasswordState } = useRootData((store) => store.appStore)
  if (resetPasswordState === LoadingStatus.success) {
    return (
      <Background>
        <SuccessWrapper>
          <SuccessHeading>Your password updated!</SuccessHeading>
        </SuccessWrapper>
      </Background>
    )
  }
  return <ResetForm />
})

const InputMessage = styled.div`
  padding: 8px 12px 0;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  color: #eb6931;
`

const ResetFormElement = styled.form`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  max-width: 600px;
  margin: auto;
  padding: 100px 10px 80px;

  ${tablet} {
    padding: 80px 20px 0;
  }
`

const FormButton = styled.button`
  margin: 0;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  background-color: #3c62d9;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.15s;
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  margin-bottom: 20px;
  width: 100%;
  padding-left: 10px;

  ${tablet} {
    padding: 0;
  }
`

const InputRow = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px 20px;

  ${tablet} {
    width: 100%;
    padding: 0 0 20px;
  }
`

const InputField = styled.input`
  margin: 0;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border: 2px solid #3c62d9;
  font-size: 13px;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s;
  touch-action: manipulation;
  outline: none;

  ${mobile} {
    padding: 0 8px;
    font-size: 16px;
  }

  &.multiline {
    padding: 10px;
    height: 200px;
  }

  &:focus {
    border-color: #3c62d9;
  }
`

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 20px 80px;
`

const SuccessHeading = styled.h2`
  padding: 20px 0 15px;
`
