import { ContentLoader } from 'components/Loader'
import Background from 'components/RegistrationBackground'
import throttle from 'lodash/throttle'
import { observer } from 'mobx-react-lite'
import React, { memo, ReactNode, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useRootData } from 'stores'
import { IRegistrationData } from 'stores/app'
import styled from 'styled-components'
import axios from 'utils/axios'
import countries from 'utils/countryCodes'
import { mobile, tablet } from 'utils/responsive'
import { combineLoadingStatus, LoadingStatus } from 'utils/store'

const dataProcessingText = `<p>I have read and agree to the <a href="https://www.bridgerpay.com/legal/terms" rel="noopener noreferrer" target="_blank">Terms & Conditions</a>, <a href="https://www.bridgerpay.com/legal/privacy" rel="noopener noreferrer" target="_blank">Cookies and Privacy Policy</a>.</p>`
const acceptTermsText = `<p>By registering, you consent that the information you provide can be shared
  between the TradeOn Summit and its organizers, partners and participating parties (exhibitors and
  sponsors whom I engage with via booth visit, collateral download and/or IM, audio or video chat).
  Furthermore, by registering, you allow TradeON Summit to contact you in the future for any similar
  events or any of their other services and programs.</p>`

// const debugVars = {
//   firstName: 'Mikola',
//   lastName: 'Tester',
//   email: 'mikola3@ultimatefintech.com',
//   company: 'My startup',
//   password: '#4e5ZHlCybdPGarYQ13WV46a58M',
//   phoneNumber: '424242',
//   countryCode: 'UA',
//   dataProcessing: true,
// } as IFormValues

const stringPattern = /[\p{ASCII}]+/u
const phonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]{9,10}$/
const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
const passwordPattern = /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[!*@#$%^&+=]).*$/i

const emptyField = 'This field cannot be empty'

const emailsValid: { [key: string]: boolean } = {}
const checkEmail = async (email: string) => {
  if (emailsValid.hasOwnProperty(email)) {
    return emailsValid[email]
  }
  try {
    await axios.get(`/public/email/check-exist?email=${email}`)
    emailsValid[email] = true
  } catch (e) {
    emailsValid[email] = false
  }
  return emailsValid[email]
}

interface IFormValues extends IRegistrationData {
  dataProcessing: boolean
}

export const RegistrationForm = memo(
  observer(() => {
    const {
      handleSubmit,
      register,
      errors,
      trigger,
      setValue,
      formState: { isSubmitting },
    } = useForm<IFormValues>({
      // defaultValues: debugVars,
      mode: 'onTouched',
    })
    const {
      fetchDefaultCountry,
      countryInfo,
      register: sendRegistration,
      countryInfoStatus,
    } = useRootData((store) => store.appStore)
    // const { fetchCategories, categories, categoriesStatus } = useRootData(
    //   (store) => store.settingsStore
    // )
    // const loadingStatus = combineLoadingStatus([countryInfoStatus, categoriesStatus])
    const loadingStatus = countryInfoStatus

    const throttledValidateEmail = throttle(async (email: string) => {
      const valid = await checkEmail(email)
      trigger('email')
      return valid
    }, 500)

    useEffect(() => {
      fetchDefaultCountry()
      // eslint-disable-next-line
    }, [])

    // useEffect(() => {
    //   fetchCategories()
    //   // eslint-disable-next-line
    // }, [])

    useEffect(() => {
      // to set value after field registration (loadingStatus issue)
      setTimeout(() => {
        setValue('country', countryInfo.countryCode)
      })
      // eslint-disable-next-line
    }, [countryInfo])

    const renderMessage = (fieldName: keyof IFormValues, message?: string) => {
      const error = errors[fieldName]
      if (error) {
        return <InputMessage>{error.message || message || 'Field invalid'}</InputMessage>
      }
      return null
    }

    return (
      <ContentLoader loadingStatus={loadingStatus}>
        {() => (
          <Background>
            <RegisterFormElement
              autoComplete="off"
              onSubmit={handleSubmit(async (values: IFormValues) => {
                values.email = values.email.trim()
                values.firstName = values.firstName.trim()
                values.lastName = values.lastName.trim()
                values.password = values.password.trim()
                values.phoneNumber = values.phoneNumber.trim()
                values.company = values.company.trim()
                await sendRegistration({
                  ...values,
                  metadataObject: {
                    confirmationEmailApproval: acceptTermsText,
                    dataProcessingText: values.dataProcessing ? dataProcessingText : undefined,
                  },
                })
              })}
            >
              {/* <Title>Registration form</Title> */}
              <InputRow>
                <InputField
                  ref={register({
                    pattern: stringPattern,
                    required: emptyField,
                  })}
                  name="firstName"
                  autoComplete="off"
                  maxLength={256}
                  placeholder="First name"
                />
                {errors.firstName && renderMessage('firstName', 'Only latin letters allowed')}
              </InputRow>

              <InputRow>
                <InputField
                  ref={register({
                    pattern: stringPattern,
                    required: emptyField,
                  })}
                  name="lastName"
                  autoComplete="off"
                  maxLength={256}
                  placeholder="Last name"
                />
                {errors.lastName && renderMessage('lastName', 'Only latin letters allowed')}
              </InputRow>

              <InputRow>
                <InputField
                  ref={register({
                    pattern: emailPattern,
                    required: emptyField,
                    validate: throttledValidateEmail,
                  })}
                  name="email"
                  type="email"
                  maxLength={256}
                  autoComplete="off"
                  placeholder="Email"
                />
                {errors.email &&
                  errors.email.type !== 'validate' &&
                  renderMessage('email', 'Invalid email address')}
                {errors.email &&
                  errors.email.type === 'validate' &&
                  renderMessage('email', 'Attendee with this email already registred')}
              </InputRow>

              <InputRow>
                <InputField
                  ref={register({
                    pattern: phonePattern,
                    required: emptyField,
                  })}
                  name="phoneNumber"
                  type="tel"
                  autoComplete="off"
                  placeholder="Phone number with type code"
                />
                {errors.phoneNumber &&
                  renderMessage('phoneNumber', 'Invalid phone number with type code')}
              </InputRow>

              <InputRow size="100%">
                <InputField
                  ref={register({
                    pattern: stringPattern,
                    required: emptyField,
                  })}
                  name="company"
                  maxLength={256}
                  autoComplete="off"
                  placeholder="Company"
                />
                {renderMessage('company', 'Only latin letters allowed')}
              </InputRow>

              {/* <InputRow>
                <SelectField
                  name="category"
                  ref={register({ required: emptyField })}
                  placeholder="Category"
                >
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </SelectField>
                {renderMessage('category' as any)}
              </InputRow> */}

              <InputRow>
                <SelectField
                  name="country"
                  ref={register({ required: emptyField })}
                  placeholder="Country"
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </SelectField>
                {renderMessage('country')}
              </InputRow>

              <InputRow>
                <InputField
                  ref={register({
                    pattern: passwordPattern,
                    required: emptyField,
                  })}
                  name="password"
                  type="password"
                  autoComplete="off"
                  placeholder="Password"
                  maxLength={256}
                />
                {renderMessage(
                  'password',
                  'Password must contains at least one upper case letter, number and special character'
                )}
              </InputRow>

              <CheckBoxes>
                <CheckBoxRow>
                  <input
                    ref={register({ required: 'This field is required' })}
                    name="dataProcessing"
                    id="dataProcessingCheckbox"
                    type="checkbox"
                  />
                  <label
                    htmlFor="dataProcessingCheckbox"
                    dangerouslySetInnerHTML={{ __html: dataProcessingText }}
                  />
                </CheckBoxRow>
                {renderMessage('dataProcessing')}
              </CheckBoxes>

              <InputRow size="100%">
                <FormButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Sending...' : 'Submit'}
                </FormButton>
              </InputRow>
            </RegisterFormElement>
          </Background>
        )}
      </ContentLoader>
    )
  })
)

export default observer(() => {
  const { registrationState } = useRootData((store) => store.appStore)
  if (registrationState === LoadingStatus.success) {
    return (
      <Background>
        <SuccessWrapper>
          <SuccessHeading>THANK YOU FOR YOUR REGISTRATION!</SuccessHeading>
          <SuccessText>
            Please check your spam/junk folders for the email confirmation.
            <br />
            Now you can close this tab or refresh the page to make a new registration.
          </SuccessText>
        </SuccessWrapper>
      </Background>
    )
  }
  return <RegistrationForm />
})

const InputMessage = styled.div`
  padding: 8px 12px 0;
  width: 100%;
  font-size: 11px;
  font-weight: bold;
  color: #eb6931;
`

const RegisterFormElement = styled.form`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: auto;
  padding: 100px 10px 80px;

  ${tablet} {
    padding: 70px 20px 0;
  }
`

const FormButton = styled.button`
  margin: 0;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  background-color: #3c62d9;
  border: none;
  text-transform: uppercase;
  transition: background-color 0.15s;
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1`
  margin-bottom: 20px;
  width: 100%;
  padding-left: 10px;

  ${tablet} {
    padding: 0;
  }
`

const InputRow = styled.div<{ size?: string }>`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: ${({ size }) => (size ? 'row' : 'column')};
  flex-wrap: ${({ size }) => (size ? 'wrap' : 'nowrap')};
  justify-content: flex-start;
  width: ${({ size }) => (size ? size : '50%')};
  padding: 0 10px 20px;

  ${tablet} {
    width: 100%;
    padding: 0 0 20px;
  }
`

const InputField = styled.input<{ size?: boolean }>`
  margin: 0;
  padding: 0 20px;
  width: ${({ size }) => (size ? size : '100%')};
  height: 50px;
  background-color: #fff;
  border: 2px solid #3c62d9;
  font-size: 13px;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s;
  touch-action: manipulation;
  outline: none;

  ${mobile} {
    padding: 0 8px;
    font-size: 16px;
  }

  &.multiline {
    padding: 10px;
    height: 200px;
  }

  &:focus {
    border-color: #3c62d9;
  }
`

const SelectField = styled.select`
  margin: 0;
  padding: 0 20px;
  width: ${({ size }) => (size ? size : '100%')};
  height: 50px;
  background-color: #fff;
  border: 2px solid #3c62d9;
  font-size: 13px;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s;
  touch-action: manipulation;
  outline: none;

  ${mobile} {
    padding: 0 8px;
    font-size: 16px;
  }

  &:focus {
    border-color: #3c62d9;
  }
`

const CheckBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  user-select: none;
  position: relative;
  padding: 0 15px 20px 5px;
`

const CheckBoxRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  label {
    margin-left: 10px;
    cursor: pointer;
  }

  input {
    cursor: pointer;
  }
`

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 20px 80px;
`

const SuccessHeading = styled.h2`
  padding: 20px 0 15px;
`

const SuccessText = styled.p`
  margin: 0;
  padding: 0 0 40px;
  line-height: 1.5em;
  text-align: center;
`
