function padZeros(num: number): string {
  return num < 10 ? '0' + num : '' + num
}

function dateStr(date: Date): string {
  const now: Date = new Date()
  let str: string = padZeros(date.getHours()) + ':' + padZeros(date.getMinutes())
  if (
    date.getFullYear() !== now.getFullYear() ||
    date.getMonth() !== now.getMonth() ||
    date.getDay() !== now.getDay()
  ) {
    str = `${padZeros(date.getMonth())}.${padZeros(date.getDay())} ${str}`
  }
  return str
}

export { padZeros, dateStr }
