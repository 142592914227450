import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ICategoryWithSponsors, IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'

const colors = [
  '#0b3c5d',
  '#328cc1',
  '#d9b310',
  '#1d2731',
  '#286da8',
  '#cd5360',
  '#b37d4e',
  '#438496',
  '#f7882f',
  '#015249',
  '#57bc90',
  '#eeaa7b',
]

const getLogo = (sponsor: IFullSponsor) => sponsor.logoWhiteUrl || sponsor.logoUrl
const minWidth = 300

const Categories = ({ categories }: { categories: ICategoryWithSponsors[] }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [containerWidth, setWidth] = useState(-1)
  const [colsCount, setColsCount] = useState(-1)

  const baseWidth = (containerWidth - 20) / colsCount - 20
  const doubleWidth = Math.min(baseWidth * 2 + 20, containerWidth - 40)
  const baseHeight = 150
  const doubleHeight = baseHeight * 2 + 20

  useEffect(() => {
    const onResize = () => {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect()
        const cols = Math.max(1, Math.floor((width - 20) / minWidth))
        setColsCount(cols)
        setWidth(width)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper ref={ref} columns={colsCount}>
      {colsCount > 0 &&
        categories.map(({ id, displayName, sponsors }, i) => (
          <CategoryBox
            height={i < 3 ? doubleHeight : baseHeight}
            width={i < 1 ? doubleWidth : baseWidth}
            key={id}
            color={colors[i % colors.length]}
          >
            <CategoryTitle to={`/category/${id}/`}>
              <Count>{sponsors.length}</Count>
              <b>{displayName}</b>
            </CategoryTitle>

            <Logos>
              {sponsors.slice(0, i < 1 ? 16 : i < 3 ? 8 : 4).map((sponsor) => (
                <Logo to={`/sponsor/${sponsor.id}/`} key={sponsor.id} img={getLogo(sponsor)} />
              ))}
            </Logos>
          </CategoryBox>
        ))}
    </Wrapper>
  )
}

export default Categories

const CategoryBox = styled.div<{ color: string; width: number; height: number }>`
  background: ${({ color }) => color};
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  max-width: 100%;
  margin: 10px;
  position: relative;
  display: block;
  float: left;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: ${({ color }) => color};
  }

  &:after {
    height: 100%;
    background: transparent;
    pointer-events: none;
  }

  /* &:hover {
    &:after {
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
    }
  } */
`

const Wrapper = styled.div<{ columns: number }>`
  padding: 10px;
  font-size: 0;
  width: 100%;

  &::after {
    content: '';
    clear: both;
    display: block;
  }
`

const Logos = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`

const Logo = styled(Link)<{ img: string }>`
  cursor: pointer;
  flex-shrink: 0;
  width: 175px;
  height: 40px;
  margin: 20px 5px;
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.1s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`

const CategoryTitle = styled(Link)`
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 22px;
  height: 30px;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;

  &:hover b {
    text-shadow: 0 0 2px white;
  }
`

const Count = styled.span`
  background: rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -10px;
  margin-left: -10px;
`
