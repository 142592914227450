import Footer from 'components/Footer'
import Hero from 'components/Hero'
import QuestionCard from 'components/QuestionCard'
import fuzzy from 'fuzzysort'
import React, { useMemo, useState } from 'react'
import { ChevronDown, Search as SearchIcon } from 'react-feather'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import { tablet } from 'utils/responsive'

export default function FAQ() {
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('')
  const { questions, categories } = useRootData((store) => store.faqStore)
  const { width } = useWindowDimensions()

  const filteredQuestions = useMemo(
    () => (category ? questions.filter((a) => a.categories.indexOf(category) > -1) : questions),
    [category, questions]
  )

  const isMobile = width < 1024

  return (
    <Footer>
      <Hero title="FAQ">
        <InputWrapper>
          <SearchInput
            type="text"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Input your question"
          />
          <SearchIcon size="18" />
        </InputWrapper>
      </Hero>

      <Container>
        <CategoriesWrapper>
          <CategoryButton active={!category} onClick={() => setCategory('')}>
            All
          </CategoryButton>

          {categories().map((cat) => (
            <CategoryButton active={cat === category} key={cat} onClick={() => setCategory(cat)}>
              {cat}
            </CategoryButton>
          ))}
        </CategoriesWrapper>

        {isMobile && (
          <>
            <CategoriesSelectWrapper>
              <CategoriesSelect onChange={(event) => setCategory(event.target.value)}>
                {categories().map((cat) => (
                  <option defaultValue={category} key={cat}>
                    {cat}
                  </option>
                ))}
              </CategoriesSelect>
              <ChevronDown size="18" />
            </CategoriesSelectWrapper>
          </>
        )}

        <Answers>
          {(search
            ? fuzzy.go(search, filteredQuestions, { key: 'question' }).map((e) => e.obj)
            : filteredQuestions
          ).map((item, i) => (
            <QuestionCard item={item} key={i} />
          ))}
        </Answers>
      </Container>
    </Footer>
  )
}

const InputWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  width: 100%;
  max-width: 600px;

  ${tablet} {
    margin-top: 20px;
  }

  svg {
    position: absolute;
    right: 12px;
    top: 12px;
    color: #a4a4a4;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.35);
  }
`

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  padding: 20px 10px;

  ${tablet} {
    padding: 20px;
    flex-direction: column;
  }
`

const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 0 20px 0 0;
  flex-shrink: 0;

  ${tablet} {
    display: none;
  }
`

const CategoriesSelectWrapper = styled.div`
  display: none;

  ${tablet} {
    position: relative;
    display: block;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    svg {
      position: absolute;
      right: 12px;
      top: 12px;
      color: #a4a4a4;
    }
  }
`

const CategoriesSelect = styled.select`
  display: none;

  ${tablet} {
    display: block;
    padding: 0 15px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    appearance: none;
    background: none;
    border: none;
  }
`

const CategoryButton = styled.div<{ active?: boolean }>`
  padding: 10px 15px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ active }) => (active ? '#fff' : '#333')};
  background-color: ${({ active }) => (active ? '#456dde' : '#fff')};
  transition: color 0.15s, background-color 0.15s;

  &:hover {
    color: #fff;
    background-color: ${({ active }) => (active ? '#456dde' : '#718de3')};
  }
`

const Answers = styled.div`
  flex-grow: 1;
`
