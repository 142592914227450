import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import { CHAT_REALM } from 'utils/config'
import useWindowDimensions from 'utils/dimensions'
import { LoadingStatus } from '../../utils/store'
import ChatWindow from './ChatWindow'

export default observer(() => {
  const { currentUser } = useRootData((store) => store.appStore)
  const {
    initConnection,
    connection,
    fetchStreams,
    fetchMessages,
    loadStreamsStatus,
    fetchPrivateRooms,
    loadPrivateRoomsStatus,
    setCurrentStream,
    setChatWindowVisibility,
    streams,
    currentPrivateRoomId,
    loadMessagesStatus,
    currentStreamId,
  } = useRootData((store) => store.chatStore)
  const location = useLocation()
  const path = location.pathname
  const isChatPage = !!path.match(/^\/chats/)
  const { width: viewportWidth } = useWindowDimensions()
  const isMobile = viewportWidth < 1000

  useEffect(() => {
    if (!connection) {
      initConnection({
        apiKey: currentUser.chatApiKey,
        realm: CHAT_REALM,
        username: currentUser.email,
      })
    }
    // eslint-disable-next-line
  }, [connection, currentUser.chatApiKey, currentUser.email])

  useEffect(() => {
    if (connection && loadStreamsStatus === LoadingStatus.not_loaded) {
      fetchStreams()
    }
    // eslint-disable-next-line
  }, [loadStreamsStatus, connection])

  useEffect(() => {
    if (connection && loadPrivateRoomsStatus === LoadingStatus.not_loaded) {
      fetchPrivateRooms()
    }
    // eslint-disable-next-line
  }, [loadPrivateRoomsStatus, connection])

  useEffect(() => {
    if (connection && loadMessagesStatus === LoadingStatus.not_loaded) {
      fetchMessages()
    }
    // eslint-disable-next-line
  }, [loadMessagesStatus, connection])

  useEffect(() => {
    if (currentStreamId < 0 && currentPrivateRoomId < 0 && streams.length > 0) {
      setCurrentStream((streams.find((s) => s.name === 'general') || streams[0]).stream_id)
    }
    // eslint-disable-next-line
  }, [currentPrivateRoomId, currentStreamId, streams])

  useEffect(() => {
    if (isMobile) {
      setChatWindowVisibility(false)
    }
  }, [path, isMobile])

  if (!connection) {
    return null
  }

  return <ChatWindow fullPage={isChatPage} />
})
