import {
  IAbstractionsServicesCountryInfo,
  IAbstractionsServicesLoginModelsLoginResponseModel,
  IApiControllersAttendeePersonalModelsAttendeeBaseDetailsResponseModel,
  IApiControllersPublicLoginModelsAccessTokenModel,
  IApiControllersPublicSectionsRegistrationModelsSubmitVirtualAttendeeModel,
} from 'api-types'
import { AxiosResponse } from 'axios'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import once from 'lodash/once'
import { flow, types } from 'mobx-state-tree'
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from 'utils/auth'
import axios from 'utils/axios'
import { SAML_URL } from 'utils/config'
import { createAxiosAction, IUserMetadata, LoadingStatusType } from 'utils/store'

export interface IUserDetails
  extends IApiControllersAttendeePersonalModelsAttendeeBaseDetailsResponseModel {
  metadataObject: IUserMetadata
}

export type IRegistrationData = IApiControllersPublicSectionsRegistrationModelsSubmitVirtualAttendeeModel

export type ICountryInfo = IAbstractionsServicesCountryInfo

// fix debounce (meybe debounce cant handle dynamic function)
const lazySaveMetadata = (metadata: { [key: string]: any }) =>
  new Promise((resolve, reject) =>
    debounce(async () => {
      try {
        await axios.put('/attendee/personal/update-metadata', metadata)
        resolve()
      } catch (e) {
        reject(e)
      }
    }, 1000)()
  )

export default types
  .model('AppStore', {
    accessToken: types.optional(types.string, getAccessToken() || ''),
    refreshToken: types.optional(types.string, getRefreshToken() || ''),

    errorMessage: types.optional(types.string, ''),
    infoMessage: types.optional(types.string, ''),
    visibleError: types.optional(types.boolean, false),
    visibleMessage: types.optional(types.boolean, false),

    currentUser: types.optional(types.frozen<IUserDetails>(), {} as IUserDetails),
    loadCurrentUserStatus: LoadingStatusType,

    countryInfo: types.optional(types.frozen<ICountryInfo>(), {} as ICountryInfo),
    countryInfoStatus: LoadingStatusType,

    activeRoute: types.optional(types.string, '/'),
    loginError: types.optional(types.string, ''),
    loginState: LoadingStatusType,
    registrationState: LoadingStatusType,
    resetPasswordState: LoadingStatusType,
    samlState: LoadingStatusType,
  })

  .views((self) => ({
    readedNotifications: () =>
      (self.currentUser.metadataObject.readedNotifications || []) as string[],
  }))

  .actions((self) => ({
    setErrorMessage: (message: string) => (self.errorMessage = message),
    setErrorVisibility: (visibility: boolean) => (self.visibleError = visibility),
    setInfoMessage: (message: string) => (self.infoMessage = message),
    setInfoMessageVisibility: (visibility: boolean) => (self.visibleMessage = visibility),
  }))

  .actions((self) => ({
    hideError: () => {
      self.visibleError = false
      setTimeout(() => self.setErrorMessage(''), 200)
    },
    hideMessage: () => {
      self.visibleMessage = false
      setTimeout(() => self.setInfoMessage(''), 200)
    },
  }))

  .actions((self) => ({
    setActiveRoute(route: string) {
      self.activeRoute = route
    },

    showError(errorMessage: string, delay = 2000) {
      self.errorMessage = errorMessage
      setTimeout(() => self.setErrorVisibility(true), 0)
      setTimeout(self.hideError, delay)
    },

    showMessage(message: string, delay = 2000) {
      self.infoMessage = message
      setTimeout(() => self.setInfoMessageVisibility(true), 0)
      setTimeout(self.hideMessage, delay)
    },

    updateTokens() {
      self.accessToken = getAccessToken() || ''
      self.refreshToken = getRefreshToken() || ''
    },
  }))

  .actions((self) => ({
    logout() {
      // race condition here
      removeAccessToken()
      removeRefreshToken()
      self.updateTokens()
      const url = new URL(SAML_URL)
      const clientId = url.pathname.split('/').pop()
      window.location.href = `https://${url.hostname}/v2/logout?client_id=${clientId}`
    },
  }))

  .actions((self) => ({
    getNewToken: createAxiosAction(
      flow(function*() {
        const w = window as any
        if (w.currentRefreshTokenRequest) {
          return
        }

        try {
          const refreshFn = async () => {
            const {
              data: { accessToken },
            } = (await axios.post('/public/refresh-token', {
              refreshToken: self.refreshToken,
            })) as AxiosResponse<IApiControllersPublicLoginModelsAccessTokenModel>

            setAccessToken(accessToken)
          }

          self.updateTokens()
          const promise = refreshFn()
          w.currentRefreshTokenRequest = promise
          yield promise
          self.updateTokens()
        } catch (e) {
          console.error('Error on refresh token', e)
        } finally {
          w.currentRefreshTokenRequest = null
        }
      }),
      (s) => (self.loginState = s),
      () => {
        self.showError('Session expired')
        self.logout()
      }
    ),

    login: createAxiosAction(
      flow(function*(username: string, password: string, responseKey: string) {
        const {
          data: { accessToken, refreshToken },
        } = (yield axios.post(
          '/public/login',
          {
            password,
            userNameOrEmail: username,
          },
          { headers: { GoogleCaptchaResponseKey: responseKey } }
        )) as AxiosResponse<IAbstractionsServicesLoginModelsLoginResponseModel>

        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        self.updateTokens()
      }),
      (s) => (self.loginState = s),
      () => self.showError('Failed to login as this user')
    ),

    register: createAxiosAction(
      flow(function*(data: IRegistrationData) {
        yield axios.post('/public/registration/virtual-attendee/submit', data)
      }),
      (s) => (self.registrationState = s),
      (error) =>
        self.showError(
          get(
            error,
            'response.data.ErrorMessage',
            get(error, 'response.data.Message', 'Failed to register new user')
          )
        )
    ),

    fetchDefaultCountry: once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get('/meta/ip/country-info')) as AxiosResponse<ICountryInfo>
          self.countryInfo = data
        }),
        (s) => (self.countryInfoStatus = s),
        () => self.showError('Failed to fetch country info')
      )
    ),

    resetPassword: createAxiosAction(
      flow(function*(password: string, token: string) {
        yield axios.post('/public/forgot-password/virtual', {
          newPassword: password,
          token,
        })
      }),
      (s) => (self.resetPasswordState = s),
      () => self.showError('Failed to reset password')
    ),

    completeLoginSaml: createAxiosAction(
      flow(function*(token: string) {
        const {
          data: { accessToken, refreshToken },
        } = (yield axios.post('/login/saml/auth', { token })) as AxiosResponse<
          IAbstractionsServicesLoginModelsLoginResponseModel
        >

        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        self.updateTokens()
      }),
      (s) => (self.samlState = s),
      () => {
        self.showError('Failed to login with Saml')
      }
    ),

    fetchUserDetails: once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get('/attendee/personal/details')) as AxiosResponse<
            IUserDetails
          >
          self.currentUser = data
        }),
        (s) => (self.loadCurrentUserStatus = s),
        () => self.showError('Failed to load user details')
      )
    ),

    updateUserMetadata: createAxiosAction(
      flow(function*(metadata: IUserMetadata) {
        self.currentUser = { ...self.currentUser, metadataObject: metadata }
        yield lazySaveMetadata(metadata)
      }),
      () => void 0,
      () => self.showError('Failed to update user metadata')
    ),
  }))

  .actions((self) => ({
    updatePartialUserMetadata: (metadata: Partial<IUserMetadata>) =>
      self.updateUserMetadata({ ...self.currentUser.metadataObject, ...metadata }),
  }))
