import React from 'react'
import { Link } from 'react-router-dom'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'

interface IExhibitorsIndexProps {
  sponsors: IFullSponsor[]
}

export default function ExhibitorsIndex({ sponsors }: IExhibitorsIndexProps) {
  return (
    <div>
      {sponsors.map((sponsor) => (
        <SponsorCard key={sponsor.id} sponsor={sponsor} />
      ))}
    </div>
  )
}

const SponsorCard = ({ sponsor }: { sponsor: IFullSponsor }) => (
  <SponsorItem>
    <SponsorTitle to={`/sponsor/${sponsor.id}/`}>{sponsor.company}</SponsorTitle>
    <Details>
      <b>Category: </b>
      {sponsor.sponsorTags.map((category, i) => (
        <span key={category.id}>
          {category.displayName}
          {i < sponsor.sponsorTags.length - 1 ? ',' : ''}
        </span>
      ))}
      <br />
    </Details>
  </SponsorItem>
)

const SponsorItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const Details = styled.div`
  padding: 10px;
  line-height: 20px;

  span {
    margin-left: 5px;
  }
`

const SponsorTitle = styled(Link)`
  display: block;
  padding: 10px;
  width: 100%;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  font-size: 16px;
  border-bottom: 1px solid #ccc;

  &:hover {
    color: #456dde;
  }
`
