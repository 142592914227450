import Footer from 'components/Footer'
import SearchResults from 'components/SearchResults'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import React, { useEffect, useMemo, useState } from 'react'
import { CheckSquare, Search as SearchIcon, Square } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'
import useSwagBag from '../components/useSwagBag'

const sortedUniq = (arr: string[]) => uniq(arr).sort()
const allSections = sortedUniq(['categories', 'companies', 'documents', 'videos', 'questions'])
const upFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const Search = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const { addItem: addSwagItem } = useRootData((store) => store.swagbagStore)

  const [searchFromLocation, sectionsFromLocation] = useMemo(() => {
    const query = queryString.parse(location.search)
    let s = query.c || []
    if (!Array.isArray(s)) {
      s = [s]
    }
    return [(query.v || '') as string, sortedUniq(s.length > 0 ? s : allSections)]
  }, [location])

  const [searchText, setSearchText] = useState(searchFromLocation)
  const [sections, setShowSections] = useState(sectionsFromLocation)

  const isVisibleSection = (name: string) => sections.indexOf(name) > -1

  const toggleSection = (name: string) => {
    if (!isVisibleSection(name)) {
      setShowSections(sortedUniq([...sections, name]))
    } else {
      setShowSections(sections.filter((s) => s !== name))
    }
  }

  const {
    fullSponsors,
    documents,
    fullSponsorsCategories,
    videos,
    fetchFullSponsors,
  } = useRootData((store) => store.sponsorsStore)

  const { questions } = useRootData((store) => store.faqStore)

  useEffect(() => {
    fetchFullSponsors()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const qs = queryString.stringify({
      c: isEqual(allSections, sections) ? undefined : sections,
      v: searchText ? searchText : undefined,
    })
    if (qs) {
      history.replace(`/search/?${qs}`)
    } else {
      history.replace('/search/')
    }
    // eslint-disable-next-line
  }, [searchText, sections])

  const swagbag = useSwagBag()
  const swagItems = swagbag.items

  return (
    <Footer>
      <Wrapper>
        <InputWrapper>
          <SearchInput
            autoFocus
            type="text"
            value={searchText}
            placeholder="Search"
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
          <SearchIcon size="18" />
        </InputWrapper>

        <SearchCategories>
          <b>Search in:</b>
          {allSections.map((section) => (
            <CategoryCheckbox key={section} onClick={() => toggleSection(section)}>
              {isVisibleSection(section) ? <CheckSquare /> : <Square />} {upFirst(section)}
            </CategoryCheckbox>
          ))}
        </SearchCategories>

        <SearchResults
          addSwagItem={addSwagItem}
          searchText={searchText}
          sponsorsList={isVisibleSection('companies') ? fullSponsors() : []}
          documents={isVisibleSection('documents') ? documents() : []}
          videos={isVisibleSection('videos') ? videos() : []}
          categories={isVisibleSection('categories') ? fullSponsorsCategories() : []}
          questions={isVisibleSection('questions') ? questions : []}
          swagBagContains={swagbag.contains}
          swagItems={swagItems}
        />
      </Wrapper>
    </Footer>
  )
})

export default Search

const SearchCategories = styled.div`
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  b {
    margin: 0 10px 5px 0;
    font-weight: 600;
  }
`

const CategoryCheckbox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  margin-right: 20px;

  svg {
    color: #456dde;
    margin-right: 10px;
  }

  &:hover {
    color: #456dde;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  padding: 20px;
  margin: 0 auto;
  text-align: left;

  ${tablet} {
    padding: 15px;
    text-align: center;
  }
`

const InputWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: 12px;
    top: 12px;
    color: #a4a4a4;
  }
`

const SearchInput = styled.input`
  width: 100%;
  min-width: 200px;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  outline: none;

  &:hover,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.35);
  }
`
