import Hero from 'components/Hero'
import SessionCard from 'components/SessionCard'
import SessionPopup from 'components/SessionPopup'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ILocationItem, ISessionItem } from 'stores/agenda'
import styled from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import { tablet } from 'utils/responsive'

interface IAgendaProps {
  sessions: ISessionItem[]
}

export default function Agenda({ sessions }: IAgendaProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [currentLocation, setCurrentLocation] = useState('')
  const [currentSession, setCurrentSession] = useState('')
  const [top, setTop] = useState(200)
  const visibleSession = sessions.find((s) => s.id === currentSession)
  const { width } = useWindowDimensions()

  const isMobile = width < 1024

  const [byLocation, locations] = useMemo(() => {
    const locs: { [key: string]: ILocationItem } = {}
    const columns: { [key: string]: ISessionItem[] } = {}
    sessions.forEach((session) => {
      if (!session.location) {
        return
      }
      locs[session.location.id] = session.location
      columns[session.location.id] = columns[session.location.id] || []
      columns[session.location.id].push(session)
    })
    return [columns, Object.values(locs).slice(2, 5)]
  }, [sessions])

  useEffect(() => {
    setCurrentLocation(locations[0].id)
  }, [locations])

  const onSessionClick = useCallback((id, topPx) => {
    setCurrentSession(id)
    if (ref.current) {
      const box = ref.current.getBoundingClientRect()
      setTop(topPx - box.top)
    }
  }, [])

  const onClose = useCallback(() => setCurrentSession(''), [])

  if (isMobile) {
    return (
      <Container ref={ref}>
        <Hero title="Agenda" />
        <ColumnsWrapper>
          <Locations>
            {locations.map((location, index) => (
              <LocationTitle
                key={index}
                color={location.backgroundColor}
                onClick={() => setCurrentLocation(location.id)}
                active={currentLocation === location.id}
              >
                {location.name}
              </LocationTitle>
            ))}
          </Locations>
          <Column>
            {byLocation[currentLocation] &&
              byLocation[currentLocation].map((session) => (
                <SessionCard
                  active={currentSession === session.id}
                  key={session.id}
                  session={session}
                  onClick={onSessionClick}
                />
              ))}
          </Column>
        </ColumnsWrapper>
        {visibleSession && <SessionPopup top={top} session={visibleSession} onClose={onClose} />}
      </Container>
    )
  }

  return (
    <Container ref={ref}>
      <Hero title="Agenda" />
      <ColumnsWrapper>
        {locations.map((location) => {
          const col = byLocation[location.id] || []
          return (
            <Column key={location.id}>
              <LocationTitle color={location.backgroundColor}>{location.name}</LocationTitle>
              {col.map((session) => (
                <SessionCard
                  active={currentSession === session.id}
                  key={session.id}
                  session={session}
                  onClick={onSessionClick}
                />
              ))}
            </Column>
          )
        })}
      </ColumnsWrapper>
      {visibleSession && <SessionPopup top={top} session={visibleSession} onClose={onClose} />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Locations = styled.div`
  display: flex;
  width: 100%;
`

const LocationTitle = styled.h3<{ color: string; active?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 22px;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 20px;
  background-color: ${({ color }) => color};
  transition: box-shadow 0.15s;

  ${({ active, color }) =>
    active &&
    `
    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      bottom: -14px;
      left: 50%;
      width: 15px;
      height: 15px;
      background-color: ${color};
      transform: translate(-50%, -50%) rotate(45deg);
      box-shadow: 0 0 0 10px rgba(255, 255, 255, .5) inset;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 0 5px rgba(255, 255, 255, .5) inset;
    }
  `}

  ${tablet} {
    font-size: 14px;
    line-height: 18px;
  }
`

const ColumnsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 1300px;
  padding: 20px 10px;
`

const Column = styled.li`
  flex-shrink: 0;
  flex-direction: column;
  width: ${100 / 3}%;
  padding: 0 10px;

  ${tablet} {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
`
