import React, { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { LoadingStatus } from 'utils/store'

const ringAnimation = keyframes`
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  90% {
    transform: scale(0.9, 0.9);
    opacity: 0.3;
  }

  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
`

export const Loader = styled.div<{ color?: string; inline?: boolean }>`
  width: 40px;
  height: 40px;
  opacity: 0;

  ${({ inline }) =>
    inline
      ? `
        opacity: 1;
        position: relative;
      `
      : `
        position: absolute;
        top: 10px;
        right: 10px;
      `}

  &.visible {
    opacity: 1;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${ringAnimation} 1s ease-out 0s infinite;
    background: ${({ color = '#fff' }) => color};
  }

  &::before {
    animation-delay: 0.5s;
  }
`

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  span {
    margin-left: 20px;
    color: #333;
  }
`

export const InlineLoader = () => (
  <LoaderWrapper>
    <Loader inline color="#00f" />
    <span>Loading...</span>
  </LoaderWrapper>
)

interface IContentLoaderProps {
  loadingStatus: LoadingStatus
  error?: string
  children: () => ReactNode
}

export const ContentLoader = ({
  children,
  loadingStatus,
  error = 'Loading error!',
}: IContentLoaderProps) => {
  if (loadingStatus === LoadingStatus.success) {
    return <>{children()}</>
  }

  if (loadingStatus === LoadingStatus.error) {
    return <Error>{error}</Error>
  }

  return <InlineLoader />
}

const Error = styled.h1`
  margin-top: 20px;
  text-align: center;
`
