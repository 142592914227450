import { AxiosError } from 'axios'
import { IMSTMap, IOptionalIType, ISimpleType, types } from 'mobx-state-tree'
import { flow } from 'mobx-state-tree'
import { IAnyStateTreeNode } from 'mobx-state-tree'
import { IUserDetails } from 'stores/app'
import { INotificationItem } from '../stores/notifications'
import { IRepresentative, ISponsor } from '../stores/sponsors'

export enum LoadingStatus {
  error = 'error',
  pending = 'pending',
  not_loaded = 'not_loaded',
  success = 'success',
}

export interface ISponsorSection {
  id: string
  order: number
  sponsors: [{ id: string; order: number }]
}

export interface ISettings {
  sponsorsSetting: {
    displaySettingsObject: {
      sections: ISponsorSection[]
    }
  }

  [key: string]: any
}

export interface IUserMetadata {
  readedNotifications: string[]
  [key: string]: any
}

// we need this interface for type compatibility inside flow actions
export interface IRootStore extends IAnyStateTreeNode {
  showError: (message: string, delay?: number) => any
  showMessage: (message: string, delay?: number) => any
  settingsStore: {
    settings: ISettings
    sponsorsSections: () => ISponsorSection[]
  }
  appStore: {
    currentUser: IUserDetails
    readedNotifications: () => string[]

    updateUserMetadata: (metadata: IUserMetadata) => any
    updatePartialUserMetadata: (metadata: Partial<IUserMetadata>) => any
  }
  notificationsStore: {
    appendChatItem: (notification: INotificationItem) => any
    removeChatItem: (id: string) => any
  }
  sponsorsStore: {
    sponsorsList: ISponsor[]
    sponsorsByChatStreamName: IMSTMap<IOptionalIType<ISimpleType<ISponsor>, [undefined]>>
    sponsorsByChatUserId: IMSTMap<IOptionalIType<ISimpleType<ISponsor>, [undefined]>>
    representativesByChatUserId: IMSTMap<IOptionalIType<ISimpleType<IRepresentative>, [undefined]>>

    getSponsorByRepresentativeChatId: (id: number) => ISponsor | undefined
    getSponsorByChatName: (name: string) => ISponsor | undefined
    visitedSponsors: () => string[]
    assignedRepresentative: (id: string) => IRepresentative | undefined
  }
  chatStore: {
    notifications: INotificationItem[]
  }
}

export const createLoadingStatusType = (defaultStatus: LoadingStatus) => {
  return types.optional(
    types.enumeration('LoadingStatus', Object.values(LoadingStatus)),
    defaultStatus
  )
}

export const LoadingStatusType = createLoadingStatusType(LoadingStatus.not_loaded)

export const combineLoadingStatus = (statuses: LoadingStatus[]): LoadingStatus => {
  // tslint:disable-next-line: forin
  for (const status in LoadingStatus) {
    const found = statuses.find((s) => s === status)
    if (found) {
      // noinspection JSUnfilteredForInLoop
      return status as LoadingStatus
    }
  }
  return LoadingStatus.not_loaded
}

export const createAxiosAction = <T extends any[]>(
  fn: (...args: T) => Promise<any>,
  onStatusChange: (status: LoadingStatus, args: T) => any,
  onError?: (err: AxiosError, args: T) => any
): ((...args: T) => Promise<any>) => {
  return flow(function*(...args: T) {
    onStatusChange(LoadingStatus.pending, args)
    try {
      yield fn(...(args as T))
      onStatusChange(LoadingStatus.success, args)
    } catch (error) {
      console.error(error)
      onStatusChange(LoadingStatus.error, args)
      if (onError) {
        onError(error, args)
      }
    }
  })
}
