import Layout from 'components/Layout'
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Agenda from 'pages/Agenda'
import Categories from 'pages/Categories'
import Category from 'pages/Category'
import ErrorPage from 'pages/Error'
import FAQ from 'pages/FAQ'
import Home from 'pages/Home'
import Login from 'pages/Login'
import Search from 'pages/Search'
import Sponsor from 'pages/Sponsor'
import Sponsors from 'pages/Sponsors'

const Empty = () => <></>

const Routes = () => (
  <Router>
    <Layout>
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={Categories} path="/categories" exact />
        <Route component={Agenda} path="/agenda" exact />
        <Route component={Sponsors} path="/sponsors/:floorId/" exact />
        <Route component={Category} path="/category/:id/" exact />
        <Route component={Sponsor} path="/sponsor/:id/" exact />
        <Route component={Empty} path="/chats" exact />
        <Route component={FAQ} path="/faq" exact />
        <Route component={Login} path="/login" exact />
        <Route component={Search} path="/search" exact />
        <Route component={ErrorPage} path="*" />
      </Switch>
    </Layout>
  </Router>
)

export default Routes
