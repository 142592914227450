import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Info, MessageSquare, X } from 'react-feather'
import InfiniteScroll from 'react-infinite-scroller'
import { useToasts } from 'react-toast-notifications'
import { useRootData } from 'stores'
import { INotificationItem } from 'stores/notifications'
import styled from 'styled-components/macro'
import { dayjs } from 'utils/dates'
import niceShadow from 'utils/niceShadow'
import { tablet } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'
import { InlineLoader } from './Loader'

interface INotificationsProps {
  closeAction: (isChatNotificationClick?: boolean) => void
}

interface IItemProps {
  item: INotificationItem
  readed: string[]
  setCurrentPrivateRoom: (id: number) => void
  setChatWindowVisibility: (v: boolean) => any
  closeAction: (isChatNotificationClick?: boolean) => void
  readNotification: (item: INotificationItem) => void
}

const Item = ({
  item,
  readed,
  setCurrentPrivateRoom,
  setChatWindowVisibility,
  closeAction,
  readNotification,
}: IItemProps) => {
  const handleRead = (notification: INotificationItem) => {
    if (item.sender_id && item.type === 'Chat') {
      setCurrentPrivateRoom(item.sender_id)
      setChatWindowVisibility(true)
      closeAction(true)
    }
    readNotification(notification)
  }

  const Icon = item.type === 'Notification' ? Info : MessageSquare

  return (
    <ItemWrapper unread={!readed.includes(item.id)} onClick={() => handleRead(item)}>
      <ItemMessage>
        <Icon size="18" color="#3c62d9" />
        {item.message}
      </ItemMessage>
      <ItemDate>{dayjs(item.date).fromNow()}</ItemDate>
    </ItemWrapper>
  )
}

export default observer(({ closeAction }: INotificationsProps) => {
  const {
    loadingStatus,
    loadPage,
    readed,
    readNotification,
    allNotificationsWithoutReaded,
    hasMore,
    markAsNotNew,
  } = useRootData((store) => store.notificationsStore)
  const readedIds = readed()
  const allNotifications = allNotificationsWithoutReaded()

  const { addToast } = useToasts()
  const { setCurrentPrivateRoom, setChatWindowVisibility } = useRootData((store) => store.chatStore)

  useEffect(() => {
    loadPage(0) // maybe need resetNotifications here also
    // eslint-disable-next-line
  }, [loadPage])

  useEffect(() => {
    console.log('update', allNotifications)
    const ids = allNotifications
      .filter((n) => n.is_new)
      .map((n) => {
        console.log('added new notification', n)
        addToast(n.message, {
          appearance: 'info',
          autoDismiss: true,
        })
        return n.id
      })
    markAsNotNew(ids)
  }, [allNotifications])

  return (
    <DropdownWrapper>
      <Title>Notifications</Title>
      <CloseIcon color="#000" onClick={() => closeAction()} />
      <Scrollbars>
        <InfiniteScroll
          style={{ width: '100%' }}
          pageStart={0}
          initialLoad={false}
          loadMore={loadPage}
          useWindow={false}
          hasMore={hasMore()}
        >
          <Items>
            {allNotificationsWithoutReaded().map((item, index) => (
              <Item
                {...{
                  closeAction,
                  item,
                  key: item.id,
                  readNotification,
                  readed: readedIds,
                  setChatWindowVisibility,
                  setCurrentPrivateRoom,
                }}
              />
            ))}
          </Items>
          {loadingStatus === LoadingStatus.pending && <InlineLoader />}
        </InfiniteScroll>
      </Scrollbars>
    </DropdownWrapper>
  )
})

const DropdownWrapper = styled.div`
  ${niceShadow};
  position: absolute;
  z-index: 1;
  top: calc(100% - 10px);
  padding: 0;
  right: 10px;
  width: 40vw;
  height: 100vh;
  max-width: 300px;
  max-height: calc(80vh - 40px);
  color: #333;
  background-color: #fff;
  border-radius: 2px;
  white-space: normal;
  overflow: hidden;

  ${tablet} {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 60px;
    right: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }
`

const Scrollbars = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`

const Title = styled.h2`
  height: 60px;
  font-size: 20px;
  line-height: 60px;
  font-weight: 900;
  color: #333;
  position: absolute;
  top: 0;
  padding-left: 20px;
  display: none;

  ${tablet} {
    display: block;
  }
`

const CloseIcon = styled(X)`
  display: none;

  ${tablet} {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s;
  }

  &:hover {
    opacity: 1;
  }
`

const Items = styled.div`
  width: 100%;
  padding: 10px;
  overflow: auto;
  position: relative;

  ${tablet} {
    padding: 0 20px 20px;
  }
`

const ItemWrapper = styled.div<{ unread: boolean }>`
  margin: 0 0 10px;
  padding: 10px 20px 10px 10px;
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  background-color: ${(props) => (props.unread ? '#eceffb' : '#f2f2f2')};
  transition: background-color 0.15s;
  border-radius: 8px;
  overflow: hidden;
  cursor: ${(props) => (props.unread ? 'pointer' : 'default')};

  &:last-child {
    margin-bottom: 0;
  }
`

const ItemDate = styled.div`
  margin: 5px 0 0;
  padding: 0 0 0 28px;
  font-size: 11px;
  color: #666;
`

const ItemMessage = styled.div`
  margin: 0;
  display: flex;
  word-break: break-word;

  svg {
    margin: 0 10px 0 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
`
