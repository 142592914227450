// import emojisCodes from 'assets/emojisCodes.json'
import marked from 'marked'
import React, { memo } from 'react'
import { User } from 'react-feather'
import { IMessage } from 'stores/chat'
import styled from 'styled-components/macro'
import { dateStr } from 'utils/chat'

interface IMessageProps {
  message: IMessage
  realm: string
}

const renderer = new marked.Renderer()
const linkRenderer = renderer.link
renderer.link = (href, title, text) => {
  const html2 = linkRenderer.call(renderer, href, title, text)
  return html2.replace(/^<a /, '<a target="_blank" rel="nofollow" ')
}

export const Message = memo(({ message, realm }: IMessageProps) => {
  const newDate = new Date()
  newDate.setTime(message.timestamp * 1000)
  const name = message.sender_full_name
  const isMe = message.is_me_message

  // const replacer = (match: string, name2: keyof typeof emojisCodes.codes) => emojisCodes.codes[name2] || match
  // const msg = message.content.replace(/:([^\s:]+)(?:::skin-tone-[2-6])?:/gi, replacer)

  const Avatar = (url: string) => {
    if (/^https?:\/\//i.test(url)) {
      return <MessageAvatar src={url} alt="" />
    }
    return (
      <MessageIcon>
        <User color="#fff" size="18" />
      </MessageIcon>
    )
  }

  return (
    <MessageWrapper className={`${isMe ? 'me' : ''}`}>
      {!isMe && Avatar(message.avatar_url)}
      <MessageContent>
        <MessageDetails>
          <MessageName>{name}</MessageName>
          <DetailsDelimeter>,&nbsp;</DetailsDelimeter>
          <MessageTime>{dateStr(newDate)}</MessageTime>
        </MessageDetails>
        <MessageText
          dangerouslySetInnerHTML={{
            __html: marked(message.content, { baseUrl: realm, renderer, breaks: true }),
          }}
        />
      </MessageContent>
    </MessageWrapper>
  )
})

const avatarSize = 36
const horizontalGap = 8

const MessageWrapper = styled.div`
  padding: 10px 10px 15px ${horizontalGap}px;
  display: flex;
  line-height: 1;
  max-width: 100%;

  &:first-of-type {
    margin-top: auto;
  }

  &.me {
    text-align: right;
  }
`

const MessageAvatar = styled.img`
  overflow: hidden;
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
  border-radius: 50%;

  .me & {
    order: 2;
    display: none;
  }
`

const MessageIcon = styled.div`
  overflow: hidden;
  width: ${avatarSize}px;
  height: ${avatarSize}px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-color: #7691e4;
  order: 1;
  border-radius: 50%;

  .me & {
    order: 2;
    display: none;
  }
`

const MessageContent = styled.div`
  padding-left: ${horizontalGap}px;
  flex-grow: 1;
  order: 2;
  width: 100%;

  .me & {
    order: 1;
    padding-right: ${horizontalGap}px;
  }
`
const MessageDetails = styled.div`
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .me & {
    justify-content: flex-end;
  }
`
const MessageName = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #585757;
  order: 1;

  .me & {
    order: 3;
    display: none;
  }
`
const MessageTime = styled.div`
  font-size: 10px;
  color: #585757;
  order: 3;

  .me & {
    order: 1;
  }
`
const DetailsDelimeter = styled.div`
  font-size: 12px;
  color: #585757;
  order: 2;

  .me & {
    order: 2;
    display: none;
  }
`

const MessageText = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 8px 10px 7px;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  background-color: #f2f2f2;
  border-radius: 0 8px 8px 8px;
  margin-right: ${horizontalGap}px;
  max-width: 100%;
  overflow: hidden;

  .me & {
    background-color: #eceffb;
    border-radius: 8px 8px 0 8px;
    margin-right: 0;
  }

  img {
    width: 100%;
  }
`
