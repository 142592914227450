import { observer } from 'mobx-react-lite'
import React from 'react'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'

const BaseButton = styled.button`
  border-radius: 0.28571429rem;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  margin: 0 0.25em 0 0;
  min-height: 1em;
  outline: 0;
  overflow: visible;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.1s ease;
  user-select: none;
  vertical-align: baseline;

  &:hover {
    background-color: #009c95;
  }
`

const Button = styled(BaseButton)`
  background-color: #00b5ad;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: #fff;
  display: block;
  font-size: 1.14285714rem;
  text-shadow: none;
  width: 100%;
`

const LogoutPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 10%;
`

const LogoutFormWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 400px !important;
`

const Message = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`

export const LogoutForm = ({ logout }: any) => {
  return (
    <LogoutPage>
      <LogoutFormWrapper>
        <Message>You are logged in as User Name.</Message>
        <Button onClick={logout}>Logout</Button>
      </LogoutFormWrapper>
    </LogoutPage>
  )
}

export default observer(() => {
  const { logout } = useRootData((store) => store.appStore)
  return <LogoutForm logout={logout} />
})
