import { Loader } from 'components/Loader'
import React, { useState } from 'react'
import { Maximize, Minimize, X } from 'react-feather'
import ReactPlayer from 'react-player'
import styled from 'styled-components/macro'
import niceShadow from 'utils/niceShadow'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'

interface IVideoCardProps {
  video: string
  onClose: () => any
}

export default ({ video, onClose }: IVideoCardProps) => {
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [pip, setPip] = useState(false)
  const hasPictureInPicture = 'pictureInPictureEnabled' in document

  const togglePip = () => {
    setPip(!pip)
  }

  usePreventBodyScroll(!!(video && !pip))

  return (
    <Popup isPip={pip}>
      <Overlay onClick={onClose} />
      <PopupContent ready={ready}>
        {!ready && <Loader inline />}
        {!error && (
          <Player
            controls={!pip}
            playing
            pip={pip}
            url={video}
            width="100%"
            height="100%"
            onReady={() => setReady(true)}
            onError={() => setError(true)}
            onEnablePIP={() => setPip(true)}
            onDisablePIP={() => setPip(false)}
          />
        )}
        {error && <Message>Video can't be played right now</Message>}
        <Close title="Close video" onClick={onClose}>
          <X color="#fff" />
        </Close>
        {hasPictureInPicture && (
          <PictureButton
            title={`Open ${pip ? 'Full mode' : 'Picture in Picture'}`}
            onClick={togglePip}
          >
            {pip ? <Maximize color="#fff" /> : <Minimize color="#fff" />}
          </PictureButton>
        )}
      </PopupContent>
    </Popup>
  )
}

const PopupContent = styled.div<{ ready: boolean }>`
  ${niceShadow}
  position: relative;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 75%;
  transform: translate(-50%, -50%);
  background-color: ${({ ready }) => (ready ? '#000' : 'transparent')};

  ${tablet} {
    max-width: 100%;
    height: 100%;
    box-shadow: none;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
`

const Popup = styled.div<{ isPip?: boolean }>`
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${Loader} {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
  }

  ${({ isPip }) =>
    isPip
      ? `
    top: auto;
    left: 20px;
    bottom: 20px;
    width: 300px;
    height: 200px;

    ${tablet} {
      width: 200px;
      height: 150px;
    }

    ${PopupContent} {
      max-width: 100%;
      height: 100%;
    }
  `
      : ''}
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const Player = styled(ReactPlayer)`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Message = styled.div`
  text-align: center;
`

const Close = styled.button`
  position: absolute;
  padding: 0;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.15);
  appearance: none;
  border: none;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.15s;

  ${tablet} {
    top: 10px;
    right: 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.35);
  }
`

const PictureButton = styled(Close)`
  right: 23px;

  ${tablet} {
    right: 50px;
  }
`
