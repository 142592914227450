import NextVLogo from 'assets/icons/nextv_white.svg'
import GoodyBag from 'components/GoodyBag'
import SearchBlock from 'components/HeaderSearch'
import NotificationsFeed from 'components/NotificationsFeed'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Bell, Briefcase, ChevronDown, Menu, Search, User, X } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import styled, { css } from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import niceShadow from 'utils/niceShadow'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'
import blinkIcon from '../utils/blinkIcon'
import { ContentLoader } from './Loader'

const MenuLink = ({ to, title }: { to: string; title: string }) => (
  <StyledNavLink exact to={to} activeClassName="active-link">
    {title}
  </StyledNavLink>
)

export default observer(() => {
  const [showMenu, setShowMenu] = useState(false)
  const [showSection, setShowSection] = useState(false)
  const [showGoodyBag, setShowGoodyBag] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const { fullSponsorsFloors, fetchFullSponsors, fullSponsorsLoadingStatus } = useRootData(
    (store) => store.sponsorsStore
  )
  const { justAdded: chatJustAdded } = useRootData((store) => store.chatStore)
  const { logout, currentUser } = useRootData((store) => store.appStore)
  const floors = fullSponsorsFloors()
  const isSponsorsPage = location.pathname.match(/^\/sponsors/)
  const { width } = useWindowDimensions()
  const isMobile = width <= 1000

  const { getUnreadCount, resetNotifications } = useRootData((store) => store.notificationsStore)
  const { justAdded } = useRootData((store) => store.swagbagStore)
  const unreadCount = getUnreadCount()

  usePreventBodyScroll(showMenu || showGoodyBag || showNotifications)

  useEffect(() => {
    resetNotifications()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    fetchFullSponsors()

    return history.listen(() => window.scrollTo(0, 0))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setShowGoodyBag(false)
    setShowNotifications(false)
    setShowMenu(false)
  }, [location])

  useEffect(() => {
    if (isSponsorsPage && isMobile) {
      setShowSection(true)
    }
  }, [isSponsorsPage, isMobile])

  const goodyBagClick = useCallback(() => {
    setShowGoodyBag(!showGoodyBag)
  }, [showGoodyBag, setShowGoodyBag])

  const notificationsClick = useCallback(() => {
    setShowNotifications(!showNotifications)
  }, [showNotifications, setShowNotifications])

  const menuClick = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu, setShowMenu])

  const isSearchPage = useMemo(() => location.pathname.match(/^\/search\/?/), [location])

  return (
    <HeaderWrapper>
      <Container showMenu={showMenu}>
        <MobileMenuButton isActive={showMenu} onClick={menuClick}>
          <Menu size="24" color="#fff" />
        </MobileMenuButton>

        <LogoLink to="/">
          <NextVLogo />
        </LogoLink>

        {showMenu && (
          <MobileMenuButton isActive={showMenu} onClick={menuClick}>
            <X size="24" color="#fff" />
          </MobileMenuButton>
        )}
        {!showMenu && (
          <MobileSearchButton to="/search">
            {showMenu ? <X size="24" color="#fff" /> : <Search size="24" color="#fff" />}
          </MobileSearchButton>
        )}

        <MenuWrapper showMenu={showMenu}>
          <MenuSection>
            <MenuLink title="Categories" to="/categories" />

            <OutsideClickHandler onOutsideClick={() => setShowSection(false)}>
              <MenuItem onClick={() => setShowSection(!showSection)}>
                <MenuItemTitle
                  className={isSponsorsPage ? 'active-link' : ''}
                  showMenu={showSection}
                >
                  Exhibitors &amp; Sponsors <ChevronDown size="14" />
                </MenuItemTitle>
                <MenuInnerSection showMenu={showSection}>
                  <ContentLoader loadingStatus={fullSponsorsLoadingStatus()}>
                    {() => (
                      <>
                        {floors.map(({ title, id }) => (
                          <MenuLink key={id} title={title} to={`/sponsors/${id}/`} />
                        ))}
                      </>
                    )}
                  </ContentLoader>
                </MenuInnerSection>
              </MenuItem>
            </OutsideClickHandler>

            <MenuLink title="Webinars" to="/agenda" />
            <MenuLink title="Chats" to="/chats" />
            <MenuLink title="FAQ" to="/faq" />
          </MenuSection>

          {!isSearchPage && (
            <SearchBlock
              onSearch={(value) => history.push(value ? `/search/?v=${value}` : '/search/')}
            />
          )}

          <MenuSection>
            <OutsideClickHandler onOutsideClick={() => setShowGoodyBag(false)}>
              <MenuButton
                onClick={goodyBagClick}
                className={`goodyBag ${showGoodyBag ? 'active-link' : ''} ${justAdded &&
                  'justAdded'}`}
              >
                <Briefcase size="24" />
                <span>Goody Bag</span>
              </MenuButton>
              {showGoodyBag && <GoodyBag closeAction={goodyBagClick} />}
            </OutsideClickHandler>

            <OutsideClickHandler onOutsideClick={() => setShowNotifications(false)}>
              <MenuButton
                onClick={notificationsClick}
                className={`${showNotifications ? 'active-link' : ''} ${chatJustAdded &&
                  'justAdded'}`}
              >
                <Bell size="24" />
                <span>Notifications</span>
                {unreadCount > 0 && <NotificationsCount>{unreadCount}</NotificationsCount>}
              </MenuButton>
              {showNotifications && (
                <NotificationsFeed
                  closeAction={(isChatNotificationClick) => {
                    notificationsClick()
                    if (isChatNotificationClick) {
                      setShowMenu(false)
                    }
                  }}
                />
              )}
            </OutsideClickHandler>

            <OutsideClickHandler onOutsideClick={() => setShowProfileMenu(false)}>
              <MenuItem onClick={() => setShowProfileMenu(!showProfileMenu)}>
                <UserBlockTitle>
                  <User size="24" />
                  <span>
                    {currentUser.firstName || 'Profile'} {currentUser.lastName || ''}
                  </span>
                </UserBlockTitle>
                <MenuInnerSection showMenu={showProfileMenu}>
                  <MenuButton onClick={logout}>Logout</MenuButton>
                </MenuInnerSection>
              </MenuItem>
            </OutsideClickHandler>
          </MenuSection>
        </MenuWrapper>
      </Container>
    </HeaderWrapper>
  )
})

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #456dde;

  ${tablet} {
    height: 50px;
  }
`

const Container = styled.div<{ showMenu?: boolean }>`
  position: relative;
  padding: 0 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1300px;
  margin: auto;
  color: #fff;
  background: #456dde;

  ${tablet} {
    padding: 0;
    height: 50px;
    width: 100%;

    ${({ showMenu }) =>
      showMenu
        ? `
          position: fixed;
          top: 0;
          left: 0;
          z-index: 2;
        `
        : ''}
  }
`

const MobileMenuButton = styled.button<{ isActive?: boolean }>`
  display: none;

  ${tablet} {
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    appearance: none;
    border: none;
  }
`

const LogoLink = styled(NavLink)`
  position: relative;
  margin: 15px 10px 0 0;
  flex-shrink: 0;
  width: 120px;
  height: auto;
  color: #fff;

  ${tablet} {
    width: 90px;
    margin: 10px auto 0 0;
  }
`

const MenuWrapper = styled.div<{ showMenu?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${tablet} {
    position: fixed;
    z-index: 10;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 17px 0;
    flex-direction: column;
    align-items: flex-start;
    background: #456dde;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.1s ease-out;

    ${({ showMenu }) =>
      showMenu &&
      `
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      `}
  }
`

const MenuInnerSection = styled.div<{ showMenu?: boolean }>`
  ${niceShadow};
  position: absolute;
  top: 43px;
  right: 0;
  z-index: 10;
  padding: 10px 0;
  width: 100%;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: ${({ showMenu }) => (showMenu ? '10000px' : '1px')};
  transition: all 0.1s ease-out;
  background-color: #fff;
  border-radius: 2px;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  visibility: ${({ showMenu }) => (showMenu ? 'visible' : 'hidden')};

  ${tablet} {
    position: static;
    padding: ${(props) => (props.showMenu ? '15px 0' : 0)};
    width: 100%;
    background-color: #3c62d9;
    border-radius: 0;
    box-shadow: none;
  }

  .active-link {
    background: #7691e4;

    &:after {
      display: none;
    }
  }
`

const menuLinkStyles = css`
  padding: 0 15px;
  font-size: 16px;
  height: 40px;
  align-items: center;
  text-align: center;
  line-height: 40px;
  display: flex;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  flex-grow: 1;
  text-decoration: none;
  position: relative;
  transition: color 0.15s, background-color 0.15s;

  &:hover:not(div),
  &.active-link {
    background-color: #7691e4;
    color: #fff;
  }

  ${tablet} {
    width: 100%;
    font-size: 20px;
    text-align: left;
    padding: 0 30px 0 45px;

    ${MenuInnerSection} & {
      padding: 0 30px 0 75px;
      font-size: 16px;
    }
  }

  ${MenuInnerSection} & {
    color: #000;
    font-weight: 400;

    &:hover:not(div),
    &.active-link {
      background: #456dde;
      color: #fff;
    }

    ${tablet} {
      color: #fff;
      font-weight: 700;
    }
  }
`

const MenuItem = styled.div`
  position: relative;
  width: 100%;
`

const MenuItemTitle = styled.div<{ showMenu?: boolean }>`
  ${menuLinkStyles}
  ${tablet} {
    padding: 0 30px 0 45px;
  }

  svg {
    margin: 4px 0 0 5px;
    transition: transform 0.15s;
    transform: ${({ showMenu }) => (showMenu ? 'rotate(-180deg)' : 'rotate(0)')};
  }
`

const UserBlockTitle = styled(MenuItemTitle)`
  svg {
    margin: 0;
    margin-right: 10px;
  }
`

const StyledNavLink = styled(NavLink)`
  ${menuLinkStyles}
`

const MobileSearchButton = styled(NavLink)`
  display: none;

  ${tablet} {
    margin: 0 0 0 auto;
    padding: 0;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    appearance: none;
    border: none;
  }
`

const MenuSection = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  ${tablet} {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`

const MenuButton = styled.button`
  background: none;
  border: none;
  ${menuLinkStyles}

  &.active-link::after {
    display: none;
  }

  span {
    display: none;

    ${tablet} {
      margin-left: 10px;
      display: block;
    }
  }

  &.justAdded svg {
    ${blinkIcon()};
  }
`

const NotificationsCount = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  top: 0;
  right: 10px;
  font-size: 9px;
  font-weight: bold;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  ${tablet} {
    position: static;
    margin-left: 10px;
  }
`
