import DocumentCard from 'components/DocumentCard'
import QuestionCard from 'components/QuestionCard'
import CategoryCard from 'components/SearchCategoryCard'
import CompanyCard from 'components/SearchCompanyCard'
import VideoCard from 'components/VideoCard'
import fuzzy from 'fuzzysort'
import React, { useMemo } from 'react'
import { IQuestion } from 'stores/faq'
import { ICategoryWithSponsors, IFullSponsor, ISponsorContentItem } from 'stores/sponsors'
import { ISwagItem } from 'stores/swagbag'
import styled from 'styled-components'
import { useSetVideo } from './VideoController'

interface ISearchResultsProps {
  sponsorsList: IFullSponsor[]
  documents: ISponsorContentItem[]
  videos: ISponsorContentItem[]
  categories: ICategoryWithSponsors[]
  questions: IQuestion[]
  searchText: string
  addSwagItem: (item: ISwagItem) => Promise<any>
  swagBagContains: (id: string) => boolean
  swagItems: ISwagItem[]
}

export default function SearchResults({
  sponsorsList,
  searchText,
  documents,
  categories,
  questions,
  addSwagItem,
  videos,
  swagBagContains,
}: ISearchResultsProps) {
  const [
    count,
    foundCategories,
    foundSponsors,
    foundDocuments,
    foundVideos,
    foundQuestions,
  ] = useMemo(() => {
    const phrase = searchText

    const sponsors = fuzzy.go(phrase, sponsorsList, { key: 'company', limit: 10 }).map((e) => e.obj)
    const cats = fuzzy.go(phrase, categories, { key: 'displayName', limit: 5 }).map((e) => e.obj)
    const docs = fuzzy.go(phrase, documents, { key: 'title', limit: 10 }).map((e) => e.obj)
    const vids = fuzzy.go(phrase, videos, { key: 'title', limit: 10 }).map((e) => e.obj)
    const ques = fuzzy.go(phrase, questions, { key: 'question', limit: 5 }).map((e) => e.obj)
    const n = cats.length + sponsors.length + vids.length + docs.length + ques.length

    return [n, cats, sponsors, docs, vids, ques]
  }, [searchText, sponsorsList, categories, documents, videos, questions])

  const setVideo = useSetVideo()

  return (
    <Wrapper>
      {!searchText && <Results>Input you search text to see results</Results>}
      {searchText && count < 1 && <Results>Nothing found</Results>}

      {foundCategories.length > 0 && (
        <Results>
          <Title>Categories</Title>
          {foundCategories.map((category) => (
            <CategoryCard key={category.id} category={category} />
          ))}
        </Results>
      )}

      {foundSponsors.length > 0 && (
        <Results>
          <Title>Companies</Title>
          {foundSponsors.map((sponsor) => (
            <CompanyCard key={sponsor.id} sponsor={sponsor} />
          ))}
        </Results>
      )}

      {foundDocuments.length > 0 && (
        <Results>
          <Title>Documents</Title>
          {foundDocuments.map((doc) => (
            <DocumentCard
              addSwagItem={addSwagItem}
              key={doc.id}
              document={doc}
              addedToSwag={swagBagContains(doc.id)}
            />
          ))}
        </Results>
      )}

      {foundVideos.length > 0 && (
        <Results>
          <Title>Videos</Title>
          {foundVideos.map((video) => (
            <VideoCard
              addSwagItem={addSwagItem}
              key={video.id}
              video={video}
              onVideoOpen={() => setVideo(video.url)}
              addedToSwag={swagBagContains(video.id)}
            />
          ))}
        </Results>
      )}

      {foundQuestions.length > 0 && (
        <Results>
          <Title>Questions</Title>
          {foundQuestions.map((question) => (
            <QuestionCard key={question.question} item={question} />
          ))}
        </Results>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  padding: 20px;
  margin: auto;
`

const Results = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
`

const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  width: 100%;
`
