import DocumentCard from 'components/DocumentCard'
import VideoCard from 'components/VideoCard'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import React, { ReactNode, useMemo, useState } from 'react'
import { BookOpen, ChevronLeft, ChevronRight, Info, Video, X } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import { getDocuments, getVideos, IFullSponsor, ISponsorContentItem } from 'stores/sponsors'
import { ISwagItem } from 'stores/swagbag'
import styled, { css } from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import { centerShadow } from 'utils/niceShadow'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'

interface IContentProps {
  addSwagItem: (item: ISwagItem) => Promise<any>
  sponsor: IFullSponsor
  onVideoOpen: (video: ISponsorContentItem) => any
  children: ReactNode
  swagBagContains: (id: string) => boolean
  swagItems: ISwagItem[]
}

const Content = ({
  sponsor,
  onVideoOpen,
  children,
  addSwagItem,
  swagBagContains,
}: IContentProps) => {
  const [activeTab, setActiveTab] = useState('')
  const { width } = useWindowDimensions()
  const isMobile = width < 1024

  const videos = useMemo(() => getVideos(sponsor), [sponsor])
  const documents = useMemo(() => getDocuments(sponsor), [sponsor])

  const handleVideoClick = (video: ISponsorContentItem) => {
    setActiveTab('')
    onVideoOpen(video)
  }

  const handleDocumentClick = () => {
    setActiveTab('')
  }

  const clearFocus = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  usePreventBodyScroll(activeTab === 'Videos' || activeTab === 'Documents')

  return (
    <ContentWrapper>
      <TabsPanel>
        <TabTitle
          disabled={!videos}
          onClick={() => setActiveTab(activeTab !== 'Videos' ? 'Videos' : '')}
          className={`ignore-outside-click ${activeTab === 'Videos' ? 'active' : ''}`}
        >
          <Video size="24" />
          <span>Videos</span>
        </TabTitle>
        <TabTitle
          disabled={!documents}
          onClick={() => setActiveTab(activeTab !== 'Documents' ? 'Documents' : '')}
          className={`ignore-outside-click ${activeTab === 'Documents' ? 'active' : ''}`}
        >
          <BookOpen size="24" />
          <span>Documents</span>
        </TabTitle>
        <TabTitle
          onClick={() => {
            window.scrollTo({
              behavior: 'smooth',
              top: (document.getElementById('Description')?.getBoundingClientRect().top || 60) - 60,
            })
            clearFocus()
          }}
          className="ga_desciption_view_click"
          data-ga-id={sponsor.id}
        >
          <Info size="24" />
          <span>Description</span>
        </TabTitle>
      </TabsPanel>

      {children}

      <ContentGrid>
        <>
          <SponsorDescription id="Description">
            <div dangerouslySetInnerHTML={{ __html: sponsor.description || 'No description' }} />
          </SponsorDescription>
        </>

        <OutsideClickHandler
          onOutsideClick={(event) => {
            if ((event.target as HTMLButtonElement).closest('.ignore-outside-click')) {
              return clearFocus()
            }
            return activeTab.length > 1 ? setActiveTab('') : null
          }}
        >
          <ContentAside active={['Videos', 'Documents'].includes(activeTab)}>
            <CloseIcon color="#000" onClick={() => setActiveTab('')} />
            <ContentBody activeTab={activeTab}>
              {videos.length < 1 && activeTab === 'Videos' && <NoItems>No videos</NoItems>}
              {videos.length > 0 &&
                activeTab === 'Videos' &&
                (isMobile ? (
                  videos.map((contentItem, i) => (
                    <VideoCard
                      addSwagItem={addSwagItem}
                      key={contentItem.id + '_' + i}
                      video={contentItem}
                      onVideoOpen={handleVideoClick}
                      addedToSwag={swagBagContains(contentItem.id)}
                    />
                  ))
                ) : (
                  <CarouselProvider
                    naturalSlideWidth={220}
                    naturalSlideHeight={320}
                    isIntrinsicHeight={true}
                    visibleSlides={4}
                    step={4}
                    totalSlides={videos.length}
                  >
                    <Slider>
                      {videos.map((contentItem, i) => (
                        <Slide key={i} index={i}>
                          <VideoCard
                            addSwagItem={addSwagItem}
                            key={contentItem.id + '_' + i}
                            video={contentItem}
                            onVideoOpen={handleVideoClick}
                            addedToSwag={swagBagContains(contentItem.id)}
                          />
                        </Slide>
                      ))}
                    </Slider>
                    {videos.length > 4 && (
                      <>
                        <PrevSlide>
                          <ChevronLeft />
                        </PrevSlide>
                        <NextSlide>
                          <ChevronRight />
                        </NextSlide>
                      </>
                    )}
                  </CarouselProvider>
                ))}

              {documents.length < 1 && activeTab === 'Documents' && <NoItems>No documents</NoItems>}
              {documents.length > 0 &&
                activeTab === 'Documents' &&
                (isMobile ? (
                  documents.map((contentItem) => (
                    <DocumentCard
                      addSwagItem={addSwagItem}
                      key={contentItem.id}
                      document={contentItem}
                      onDocumentOpen={handleDocumentClick}
                      addedToSwag={swagBagContains(contentItem.id)}
                    />
                  ))
                ) : (
                  <CarouselProvider
                    naturalSlideWidth={400}
                    naturalSlideHeight={100}
                    isIntrinsicHeight={true}
                    visibleSlides={2}
                    step={2}
                    totalSlides={videos.length}
                  >
                    <Slider>
                      {documents.map((contentItem, i) => (
                        <Slide key={i} index={i}>
                          <DocumentCard
                            addSwagItem={addSwagItem}
                            key={contentItem.id}
                            document={contentItem}
                            onDocumentOpen={handleDocumentClick}
                            addedToSwag={swagBagContains(contentItem.id)}
                          />
                        </Slide>
                      ))}
                    </Slider>
                    {documents.length > 2 && (
                      <>
                        <ButtonBack>
                          <ChevronLeft />
                        </ButtonBack>
                        <ButtonNext>
                          <ChevronRight />
                        </ButtonNext>
                      </>
                    )}
                  </CarouselProvider>
                ))}
            </ContentBody>
          </ContentAside>
        </OutsideClickHandler>
      </ContentGrid>
    </ContentWrapper>
  )
}

export default Content

const ContentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -10px;
`

const ContentAside = styled.aside<{ active: boolean }>`
  ${centerShadow};
  position: fixed;
  z-index: 300;
  right: 0;
  bottom: 0;
  transform: ${({ active }) => (active ? 'translate(0, 0)' : 'translate(0, 320px)')};
  left: 0;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: ${({ active }) => (active ? 1 : 0)};
  background-color: #fff;
  transition: transform 0.25s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;

  ${tablet} {
    top: 0;
    z-index: ${({ active }) => (active ? 1000 : 300)};
  }
`

const buttonWidth = 60

const slideButton = css`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${buttonWidth}px;
  margin: auto;
  background: none;
  border: none;
  appearance: none;
`

const PrevSlide = styled(ButtonBack)`
  ${slideButton};
  left: -${buttonWidth}px;
`

const NextSlide = styled(ButtonNext)`
  ${slideButton};
  right: -${buttonWidth}px;
`

const NoItems = styled.div`
  padding: 20px;
`

const ContentBody = styled.div<{ activeTab: string }>`
  position: relative;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: calc(100% - ${buttonWidth * 2}px);

  > div {
    width: 100%;
  }

  ${tablet} {
    padding-top: 65px;
    width: 100%;
    height: 100%;
    flex: none;
    flex-direction: ${({ activeTab }) => (activeTab === 'Videos' ? 'row' : 'column')};
    flex-wrap: wrap;
    overflow-y: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const SponsorDescription = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 10px;
  font-size: 16px;
  line-height: 26px;
  transition: max-height 0.15s ease-in-out;
  background: #fff;

  p {
    padding: 0 0 10px;
  }
`

const CloseIcon = styled(X)`
  position: absolute;
  z-index: 10;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s;

  &:hover {
    opacity: 1;
  }
`

const ContentWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${tablet} & {
    padding: 0;
  }
`

const TabsPanel = styled.div`
  overflow: hidden;
  display: flex;
  margin: 0 auto 30px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  box-shadow: 0 0 0 1px #bfbfbf;
`
const TabTitle = styled.button`
  position: relative;
  margin: 0 -1px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30%;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: #3c62d9;
  border: none;
  border-right: 1px solid #bfbfbf;
  appearance: none;
  background: #fff;
  transition: color 0.15s, background-color 0.15s;

  svg * {
    stroke: #3c62d9;
  }

  &:hover {
    color: #fff;

    svg * {
      stroke: #fff;
    }
  }

  &:first-child {
    padding: 0 30px 0 40px;
  }

  &:last-child {
    padding: 0 40px 0 30px;
  }

  span {
    margin-left: 10px;

    ${tablet} {
      display: none;
    }
  }

  ${tablet} {
    min-width: 25%;
  }

  &.active {
    color: #fff;
    background-color: #3c62d9;
    cursor: default;

    svg * {
      stroke: #fff;
    }
  }

  &:not(.active):hover {
    color: #fff;
    background-color: #6a86e0;
  }
`
