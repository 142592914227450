import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './config'

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export const getAccessToken: () => string | null = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY)
  return token ? token : null
}

export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, token)
}

export const getRefreshToken: () => string | null = () => {
  const token = localStorage.getItem(REFRESH_TOKEN_KEY)
  return token ? token : null
}

export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY)

export const decodeToken = (token: string) => {
  return {
    header: token ? JSON.parse(window.atob(token.split('.')[0])) : '',
    payload: token ? JSON.parse(window.atob(token.split('.')[1])) : '',
  }
}
