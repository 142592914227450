import jquery from 'jquery' // don't load full jquery (we need only hubConnection and maybe ajax)
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useRootData } from 'stores'
import { API, DEVICE_ID } from 'utils/config'

const globalScope = window as any
globalScope.jQuery = jquery
const jQuery: JQueryStatic = globalScope.jQuery as JQueryStatic

export default observer(() => {
  const { addToast } = useToasts()
  const { accessToken } = useRootData((store) => store.appStore)
  const { resetNotifications } = useRootData((store) => store.notificationsStore)
  useEffect(() => {
    import('signalr').then(() => {
      const connection: SignalR.Hub.Connection = jQuery.hubConnection(API)
      connection.logging = true
      connection.qs = { authorization: accessToken, deviceId: DEVICE_ID }
      const hubProxy: SignalR.Hub.Proxy = connection.createHubProxy('virtual-notifications')
      hubProxy.on('OnVirtualNotify', (subject: string, message: string, acknowledge: false) => {
        // problem with remove toast when click on notification in NotificationsFeed
        // solution - write own toast (with state in MST, so possible to integrate with notifications)
        // we need to discuss this with PM
        resetNotifications()
        addToast(message, {
          appearance: 'info',
          autoDismiss: !acknowledge,
        })
      })
      connection
        .start()
        .done(() => {
          // tslint:disable-next-line: no-console
          console.info('SignalR now connected, connection ID=' + connection.id)
        })
        .fail(() => {
          // tslint:disable-next-line: no-console
          console.warn('SignalR could not connect')
        })
    })
    // eslint-disable-next-line
  }, [])

  return null
})
