import { useLayoutEffect, useMemo } from 'react'
import useWindowDimensions from './dimensions'

const byId: { [key: number]: boolean } = {}
const hasPrevented = () => Object.values(byId).find((v) => v)

export default function usePreventBodyScroll(shouldPrevent: boolean) {
  const { width } = useWindowDimensions()
  const id = useMemo(() => Date.now(), [])
  const isMobile = width <= 1000
  const prevented = isMobile && shouldPrevent

  useLayoutEffect(() => {
    byId[id] = prevented
    if (hasPrevented()) {
      document.body.setAttribute('style', 'overflow: hidden')
    } else {
      document.body.setAttribute('style', '')
    }
    return () => {
      delete byId[id]
      if (hasPrevented()) {
        document.body.setAttribute('style', '')
      }
    }
  }, [prevented])

  return prevented
}
