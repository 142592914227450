import Auth from 'components/Auth'
import ErrorPopup from 'components/ErrorPopup'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import CompleteSaml from 'pages/CompleteSaml'
import ForgotPassword from 'pages/ForgotPassword'
import LoginSaml from 'pages/LoginSaml'
import Registration from 'pages/Registration'
import React, { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { useRootData } from 'stores'
import StoreProvider from 'stores/StoreProvider'
import { combineLoadingStatus } from 'utils/store'
import GlobalStyle from './global-styles'
import Router from './router'

const AppWithSettings = observer(() => {
  const { fetch, loadingStatus } = useRootData((store) => store.settingsStore)
  const { fetchUserDetails, currentUser, loadCurrentUserStatus } = useRootData(
    (store) => store.appStore
  )
  useEffect(() => {
    fetch()
    fetchUserDetails()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentUser) {
      TagManager.initialize({
        dataLayer: {
          userId: currentUser.id,
        },
        gtmId: 'GTM-K8JTCSD',
      })
      const globalScope = window as any
      globalScope.dataLayer.push({ userId: currentUser.id })
    }
  }, [currentUser])

  return (
    <ContentLoader loadingStatus={combineLoadingStatus([loadingStatus, loadCurrentUserStatus])}>
      {() => <Router />}
    </ContentLoader>
  )
})

const AppWithAuth = () => <Auth>{() => <AppWithSettings />}</Auth>

const AppWithHandledSaml = () => (
  <>
    <BrowserRouter>
      <Switch>
        <Route component={Registration} path="/registration" exact />
        <Route component={ForgotPassword} path="/forgot-password" exact />
        <Route component={LoginSaml} path="/login" exact />
        <Route component={LoginSaml} path="/login/saml" exact />
        <Route component={CompleteSaml} path="/complete/saml" exact />
        <Route component={AppWithAuth} path="*" />
      </Switch>
    </BrowserRouter>
  </>
)

export const App = () => (
  <ToastProvider>
    <GlobalStyle />
    <StoreProvider>
      <>
        <AppWithHandledSaml />
        <ErrorPopup />
      </>
    </StoreProvider>
  </ToastProvider>
)
