import Countdown from 'components/Countdown'
import { observer } from 'mobx-react-lite'
import React, { ReactNode, useEffect, useRef } from 'react'
import { useRootData } from 'stores'
import { getAccessToken } from 'utils/auth'

interface IAuthHandlerProps {
  children: () => ReactNode
}

export const Auth = observer(({ children }: IAuthHandlerProps) => {
  const refreshTimeout = useRef<any>(0)
  const updateInterval = useRef<any>(0)
  const { accessToken, getNewToken, logout, refreshToken, updateTokens } = useRootData(
    (store) => store.appStore
  )

  // We need this only on init
  useEffect(() => {
    if (refreshToken) {
      getNewToken()
    }
    startUpdate()
    return () => stopUpdate()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (refreshToken) {
      startRefresh()
    }
    return () => stopRefresh()
    // eslint-disable-next-line
  }, [refreshToken])

  const startUpdate = () => {
    updateInterval.current = setInterval(() => updateTokens(), 2000)
  }

  const stopUpdate = () => {
    clearInterval(updateInterval.current)
  }

  const startRefresh = () => {
    refreshTimeout.current = setTimeout(async () => {
      const currentToken = getAccessToken()
      if (!currentToken) {
        stopRefresh()
        logout()
        return
      }

      try {
        await getNewToken()
      } finally {
        startRefresh()
      }
    }, 300000)
  }

  const stopRefresh = () => {
    clearTimeout(refreshTimeout.current)
  }

  if (accessToken) {
    return <>{children()}</>
  }

  return <Countdown />
})

export default Auth
