import React from 'react'
import { Icon } from 'react-feather'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { centerShadow } from 'utils/niceShadow'
import { mobile } from 'utils/responsive'

interface IMode {
  icon: Icon
  name: string
}

interface IProps {
  modes: IMode[]
  mode: string
  setMode: (mode: string) => any
}

interface ITooltips {
  [key: string]: string
}

const tooltips = {
  '2d': 'Flat view',
  '3d': 'Zoom and rotate booth in perspective view',
} as ITooltips

export default function ModesSelect({ modes, mode, setMode }: IProps) {
  const onModeSelect = (m: string) => () => {
    setMode(m)
  }

  return (
    <ModeSelector>
      <>
        <ReactTooltip
          id="modesTooltip"
          getContent={(dataTip) => `${dataTip}`}
          place="right"
          type="info"
          effect="solid"
        />
        {modes.map(({ icon: ModeIcon, name }) => (
          <Mode
            data-for="modesTooltip"
            data-tip={tooltips[name]}
            key={name}
            active={mode === name}
            onClick={onModeSelect(name)}
          >
            <ModeIcon />
          </Mode>
        ))}
      </>
    </ModeSelector>
  )
}

const ModeSelector = styled.div`
  ${centerShadow};
  position: absolute;
  top: 80px;
  left: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #456dde;
  overflow: hidden;
`

const Mode = styled.button<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  background: ${({ active }) => (active ? '#e91e63' : '#456dde')};
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: #e91e63;
  }

  ${mobile} {
    width: 40px;
    height: 40px;
  }
`
