import React, { memo, useMemo } from 'react'
import { Facebook, Instagram, Mail, Twitter, Youtube } from 'react-feather'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'

interface ILinksProps {
  sponsor: IFullSponsor
}

export const LinksRow = memo(({ sponsor }: ILinksProps) => {
  const socialLinks = useMemo(() => {
    return socialIcons
      .map(({ name, Component }) => {
        const link = (sponsor.content || []).find(
          (item) => item.contentType === 'Link' && item.linkType === name
        )
        return { link, Component }
      })
      .filter((item) => item.link)
      .map(({ link, Component }) => {
        const type = link?.linkType.toLowerCase()
        return (
          <LinkIcon
            href={link?.linkType === 'Email' ? `mailto:${link!.url}` : link!.url}
            target="_blank"
            rel="noopener noreferrer"
            title={link!.title}
            key={link!.id}
            className={`${type} ga_social_link_${type}`}
            data-ga-id={link!.id}
          >
            <Component />
          </LinkIcon>
        )
      })
  }, [sponsor])

  return (
    <LinksWrapper>
      <Columns>{socialLinks}</Columns>

      <SiteLinks>
        {(sponsor.content || [])
          .filter((item) => item.contentType === 'Link' && item.linkType === 'External')
          .map((link) => (
            <a
              key={link.id}
              href={link.url}
              className="ga_social_link_external"
              target="_blank"
              rel="noopener noreferrer"
              data-ga-id={link.id}
            >
              {link.title}
            </a>
          ))}
      </SiteLinks>
    </LinksWrapper>
  )
})

export default LinksRow

const socialIcons = [
  {
    Component: Youtube,
    name: 'Youtube',
  },
  {
    Component: Twitter,
    name: 'Twitter',
  },
  {
    Component: Facebook,
    name: 'Facebook',
  },
  {
    Component: Instagram,
    name: 'Instagram',
  },
  {
    Component: Mail,
    name: 'Email',
  },
]

const Columns = styled.div`
  display: flex;
  margin-right: 30px;

  ${tablet} {
    flex-wrap: wrap;
  }
`

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 30px;

  ${tablet} {
    flex-direction: column;
  }
`

const LinkIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  width: 36px;
  height: 36px;
  background-color: #3c62d9;
  border-radius: 4px;
  transition: transform 0.15s ease-in-out;
  transform: scale(1);

  ${tablet} {
    width: 44px;
    height: 44px;
    margin: 0 5px;
  }

  svg {
    transition: transform 0.15s ease-in-out;
    transform: scale(1);

    ${tablet} {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    transform: scale(1.05);

    svg {
      transform: scale(0.86);
    }
  }

  &.email {
    background-color: #3c62d9;

    svg {
      stroke: #fff;
    }
  }

  &.twitter {
    svg {
      fill: #fff;
      stroke: none;
    }
  }

  &.facebook {
    svg {
      fill: #fff;
      stroke: none;
    }
  }

  &.instagram {
    svg {
      stroke: #fff;
    }
  }
`

const SiteLinks = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  line-height: 36px;
  margin-left: auto;
  width: 100%;

  ${tablet} {
    margin: 30px auto 0;
    justify-content: center;
  }

  a {
    color: #4b6dca;
    font-weight: bold;
    font-size: 18px;
    margin-left: 20px;
    text-decoration: none;
    position: relative;

    ${tablet} {
      margin: 0 10px;
    }

    &:hover {
      color: #6e87ca;
    }
  }
`
