import BoothesWithSidePanel from 'components/BoothesWithSidePanel'
import LiveAgenda from 'components/LiveAgenda'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRootData } from 'stores'
import { LoadingStatus } from 'utils/store'
import ErrorPage from './Error'

export default observer(function CategoryPage() {
  const { fullCategory, fetchFullSponsors, fullSponsorsLoadingStatus } = useRootData(
    (store) => store.sponsorsStore
  )
  const { id } = useParams()
  const category = fullCategory(id as string)
  const loadingStatus = fullSponsorsLoadingStatus()

  useEffect(() => {
    fetchFullSponsors()
    // eslint-disable-next-line
  }, [])

  if (loadingStatus === LoadingStatus.success && !category) {
    return <ErrorPage />
  }

  return (
    <>
      <ContentLoader loadingStatus={loadingStatus}>
        {() => (
          <BoothesWithSidePanel title={category!.displayName} allSponsors={category!.sponsors} />
        )}
      </ContentLoader>
      <LiveAgenda />
    </>
  )
})
