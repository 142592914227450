import {
  IAbstractionsContractsQueriesNotificationDataNotificationDataResponse,
  IAbstractionsContractsQueriesNotificationDataNotificationDataResponseNotificationData,
} from 'api-types'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import flatten from 'lodash/flatten'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { buildQuery, IFilterConfig } from 'utils/filter'
import { createAxiosAction, IRootStore, LoadingStatus, LoadingStatusType } from 'utils/store'

export type INotifications = IAbstractionsContractsQueriesNotificationDataNotificationDataResponseNotificationData

export interface INotificationItem {
  type: 'Chat' | 'Notification'
  message: string
  id: string
  date: string
  sender_id?: number
  is_new?: boolean
}

const filters = {
  itemsOnPage: 20,
  sort: {
    desc: true,
    field: 'CreateDate',
  },
}

export default types
  .model('NotificationsStore', {
    // but we can compute this var from unread messages (as view)
    chatNotifications: types.frozen<INotificationItem[]>([]),
    loadingStatus: LoadingStatusType,
    // i think don't need pagination for notifications (problem with counter larger than page)
    notifications: types.array(types.frozen<INotificationItem[]>([])),
    totalPages: types.optional(types.number, 0),
  })

  .views((self) => ({
    allNotifications: () =>
      self.chatNotifications
        .concat(flatten(self.notifications))
        .sort((a, b) => dayjs(b.date).diff(dayjs(a.date))),
    readed: () => getParent<IRootStore>(self).appStore.readedNotifications(),
  }))

  .views((self) => ({
    allNotificationsWithoutReaded: () => {
      const readed = self.readed()
      return self.allNotifications().filter((item) => !readed.includes(item.id))
    },
    getUnreadCount: () =>
      self.allNotifications().reduce((acc, n) => (self.readed().includes(n.id) ? acc : acc + 1), 0),
    hasMore: () =>
      self.loadingStatus === LoadingStatus.success && self.notifications.length < self.totalPages,
  }))

  .actions((self) => ({
    removeChatItem(id: string) {
      self.chatNotifications = self.chatNotifications.filter((item) => item.id !== id)
    },
    appendChatItem(notification: INotificationItem) {
      const last = self.chatNotifications[0]
      if (!last || last.sender_id !== notification.sender_id) {
        self.chatNotifications = [notification, ...self.chatNotifications]
      }
    },
  }))

  .actions((self) => ({
    setReadedNotificationsIds(ids: string[]) {
      getParent<IRootStore>(self).appStore.updatePartialUserMetadata({ readedNotifications: ids })
    },
  }))

  .actions((self) => ({
    fetchNotifications: createAxiosAction(
      flow(function*(filter: IFilterConfig) {
        const { data } = (yield axios.get(
          `/public/notifications?${buildQuery(filter)}`
        )) as AxiosResponse<IAbstractionsContractsQueriesNotificationDataNotificationDataResponse>
        self.notifications[filter.page] = data.notificationsData.map(
          (item) =>
            ({
              date: item.createDate,
              id: item.id,
              message: item.message,
              type: 'Notification',
            } as INotificationItem)
        )
        self.totalPages = data.totalPages
      }),
      (s) => (self.loadingStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to load notifications')
    ),
  }))

  .actions((self) => ({
    loadPage: (page: number) =>
      self.fetchNotifications({ ...filters, ...{ page } } as IFilterConfig),

    readNotification: (item: INotificationItem) => {
      if (item.type === 'Notification') {
        const readed = self.readed()
        if (!readed.includes(item.id)) {
          self.setReadedNotificationsIds([...readed, item.id])
        }
      }

      if (item.type === 'Chat') {
        self.removeChatItem(item.id)
      }
    },

    markAsNotNew: (ids: string[]) => {
      for (const id of ids) {
        // implement marking as not new in good way
        for (const page in self.notifications) {
          self.notifications[page] = self.notifications[page].map((n) => {
            if (ids.includes(id)) {
              return { ...n, is_new: false }
            } else {
              return n
            }
          })
        }
      }
    },
  }))
  .actions((self) => ({
    resetNotifications: () => {
      self.notifications.clear()
      self.loadPage(0)
    },
  }))
