import {
  IAbstractionsContractsQueriesCategoriesCategoriesResponseCategory,
  IAbstractionsContractsQueriesSettingsResponse,
} from 'api-types'
import { AxiosResponse } from 'axios'
import once from 'lodash/once'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import { createAxiosAction, IRootStore, ISettings, LoadingStatusType } from 'utils/store'

export type ICategory = IAbstractionsContractsQueriesCategoriesCategoriesResponseCategory

export default types
  .model('AppStore', {
    categoriesStatus: LoadingStatusType,
    loadingStatus: LoadingStatusType,
    settings: types.frozen<ISettings>({} as ISettings),
    categories: types.frozen<ICategory[]>([]),
  })

  .views((self) => ({
    sponsorsSections: () => self.settings.sponsorsSetting.displaySettingsObject.sections,
  }))

  .actions((self) => ({
    fetch: once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get('/public/settings')) as AxiosResponse<
            IAbstractionsContractsQueriesSettingsResponse
          >
          self.settings = data.setting as ISettings
        }),
        (s) => (self.loadingStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to load settings')
      )
    ),

    fetchCategories: once(
      createAxiosAction(
        flow(function*() {
          const { data } = (yield axios.get(`/public/categories?${allQuery}`)) as AxiosResponse<{
            categories: ICategory[]
          }>
          self.categories = data.categories.sort((a, b) => a.name.localeCompare(b.name))
        }),
        (s) => (self.categoriesStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to load categories')
      )
    ),
  }))

  .actions(() => {
    const integrations = flow(function*(sponsorId: string) {
      yield axios.post(`/attendee/integrations?sponsorId=${sponsorId}`) as Promise<any>
    })
    return { integrations }
  })
