import Categories from 'components/Categories'
import Footer from 'components/Footer'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useRootData } from 'stores'
import styled from 'styled-components/macro'

const CategoriesWrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto 30px;
`

export default observer(function CategoriesPage() {
  const { fetchFullSponsors, fullSponsorsCategories, fullSponsorsLoadingStatus } = useRootData(
    (store) => store.sponsorsStore
  )
  const categories = fullSponsorsCategories()

  useEffect(() => {
    fetchFullSponsors()
    // eslint-disable-next-line
  }, [])

  return (
    <Footer>
      <ContentLoader loadingStatus={fullSponsorsLoadingStatus()}>
        {() => (
          <>
            <CategoriesWrapper>
              <Categories categories={categories} />
            </CategoriesWrapper>
          </>
        )}
      </ContentLoader>
    </Footer>
  )
})
