import Chat from 'components/chat/Controller'
import Header from 'components/Header'
import SignalR from 'components/SignalR'
import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'
import VideoController from './VideoController'

const Container = styled.div`
  margin: auto;
`

const PageContent = styled.div`
  padding-top: 60px;
  display: flex;
  min-height: 100vh;
  flex-grow: 1;
  flex-direction: column;

  ${tablet} {
    padding-top: 50px;
  }
`

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <VideoController>
      <Container>
        <Helmet>
          <title>Virtual Event</title>
        </Helmet>
        <Header />
        <PageContent>
          {children}
          <Chat />
        </PageContent>
        <SignalR />
      </Container>
    </VideoController>
  )
}
