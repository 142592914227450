import React, { memo, useEffect, useRef } from 'react'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components/macro'
import * as THREE from 'three'
import { traverseMaterials, Viewer } from 'utils/GltfViewer'

interface ILayoutBanner {
  name: string
  id: number
}

interface ILayout {
  url: string
  banners: ILayoutBanner[]
}

export const config = {
  boothLayout1: {
    banners: [
      { name: 'BANNER_LEFT', id: 1 },
      { name: 'BANNER_RIGHT', id: 2 },
      { name: 'BANNER_DESK', id: 3 },
      { name: 'BANNER_DISPLAY', id: 4 },
      { name: 'BANNER_TOP', id: 5 },
    ],
    url: '/3d/A_LARGE.glb',
  },
  boothLayout2: {
    banners: [
      { name: 'BANNER_LEFT001', id: 1 },
      { name: 'BANNER_RIGHT001', id: 2 },
      { name: 'BANNER_DESK', id: 3 },
      { name: 'BANNER_DISPLAY001', id: 4 },
      { name: 'BANNER_TOP', id: 5 },
    ],
    url: '/3d/B_LARGE.glb',
  },
  boothLayout3: {
    banners: [
      { name: 'BANNER_LEFT', id: 1 },
      { name: 'BANNER_RIGHT', id: 2 },
      { name: 'BANNER_DESK', id: 3 },
      { name: 'BANNER_DISPLAY', id: 4 },
    ],
    url: '/3d/B_SMALL.glb',
  },
  boothLayout4: {
    banners: [
      { name: 'BANNER_LEFT', id: 1 },
      { name: 'BANNER_RIGHT', id: 2 },
      { name: 'BANNER_DESK', id: 3 },
      { name: 'BANNER_DISPLAY', id: 4 },
    ],
    url: '/3d/A_SMALL.glb',
  },
} as { [key: string]: ILayout }

export const getLayout = (name: string) => config[name] || config.boothLayout1

const addBanner = (placeholder: THREE.Object3D, texture: THREE.Texture) => {
  ;(placeholder as THREE.Mesh).material = new THREE.MeshBasicMaterial({
    color: 0xffffff,
    map: texture,
    reflectivity: 0,
    transparent: true,
  })
}

export const renderSponsorBooth = (sponsor: IFullSponsor, booth: THREE.Object3D) => {
  const byId: { [key: number]: string } = {}
  for (const b of sponsor.virtualBanners) {
    byId[b.bannerId] = b.imageUrl
  }

  const layout = getLayout(sponsor.boothLayout)

  for (const banner of layout.banners) {
    const placeholder = booth.getObjectByName(banner.name)

    const textureLoader = new THREE.TextureLoader()
    const texture = textureLoader.load(
      (byId[banner.id] || '/323x181_right.jpg').replace('.svg', '.jpg') // handle this in other way
    )
    texture.anisotropy = 16
    texture.flipY = false

    if (placeholder) {
      addBanner(placeholder, texture)
    }
  }

  const blackColor = (sponsor.layersStyles[0] || {}).color || '000'
  const frontColor = (sponsor.layersStyles[1] || {}).color || '456dde'

  traverseMaterials(booth, (mat: any) => {
    if (mat.name === 'BLUE') {
      mat.color.set(new THREE.Color('#' + blackColor))
    }
    if (mat.name === 'BLACK') {
      mat.color.set(new THREE.Color('#' + frontColor))
    }
  })

  return booth
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: grab;

  canvas {
    outline: none;
  }
`

export function Booth3D({ sponsor }: { sponsor: IFullSponsor }) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const el = ref.current
    if (!el) {
      return
    }

    const options = {
      cameraPosition: [0, 0, 6],
      preset: '',
    }

    const layout = getLayout(sponsor.boothLayout)

    const viewer = new Viewer(el, options)
    viewer
      .load(layout.url)
      .then((gltf) => {
        const booth = gltf.scene as THREE.Object3D
        renderSponsorBooth(sponsor, booth)
      })
      .catch((err) => console.error(err))
    // eslint-disable-next-line
  }, [])

  return <Wrapper ref={ref} />
}

export default memo(Booth3D)
