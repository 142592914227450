import { createGlobalStyle, css } from 'styled-components/macro'
import reset from 'styled-reset'
import { tablet } from 'utils/responsive'

const fonts = css`
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
      url('/fonts/lato-v16-latin-regular.woff2') format('woff2'),
      url('/fonts/lato-v16-latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'),
      url('/fonts/lato-v16-latin-700.woff2') format('woff2'),
      url('/fonts/lato-v16-latin-700.woff') format('woff');
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 900;
    src: local('Lato Black'), local('Lato-Black'),
      url('/fonts/lato-v16-latin-900.woff2') format('woff2'),
      url('/fonts/lato-v16-latin-900.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), local('Poppins-Light'),
      url('/fonts/poppins-v12-latin-300.woff2') format('woff2'),
      url('/fonts/poppins-v12-latin-300.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins Regular'), local('Poppins-Regular'),
      url('/fonts/poppins-v12-latin-regular.woff2') format('woff2'),
      url('/fonts/poppins-v12-latin-regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
      url('/fonts/poppins-v12-latin-600.woff2') format('woff2'),
      url('/fonts/poppins-v12-latin-600.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
      url('/fonts/poppins-v12-latin-800.woff2') format('woff2'),
      url('/fonts/poppins-v12-latin-800.woff') format('woff');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins Black'), local('Poppins-Black'),
      url('/fonts/poppins-v12-latin-900.woff2') format('woff2'),
      url('/fonts/poppins-v12-latin-900.woff') format('woff');
  }
`

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fonts}

  * {
    box-sizing: border-box;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${tablet} {
      margin-left: 0;
    }
  }

  body {
    margin: 0;
    overflow: auto;
    min-height: 100vh;
    color: #333;
  }

  body, button, input, select, textarea {
    font-family: 'Lato', -apple-system,
      BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans',  'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  h1, h2 {
    font-family: 'Poppins',-apple-system,
      BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans',  'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-weight: 900;
  }

  h1 {
    font-size: 36px;

    ${tablet} {
      font-size: 24px;
    }
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;

    /* customizable */
    transition: all 0.15s ease;
    padding: 6px;
    color: #cecece;
    border-radius: 6px;
    font-size: 12px;
    box-shadow: 2px 2px 1px silver;
  }

  [data-tooltip]:hover:before {
      /* needed - do not touch */
      opacity: 1;

      /* customizable */
      background: #4f5255;
      margin-top: 2px;
      margin-left: -30px;
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
      pointer-events: none;
  }

  .react-toast-notifications__container {
    top: 60px !important;
    max-height: calc(100% - 60px) !important;
  }
`

export default GlobalStyle
