import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { IFullSponsor } from 'stores/sponsors'
import styled from 'styled-components'

export const CompanyCard = memo(({ sponsor }: { sponsor: IFullSponsor }) => (
  <CompanyWrapper>
    <Logo img={sponsor.logoUrl || sponsor.logoWhiteUrl} />
    <CompanyDetails>
      <SponsorTitle to={`/sponsor/${sponsor.id}/`}>{sponsor.company}</SponsorTitle>
      <span>
        Categories:
        {sponsor.sponsorTags.map((tag) => (
          <Category key={tag.id} to={`/category/${tag.id}`}>
            {tag.displayName}
          </Category>
        ))}
      </span>
    </CompanyDetails>
  </CompanyWrapper>
))

export default CompanyCard

const CompanyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 10px;
  margin-bottom: 20px;
`

const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const Category = styled(Link)`
  text-decoration: none;
  color: #456dde;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`

const Logo = styled.div<{ img: string }>`
  flex-shrink: 0;
  width: 70px;
  height: 30px;
  margin-right: 15px;
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background: #ccc;
    z-index: -1;
  }
`

const SponsorTitle = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  margin-bottom: 10px;

  &:hover {
    color: #000;
  }
`
