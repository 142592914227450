import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { File, Mail, Play, PlusCircle, Save, Search as SearchIcon, X, XCircle } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { useRootData } from 'stores'
import { ISwagItem } from 'stores/swagbag'
import styled from 'styled-components/macro'
import niceShadow from 'utils/niceShadow'
import { tablet } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'
import blinkIcon from '../utils/blinkIcon'
import { ContentLoader, Loader } from './Loader'

interface IGoodyBagProps {
  closeAction: () => void
}

interface IItemProps {
  deleteItem: (id: string) => void
  item: ISwagItem
  emailContent: (id: string, documentType: string, title: string, url: string) => void
  emailStatus: LoadingStatus
  justSent: boolean
}

const Item = ({ item, deleteItem, emailContent, emailStatus, justSent }: IItemProps) => {
  return (
    <ItemWrapper>
      <ItemIcon>{item.documentType === 'Document' ? <File /> : <Play />}</ItemIcon>
      <ItemTitle>
        <h3>{item.title}</h3>
      </ItemTitle>
      <Buttons>
        <a href={item.url} rel="noopener noreferrer" target="_blank">
          <Save color="#3c62d9" />
        </a>
        {emailStatus === LoadingStatus.pending ? (
          <MailLoader inline color="#00f" />
        ) : (
          <Mail
            color="#3c62d9"
            className={`${emailStatus === LoadingStatus.error ? 'disabled' : ''} ${
              justSent ? 'justSent' : ''
            }`}
            onClick={() => emailContent(item.id, item.documentType, item.title, item.url)}
          />
        )}
        <XCircle onClick={() => deleteItem(item.id)} color="#e20c0c" />
      </Buttons>
    </ItemWrapper>
  )
}

export default observer(({ closeAction }: IGoodyBagProps) => {
  const {
    items,
    loadingStatus,
    fetchItems,
    deleteItem,
    emailContent,
    justSentFlags,
    emailContentStatuses,
  } = useRootData((store) => store.swagbagStore)
  const emailStatusesById = items.reduce((res, val) => {
    res[val.id] = emailContentStatuses.get(val.id) || LoadingStatus.not_loaded
    return res
  }, {} as { [key: string]: LoadingStatus })

  const sentFlagsById = items.reduce((res, val) => {
    res[val.id] =
      justSentFlags.get(val.id) === undefined ? false : (justSentFlags.get(val.id) as boolean)
    return res
  }, {} as { [key: string]: boolean })

  const [swagName, setSwagName] = useState('')

  useEffect(() => {
    if (loadingStatus !== LoadingStatus.success) {
      fetchItems()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <GoodyBagWrapper>
      <Title>Goody pack</Title>
      <CloseIcon color="#000" onClick={closeAction} />
      <ContentLoader loadingStatus={loadingStatus}>
        {() => (
          <>
            <Search>
              <input
                value={swagName}
                onChange={(e) => setSwagName(e.target.value)}
                type="text"
                placeholder="Search your swag"
                name="search_swag"
              />
              <SearchIcon size="18" />
            </Search>
            <Items>
              {items
                .filter((item) => item.title.indexOf(swagName) !== -1)
                .map((item) => (
                  <Item
                    {...{
                      deleteItem,
                      emailContent,
                      emailStatus: emailStatusesById[item.id],
                      item,
                      justSent: sentFlagsById[item.id],
                      key: item.id,
                    }}
                  />
                ))}
            </Items>
          </>
        )}
      </ContentLoader>

      <AddButton to="/search/?c=documents&c=videos">
        <PlusCircle size="40" />
      </AddButton>
    </GoodyBagWrapper>
  )
})

const GoodyBagWrapper = styled.div`
  ${niceShadow};
  width: 40vw;
  max-width: 700px;
  background-color: #fff;
  border-radius: 2px;
  position: absolute;
  top: calc(100% - 10px);
  right: 10px;
  z-index: 1;
  padding: 25px;
  white-space: normal;
  overflow: auto;
  max-height: 80vh;

  ${tablet} {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: auto;
    border-radius: 0;
    max-height: none;
  }
`

const Buttons = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: auto;

  ${tablet} {
    margin-top: 10px;
    width: 100%;
  }

  svg {
    margin: 0 0 0 10px;
    opacity: 0.5;
    transition: opacity 0.15s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  svg.justSent {
    ${blinkIcon('green')};
  }

  svg.disabled {
    stroke: lightgrey;
    pointer-events: none;
    cursor: default;
  }
`

const Title = styled.h2`
  height: 30px;
  font-size: 20px;
  font-weight: 900;
  color: #333;
`

const CloseIcon = styled(X)`
  display: none;

  ${tablet} {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s;
  }

  &:hover {
    opacity: 1;
  }
`

const Search = (() => {
  const margin = 18
  const height = 40

  return styled.div`
    width: 100%;
    height: ${height}px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: ${margin}px;
    margin-bottom: ${margin}px;
    position: relative;

    input {
      width: 85%;
      height: 100%;
      border: none;
      outline: none;
      margin-left: 10px;
      line-height: 14px;
    }

    svg {
      position: absolute;
      right: 8px;
      top: 8px;
      color: #a4a4a4;
    }
  `
})()

const Items = styled.div`
  width: 100%;
`

const AddButton = styled(NavLink)`
  display: block;
  margin: auto;
  margin-top: 20px;
  max-height: 40px;
  color: #3c62d9;
  text-align: center;
  cursor: pointer;
  transition: color 0.15s;

  &:hover {
    color: #0e26b9;
  }
`

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;

  &:last-child {
    border: none;
  }
`

const ItemIcon = styled.div`
  svg {
    color: #666;
  }
`

const ItemTitle = styled.div`
  flex-grow: 1;
  height: 100%;
  color: #1e3a8d;
  font-size: 17px;
  font-weight: bold;
  margin: 0 10px;

  h3 {
    line-height: 26px;
  }

  h4 {
    font-size: 12px;
    font-weight: 400;
    color: #333;
  }
`

const MailLoader = styled(Loader)`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`
