import { AxiosResponse } from 'axios'
import { flow, getParent, types } from 'mobx-state-tree'
import { ISponsorContentItem } from 'stores/sponsors'
import axios from 'utils/axios'
import { createAxiosAction, IRootStore, LoadingStatus, LoadingStatusType } from 'utils/store'

export type ISwagItem = ISponsorContentItem

let justAddedTimeout: number = 0
const justSentTimeouts: { [key: string]: number } = {}

export default types
  .model('AgendaStore', {
    actionStatus: LoadingStatusType,
    emailContentStatuses: types.map(LoadingStatusType),
    justAdded: types.optional(types.boolean, false),
    justSentFlags: types.map(types.boolean),
    loadingStatus: LoadingStatusType,

    items: types.frozen<ISwagItem[]>([]),
  })

  .actions((self) => ({
    contains: (id: string) => self.items.find((i) => i.id === id) !== undefined,
    setJustAdded: (value: boolean) => (self.justAdded = value),
    setJustSent: (id: string, value: boolean) => self.justSentFlags.set(id, value),
  }))

  .actions((self) => ({
    fetchItems: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get('/attendee/goody-bag')) as AxiosResponse<ISwagItem[]>
        self.items = data || [] // sometimes return null
      }),
      (s) => (self.loadingStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to fetch swag items')
    ),

    addItem: createAxiosAction(
      flow(function*(item: ISwagItem) {
        yield axios.post('/attendee/goody-bag', item)
        self.items = self.items.concat(item)
        self.justAdded = true
        clearTimeout(justAddedTimeout)
        justAddedTimeout = setTimeout(() => self.setJustAdded(false), 1200)
      }),
      (s) => (self.actionStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to add swag item')
    ),

    deleteItem: createAxiosAction(
      flow(function*(id: string) {
        yield axios.delete(`/attendee/goody-bag?id=${id}`)
        self.items = self.items.filter((item) => item.id !== id)
      }),
      (s) => (self.actionStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to delete swag item')
    ),
  }))

  .actions((self) => ({
    emailContent: createAxiosAction(
      flow(function*(id: string, documentType: string, title: string, url: string) {
        yield axios.post(`/attendee/goody-bag/send`, {
          documentType,
          title,
          url,
        })
      }),
      (s, [id]) => {
        self.emailContentStatuses.set(id, s)
        if (s === LoadingStatus.success) {
          self.justSentFlags.set(id, true)
          clearTimeout(justSentTimeouts[id])
          justSentTimeouts[id] = setTimeout(() => self.setJustSent(id, false), 1200)
          getParent<IRootStore>(self).showMessage('Email sent')
        }
      },
      () => getParent<IRootStore>(self).showError('Failed to email content')
    ),
  }))
