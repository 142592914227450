import PlayIcon from 'assets/icons/play.svg'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import { useRootData } from 'stores'
import { ISessionItem } from 'stores/agenda'
import styled from 'styled-components/macro'
import { dayjs } from 'utils/dates'
import { centerShadow } from 'utils/niceShadow'
import { mobile } from 'utils/responsive'
import { LoadingStatus } from 'utils/store'

export function LiveAgenda({ sessions }: { sessions: ISessionItem[] }) {
  const liveSessions = useMemo(() => {
    const currentTime = dayjs()
    return sessions
      .filter((session) => !(currentTime > dayjs(session.endTime)))
      .sort((sessionA, sessionB) => dayjs(sessionA.startTime).diff(sessionB.startTime))
      .map((session) => {
        const startTime = dayjs(session.startTime)
        const endTime = dayjs(session.endTime)
        return {
          ...session,
          isLive: startTime < currentTime && currentTime < endTime,
        }
      })
  }, [sessions])

  return (
    <>
      <SessionsWrapper>
        {liveSessions.slice(0, 5).map((session) => {
          return (
            <SessionBlock key={session.id}>
              <ImageWrapper>
                <PlayButton />
                <Placeholder />
              </ImageWrapper>

              <Details>
                <SessionName title={session.title}>{session.title}</SessionName>

                {session.isLive ? (
                  <LiveLabel>LIVE</LiveLabel>
                ) : (
                  <SessionStart>{dayjs(session.startTime).fromNow()}</SessionStart>
                )}

                <Speakers>
                  {session.speakers.map((speaker) => speaker.name.trim()).join(', ')}
                </Speakers>
              </Details>
            </SessionBlock>
          )
        })}
      </SessionsWrapper>
    </>
  )
}

export default observer(() => {
  const { sessions, fetchSessionsStatus, fetchSessions } = useRootData((store) => store.agendaStore)

  useEffect(() => {
    if (fetchSessionsStatus !== LoadingStatus.success) {
      fetchSessions()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <LiveAgendaWrapper>
      <ContentLoader loadingStatus={fetchSessionsStatus}>
        {() => <LiveAgenda sessions={sessions} />}
      </ContentLoader>
    </LiveAgendaWrapper>
  )
})

const LiveAgendaWrapper = styled.div`
  ${centerShadow}
  position: fixed;
  top: calc(100% - 50px);
  width: 210px;
  left: 10px;
  background: white;
  transition: transform 0.2s ease-out;
  z-index: 1;

  &:hover {
    transform: translateY(-200px);
  }
`

const Details = styled.div`
  width: 150px;
  height: 100%;

  ${mobile} {
    width: 140px;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 5px;
    background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
    z-index: 1;
  }
`

const PlayButton = styled(PlayIcon)`
  width: 24px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;

  ${mobile} {
    width: 20px;
    height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
`

const SessionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SessionBlock = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  padding: 0 5px;
  overflow: hidden;
  align-items: center;

  ${mobile} {
    height: 50px;
    margin-left: 10px;
  }
`

const SessionStart = styled.p`
  color: #666;
  margin: 5px 0;
  font-size: 12px;
`

const Speakers = styled.p`
  line-height: 20px;
  font-size: 12px;
`

const SessionName = styled.h2`
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Placeholder = styled.div`
  width: 40px;
  height: 40px;
  background: red;
`

const ImageWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
`

const LiveLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #ec550c;
  background: #fff;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 8px;
  border-radius: 5px;
`
