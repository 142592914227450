import PlayIcon from 'assets/icons/play.svg'
import SpeakerCard from 'components/SpeakerCard'
import React from 'react'
import { X } from 'react-feather'
import { ISessionItem } from 'stores/agenda'
import styled from 'styled-components/macro'
import niceShadow from 'utils/niceShadow'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'
import { useSetVideo } from './VideoController'

interface ISessionPopupProps {
  session: ISessionItem
  top?: number
  onClose: () => any
}

const SessionPopup = ({ session, onClose, top = 200 }: ISessionPopupProps) => {
  const setVideo = useSetVideo()

  usePreventBodyScroll(true)

  return (
    <Popup>
      <Overlay onClick={onClose} />
      <PopupBody>
        <h2>{session.title}</h2>
        {session.streamVideoUrl && (
          <VideoButton onClick={() => setVideo(session.streamVideoUrl)}>
            <PlayIcon />
            Open stream
          </VideoButton>
        )}
        <CloseIcon size="24" onClick={onClose} />
        <Description dangerouslySetInnerHTML={{ __html: session.fullDescription }} />
        <h2>Speakers</h2>
        {session.speakers &&
          session.speakers.map((speaker) => <SpeakerCard key={speaker.id} speaker={speaker} />)}
      </PopupBody>
    </Popup>
  )
}

export default SessionPopup

const Popup = styled.div`
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  transform: translate(0);
`

const PopupBody = styled.div`
  ${niceShadow}
  position: relative;
  width: 50%;
  background: white;
  padding: 30px 20px;
  z-index: 300;
  border-radius: 2px;
  text-align: left;

  ${tablet} {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 25px;
    width: 100%;
    transform: translate(0);
  }

  h2 {
    width: 90%;
    font-size: 20px;
    font-weight: 700;
  }
`

const CloseIcon = styled(X)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.15s;

  &:hover {
    opacity: 1;
  }
`

const Description = styled.div`
  margin: 20px 0;
`

const VideoButton = styled.a`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 14px;
  color: #456dde;
  text-decoration: none;
  width: 100%;
  margin-top: 10px;
  padding-right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    fill: #456dde;
    width: auto;
    height: 40px;
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`
