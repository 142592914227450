import Background from 'components/RegistrationBackground'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

const eventDate = new Date('2020-10-20T08:00:00Z')
const getDiff = (date: Date) => eventDate.getTime() - Date.now()
const zeroPad = (n: number) => (n > 9 ? n : '0' + n)

export default () => {
  const [diff, setDiff] = useState(getDiff(eventDate))
  const interval = useRef<any>() as MutableRefObject<any>

  useEffect(() => {
    interval.current = setInterval(() => setDiff(getDiff(eventDate)))
    return () => clearInterval(interval.current)
  }, [])

  const days = zeroPad(Math.floor(diff / 1000 / 60 / 60 / 24))
  const hours = zeroPad(Math.floor(diff / 1000 / 60 / 60) % 24)
  const minutes = zeroPad(Math.floor(diff / 1000 / 60) % 60)
  const seconds = zeroPad(Math.floor(diff / 1000) % 60)

  return (
    <Background>
      <Wrapper>
        <h1>See you soon!</h1>
        <CountDownWrapper>
          <CountDownCard>
            <CountDownNumber>{days}</CountDownNumber>
            <CountDownTitle>days</CountDownTitle>
          </CountDownCard>
          <CountDownCard>
            <CountDownNumber>{hours}</CountDownNumber>
            <CountDownTitle>hours</CountDownTitle>
          </CountDownCard>
          <CountDownCard>
            <CountDownNumber>{minutes}</CountDownNumber>
            <CountDownTitle>minutes</CountDownTitle>
          </CountDownCard>
          <CountDownCard>
            <CountDownNumber>{seconds}</CountDownNumber>
            <CountDownTitle>seconds</CountDownTitle>
          </CountDownCard>
        </CountDownWrapper>
      </Wrapper>
    </Background>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;

  h1 {
    width: 100%;
  }
`

const CountDownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`

const CountDownCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
  width: 70px;
`

const CountDownNumber = styled.span`
  font-size: 35px;
`

const CountDownTitle = styled.span`
  text-align: center;
  width: 100%;
  margin-top: 15px;
  padding-top: 15px;
  font-size: 16px;
  border-top: solid 1px rgba(255, 255, 255, 0.3);
  opacity: 0.6;
`
