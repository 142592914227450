import Agenda from 'components/Agenda'
import Footer from 'components/Footer'
import { ContentLoader } from 'components/Loader'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useRootData } from 'stores'
import { LoadingStatus } from 'utils/store'

export default observer(function Agendas() {
  const { sessions, fetchSessionsStatus, fetchSessions } = useRootData((store) => store.agendaStore)

  useEffect(() => {
    if (fetchSessionsStatus !== LoadingStatus.success) {
      fetchSessions()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Footer>
      <ContentLoader loadingStatus={fetchSessionsStatus}>
        {() => <Agenda sessions={sessions} />}
      </ContentLoader>
    </Footer>
  )
})
