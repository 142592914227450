import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  const isMobile = width < 768
  return {
    height,
    isMobile,
    width,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

interface IDimensionObject {
  width: number
  height: number
  top: number
  left: number
  right: number
  bottom: number
}

type UseDimensionsHook = [(node: HTMLElement | null) => void, IDimensionObject, HTMLElement | null]

interface IUseDimensionsArgs {
  liveMeasure?: boolean
}

function getDimensionObject(node: HTMLElement): IDimensionObject {
  const rect = node.getBoundingClientRect()

  return {
    bottom: rect.bottom,
    height: rect.height,
    left: rect.left,
    right: rect.right,
    top: rect.top,
    width: rect.width,
  }
}

export function useNodeDimensions({
  liveMeasure = true,
}: IUseDimensionsArgs = {}): UseDimensionsHook {
  const [dimensions, setDimensions] = useState({})
  const [node, setNode] = useState<HTMLElement | null>(null)

  const ref = useCallback((n) => {
    setNode(n)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)))
      measure()

      if (liveMeasure) {
        window.addEventListener('resize', measure)
        window.addEventListener('scroll', measure)

        return () => {
          window.removeEventListener('resize', measure)
          window.removeEventListener('scroll', measure)
        }
      }
    }
    // eslint-disable-next-line
  }, [node])

  return [ref, dimensions as IDimensionObject, node]
}
