import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Background = ({ children }: { children: ReactNode }) => (
  <RegistrationBackground>
    {/* <img className="image-bottom" src="/registration-design/full_bottom.png" />
    <img className="image-logo" src="/registration-design/logo.png" />
    <img className="image-right" src="/registration-design/right-text.png" /> */}
    {children}
  </RegistrationBackground>
)

export default Background

const RegistrationBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #192d54;
  color: white;
  position: relative;

  .image-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: 50%;
  }

  .image-right {
    position: absolute;
    top: 10px;
    right: 10px;
    max-width: 40%;
  }

  .image-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  a {
    color: white;
  }
`
