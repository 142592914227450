import {
  IAbstractionsContractsModelsSponsorContentBase,
  IAbstractionsContractsQueriesAttendeeTypesAttendeeTypesResponse,
  IAbstractionsContractsQueriesAttendeeTypesAttendeeTypesResponseAttendeeType,
  IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponse,
  IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponseSponsorFloor,
  IAbstractionsContractsQueriesSponsorsAttendeeSponsorsResponseSponsor,
  IAbstractionsContractsQueriesSponsorsAttendeeSponsorsResponseVirtualBanner,
  IAbstractionsContractsQueriesSponsorsPublishedSponsorsResponse,
  IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponse,
  IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponseSponsorType,
  IAbstractionsContractsQueriesTagsTagsWithPaginationResponse,
  IAbstractionsContractsQueriesTagsTagsWithPaginationResponseTag,
  IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponseVipAttendee,
} from 'api-types'
import { AxiosResponse } from 'axios'
import once from 'lodash/once'
import { flow, getParent, types } from 'mobx-state-tree'
import axios from 'utils/axios'
import { allQuery } from 'utils/filter'
import {
  combineLoadingStatus,
  createAxiosAction,
  IRootStore,
  LoadingStatus,
  LoadingStatusType,
} from 'utils/store'

export type IAllSponsorsResponse = IAbstractionsContractsQueriesSponsorsPublishedSponsorsResponse
export type IAllSponsorTypesResponse = IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponse
export type IAllSponsorTiersResponse = IAbstractionsContractsQueriesAttendeeTypesAttendeeTypesResponse
export type IAllSponsorFloorsResponse = IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponse
export type IAllSponsorTagsResponse = IAbstractionsContractsQueriesTagsTagsWithPaginationResponse

export type ISponsorType = IAbstractionsContractsQueriesSponsorTypesSponsorTypesResponseSponsorType
export type ISponsorTier = IAbstractionsContractsQueriesAttendeeTypesAttendeeTypesResponseAttendeeType
export type ISponsorTag = IAbstractionsContractsQueriesTagsTagsWithPaginationResponseTag
export type ISponsorFloor = IAbstractionsContractsQueriesSponsorFloorsSponsorFloorsResponseSponsorFloor

export interface ISponsorContentItem extends IAbstractionsContractsModelsSponsorContentBase {
  [key: string]: any
}

export interface ISponsorBanner
  extends Omit<
    IAbstractionsContractsQueriesSponsorsAttendeeSponsorsResponseVirtualBanner,
    'content'
  > {
  content?: ISponsorContentItem
}

export type IRepresentative = IAbstractionsContractsQueriesVipAttendeesVipAttendeesResponseVipAttendee

export interface ISponsor
  extends IAbstractionsContractsQueriesSponsorsAttendeeSponsorsResponseSponsor {
  representatives: IRepresentative[]
  content: ISponsorContentItem[]
  banners: ISponsorBanner[]
  chatStream: string
}

export interface IFullSponsor extends ISponsor {
  sponsorTiers: ISponsorTier[]
  sponsorTags: ISponsorTag[]
}

export interface ICategoryWithSponsors extends ISponsorTag {
  sponsors: IFullSponsor[]
}

export interface IFloorWithSponsors extends ISponsorFloor {
  sponsors: IFullSponsor[]
}

export const getVideos = (sponsor: ISponsor) =>
  (sponsor.content || []).filter((item) => item.documentType === 'Video' && item.url)

export const getDocuments = (sponsor: ISponsor) =>
  (sponsor.content || []).filter((item) => item.documentType === 'Document' && item.url)

export const getImages = (sponsor: ISponsor) => sponsor.logoUrl || sponsor.logoWhiteUrl

export default types
  .model('AppStore', {
    loadingSponsorsStatus: LoadingStatusType,
    sponsorsById: types.map(types.frozen<ISponsor>()),
    sponsorsList: types.frozen<ISponsor[]>([]),

    fetchSponsorDetailsStatus: LoadingStatusType,
    sponsorFloors: types.frozen<ISponsorFloor[]>([]),
    sponsorTags: types.frozen<ISponsorTag[]>([]), // categories
    sponsorTiers: types.frozen<ISponsorTier[]>([]),
    sponsorTypes: types.frozen<ISponsorType[]>([]),
  })

  .views((self) => ({
    visitedSponsors: () =>
      (getParent<IRootStore>(self).appStore.currentUser.metadataObject.visitedSponsors ||
        []) as string[],

    assignedRepresentatives: () =>
      (getParent<IRootStore>(self).appStore.currentUser.metadataObject.assignedRepresentatives ||
        {}) as { [key: string]: string },

    fullSponsorsLoadingStatus: () =>
      combineLoadingStatus([self.fetchSponsorDetailsStatus, self.loadingSponsorsStatus]),

    floor: (id: string) => self.sponsorFloors.find((floor) => id === floor.id),
    sponsor: (id: string) => self.sponsorsById.get(id),
    tag: (id: string) => self.sponsorTags.find((tag) => id === tag.id),
    tier: (id: string) => self.sponsorTiers.find((tier) => id === tier.id),

    getSponsorByRepresentativeChatId: (id: number) =>
      self.sponsorsList.find((s) => (s.representatives || []).find((r) => r.chatUserId === id)),

    getSponsorByRepresentativeId: (id: string) =>
      self.sponsorsList.find((s) => (s.representatives || []).find((r) => r.id === id)),

    getSponsorByChatName: (name: string) => self.sponsorsList.find((s) => s.chatStream === name),

    documents: () =>
      self.sponsorsList
        .map(getDocuments)
        .flat()
        .filter(
          (value, index, arr) => arr.findIndex((element) => element.id === value.id) === index
        ),
    images: () => self.sponsorsList.map(getImages),
    videos: () =>
      self.sponsorsList
        .map(getVideos)
        .flat()
        .filter(
          (value, index, arr) => arr.findIndex((element) => element.id === value.id) === index
        ),
  }))

  .views((self) => ({
    representativeByUserId: (id: number) =>
      (self.getSponsorByRepresentativeChatId(id)?.representatives || []).find(
        (r) => r.chatUserId === id
      ),

    assignedRepresentativeId: (id: string) => {
      const nowAssigned = self.assignedRepresentatives()
      if (nowAssigned[id]) {
        return nowAssigned[id]
      }

      const sponsor = self.sponsor(id)
      const representatives = (sponsor?.representatives || []).filter((r) => r.chatUserId)
      return representatives.length > 0 ? representatives[0].id : undefined
    },

    fullSponsor: (id: string) => {
      const sponsor = self.sponsor(id)
      if (!sponsor || self.fullSponsorsLoadingStatus() !== LoadingStatus.success) {
        return undefined
      }

      return {
        ...sponsor,
        sponsorTags: sponsor.tags.map((tid) => self.tag(tid)).filter((t) => t) as ISponsorTag[],
        sponsorTiers: sponsor.sponsorTiersIds
          .map((tid) => self.tier(tid))
          .filter((t) => t) as ISponsorTier[],
      } as IFullSponsor
    },
  }))

  .views((self) => ({
    assignedRepresentative: (id: string) => {
      const repId = self.assignedRepresentativeId(id)
      const sponsor = self.sponsor(id)
      const representative = (sponsor?.representatives || []).find((r) => r.id === repId)
      return representative
    },
    fullSponsors: () => {
      return self.sponsorsList.map((s) => self.fullSponsor(s.id)).filter((s) => s) as IFullSponsor[]
    },
  }))

  .views((self) => ({
    fullSponsorsSections: () => {
      const sectionsOrder = getParent<IRootStore>(self).settingsStore.sponsorsSections()

      const typesById: { [key: string]: ISponsorType } = {}
      for (const type of self.sponsorTypes) {
        typesById[type.id] = type
      }

      return sectionsOrder.map((section) => {
        const type = typesById[section.id]
        return {
          ...type,
          sponsors: section.sponsors
            .map(({ id }) => self.fullSponsor(id))
            .filter((s) => s) as IFullSponsor[],
        }
      })
    },

    fullSponsorsCategories: () => {
      const byCat: { [key: string]: IFullSponsor[] } = {}
      for (const sponsor of self.fullSponsors()) {
        for (const cat of sponsor.sponsorTags) {
          byCat[cat.id] = byCat[cat.id] || []
          byCat[cat.id].push(sponsor)
        }
      }

      return self.sponsorTags
        .map((cat) => ({
          ...cat,
          sponsors: byCat[cat.id] || [],
        }))
        .filter(({ sponsors }) => sponsors.length > 0)
        .sort((a, b) => b.sponsors.length - a.sponsors.length) as ICategoryWithSponsors[]
    },

    fullSponsorsFloors: () => {
      return self.sponsorFloors
        .map((floor) => ({
          ...floor,
          sponsors: floor.sponsorsOrders
            .sort((a, b) => a.order - b.order)
            .map(({ id }) => self.fullSponsor(id)!)
            .filter((s) => s),
        }))
        .filter(({ sponsors }) => sponsors.length > 0)
    },
  }))

  .views((self) => ({
    fullCategory: (id: string) => {
      return self.fullSponsorsCategories().find((s) => s.id === id)
    },
    fullFloor: (id: string) => {
      return self.fullSponsorsFloors().find((s) => s.id === id)
    },
  }))

  .actions((self) => ({
    getAllSponsors: createAxiosAction(
      flow(function*() {
        const { data } = (yield axios.get(
          `/attendee/media-partners/?${allQuery}`
        )) as AxiosResponse<{
          sponsors: ISponsor[]
        }>
        self.sponsorsList = data.sponsors
        for (const sponsor of self.sponsorsList) {
          self.sponsorsById.set(sponsor.id, sponsor)
        }
      }),
      (s) => (self.loadingSponsorsStatus = s),
      () => getParent<IRootStore>(self).showError('Failed to load sponsors list')
    ),

    fetchSponsorsDetails: once(
      createAxiosAction(
        flow(function*() {
          const [
            {
              data: { sponsorTypes },
            },
            {
              data: { attendeeTypes },
            },
            {
              data: { sponsorFloors },
            },
            {
              data: { tags },
            },
          ] = (yield Promise.all([
            axios.get(`/public/media-partners-types?${allQuery}`),
            axios.get(`/public/attendee-types?${allQuery}`), // strange name for method with tiers
            axios.get(`/public/media-partners-floors?${allQuery}`),
            axios.get(`/public/tag?${allQuery}`),
          ])) as [
            AxiosResponse<IAllSponsorTypesResponse>,
            AxiosResponse<IAllSponsorTiersResponse>,
            AxiosResponse<IAllSponsorFloorsResponse>,
            AxiosResponse<IAllSponsorTagsResponse>
          ]
          self.sponsorTypes = sponsorTypes
          self.sponsorTiers = attendeeTypes
          self.sponsorFloors = sponsorFloors
          self.sponsorTags = tags
        }),
        (s) => (self.fetchSponsorDetailsStatus = s),
        () => getParent<IRootStore>(self).showError('Failed to fetch sponsors types/tiers/tags')
      )
    ),

    assignRepresentative: (representativeId: string) => {
      const sponsor = self.getSponsorByRepresentativeId(representativeId)
      if (!sponsor) {
        return
      }

      const nowAssigned = self.assignedRepresentatives()
      if (nowAssigned[sponsor.id] !== representativeId) {
        getParent<IRootStore>(self).appStore.updatePartialUserMetadata({
          assignedRepresentatives: {
            ...nowAssigned,
            [sponsor.id]: representativeId,
          },
        })
      }
    },
  }))

  .actions((self) => ({
    fetchFullSponsors: flow(function*() {
      yield Promise.all([
        self.loadingSponsorsStatus !== LoadingStatus.success
          ? self.getAllSponsors()
          : Promise.resolve(),
        self.fetchSponsorsDetails(),
      ])
    }),

    visitSponsor: (id: string) => {
      const visited = self.visitedSponsors()
      const visitedSponsors = !visited.includes(id) ? [...visited, id] : undefined

      if (visitedSponsors) {
        getParent<IRootStore>(self).appStore.updatePartialUserMetadata({ visitedSponsors })
      }
    },
  }))
