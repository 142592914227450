import React, { ReactElement, useState } from 'react'
import VideoPopup from './VideoPopup'

type SetFn = (url: string) => any

export const setVideoContext = React.createContext<SetFn>(() => console.warn('Not initialized?!'))

export const useSetVideo = () => React.useContext(setVideoContext)

export default ({ children }: { children: ReactElement }) => {
  const [currentVideo, setVideo] = useState('')

  return (
    <>
      <setVideoContext.Provider value={setVideo}>{children}</setVideoContext.Provider>
      {currentVideo && <VideoPopup video={currentVideo} onClose={() => setVideo('')} />}
    </>
  )
}
