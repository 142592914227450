import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  width: 100%;
  padding: 100px 20px;
  padding-left: 120px;
  max-width: 1300px;
  margin: auto;
  font-weight: bold;
  position: relative;

  h1 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  h2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 100px;
  }
`

const ErrorPage = () => (
  <Wrapper>
    <h2>:(</h2>
    <h1>404!</h1>
    <p>Page not found!</p>
  </Wrapper>
)

export default ErrorPage
