import ChatSidebar from 'components/chat/ChatSidebar'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronRight, Maximize, MessageCircle, MessageSquare, X } from 'react-feather'
import { useRootData } from 'stores'
import styled, { css } from 'styled-components/macro'
import useWindowDimensions from 'utils/dimensions'
import { centerShadow } from 'utils/niceShadow'
import usePreventBodyScroll from 'utils/preventBodyScroll'
import { tablet } from 'utils/responsive'
import ChannelMessages from './ChannelMessages'
import ChatWindowTitle from './ChatWindowTitle'

export default observer(({ fullPage }: { fullPage?: boolean }) => {
  const {
    showChatWindow,
    setChatWindowVisibility,
    currentPrivateRoomId,
    currentStreamId,
    setCurrentStream,
    setCurrentPrivateRoom,
    forceFullChatExpand,
    setForceFullChatExpand,
    privateRoom,
    ownChatId,
    stream,
  } = useRootData((store) => store.chatStore)
  const currentRoom = privateRoom(currentPrivateRoomId)
  const currentStream = stream(currentStreamId)
  const ownId = ownChatId()

  const {
    getSponsorByRepresentativeChatId,
    getSponsorByChatName,
    assignRepresentative,
  } = useRootData((store) => store.sponsorsStore)
  const sponsor = currentRoom
    ? getSponsorByRepresentativeChatId(currentRoom.user_id)
    : currentStream
    ? getSponsorByChatName(currentStream.name)
    : undefined

  const [expandedSidebar, setExpandedSidebar] = useState(false)

  const { width: viewportWidth } = useWindowDimensions()
  const isMobile = viewportWidth < 1000

  const chatContentRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const isVisibleWindow = !!(showChatWindow() || fullPage)

  const handleChatSidebar = () => {
    setExpandedSidebar(!expandedSidebar)
  }

  usePreventBodyScroll(isVisibleWindow)

  useEffect(() => {
    if (forceFullChatExpand && !isMobile) {
      setExpandedSidebar(true)
    }
  }, [forceFullChatExpand, isMobile])

  return (
    <>
      <ChatWindowWrapper
        ref={wrapperRef}
        className={`${isVisibleWindow ? 'isExpanded' : ''} ${
          expandedSidebar || (fullPage && !isMobile) ? 'withSidebar' : ''
        } ${fullPage ? 'fullChat' : ''}`}
      >
        <ChatWindowHeader>
          {(!fullPage || isMobile) && (
            <ChatWindowChannels onClick={handleChatSidebar}>
              {expandedSidebar ? (
                <ChevronRight color={fullPage ? '#333' : '#fff'} size="18" />
              ) : (
                <MessageSquare color={fullPage ? '#333' : '#fff'} size="18" />
              )}
            </ChatWindowChannels>
          )}
          <ChatWindowTitle
            ownId={ownId}
            currentRoom={currentRoom}
            currentStream={currentStream}
            onSelectRepresentative={(r) => {
              assignRepresentative(r.id)
              setCurrentPrivateRoom(r.chatUserId)
              if (isMobile) {
                setExpandedSidebar(false)
              }
            }}
            setChatWindowVisibility={setChatWindowVisibility}
            sponsor={sponsor}
          />
          <ChatWindowControl
            title={`${isVisibleWindow ? 'Minimize' : 'Maximize'} Chat`}
            onClick={() => {
              setChatWindowVisibility(!isVisibleWindow)
              if (forceFullChatExpand) {
                setForceFullChatExpand(false)
              }
            }}
          >
            {isVisibleWindow ? (
              <X color="#fff" size="18" />
            ) : isMobile ? (
              <MessageCircle color="#fff" size="24" />
            ) : (
              <Maximize color="#fff" size="18" />
            )}
          </ChatWindowControl>
        </ChatWindowHeader>
        <ChatWindowBody>
          <ChatSidebar
            activePrivateRoom={currentPrivateRoomId}
            activeStream={currentStreamId}
            onPrivateRoomSelect={(id) => {
              setCurrentPrivateRoom(id)
              if (isMobile) {
                setExpandedSidebar(false)
              }
            }}
            onStreamSelect={(s) => {
              setCurrentStream(s.stream_id)
              if (isMobile) {
                setExpandedSidebar(false)
              }
            }}
          />
          <ChatContent ref={chatContentRef}>
            {(currentRoom || currentStream) && (
              <ChannelMessages
                isVisibleWindow={isVisibleWindow}
                privateRoom={currentRoom}
                stream={currentStream}
              />
            )}
          </ChatContent>
        </ChatWindowBody>
      </ChatWindowWrapper>
    </>
  )
})

const ChatWindowWrapper = styled.div`
  ${centerShadow};
  overflow: hidden;
  position: fixed;
  z-index: 200;
  right: 20px;
  bottom: 0;
  width: 200px;
  height: 44px;
  transition: width 0.15s;

  &.fullChat {
    transition: none;
    width: 100% !important;
    right: auto !important;
    bottom: auto !important;
    top: 60px !important;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1300px;
    z-index: 1;
    height: calc(100% - 60px) !important;
    flex-grow: 1;

    ${tablet} {
      top: 50px !important;
      height: calc(100% - 50px) !important;
    }
  }

  ${tablet} {
    top: auto;
    bottom: 20px;
    right: 20px;
    width: 50px !important;
    height: 50px !important;
    border-radius: 5px;
    transition: none;
  }

  &.isExpanded {
    top: 60px;
    height: calc(100% - 60px);
    width: 300px;
    right: 0;

    ${tablet} {
      top: auto;
      right: 0;
      bottom: 0;
      width: 100% !important;
      height: calc(100% - 50px) !important;
      border-radius: 0;
    }

    &.withSidebar {
      width: 520px;

      ${tablet} {
        &.isExpanded {
          width: 100% !important;
        }
      }
    }
  }
`

const ChatWindowHeader = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  height: 44px;
  background-color: #3c62d9;

  .fullChat & {
    background: #eee;
  }

  ${tablet} {
    height: 100%;

    .isExpanded & {
      height: 44px;
    }
  }
`

const ChatWindowBody = styled.div`
  overflow: hidden;
  position: absolute;
  top: 44px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  height: 0;
  background-color: #fff;

  .isExpanded & {
    height: auto;
  }
`

const chatControlButton = css`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  appearance: none;
  opacity: 0.75;
  transition: opacity 0.15s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`

const ChatWindowChannels = styled.button`
  ${chatControlButton};
  display: none;

  .isExpanded & {
    display: block;
  }

  svg {
    margin-top: 2px;
  }

  ${tablet} {
    display: none;

    .isExpanded & {
      display: block;
    }
  }
`

const ChatWindowControl = styled.button`
  ${chatControlButton};
  margin: 0 0 0 auto;

  .fullChat & {
    display: none;
  }

  ${tablet} {
    margin: auto;

    .isExpanded & {
      margin: 0 0 0 auto;
    }
  }
`

const ChatContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 300px;
  flex-direction: row;
  margin: 0;
  height: 100%;
`
