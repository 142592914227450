import fuzzy from 'fuzzysort'
import React, { useEffect, useMemo, useState } from 'react'
import { Briefcase, Eye, File, Play, Search as SearchIcon } from 'react-feather'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link, useLocation } from 'react-router-dom'
import { useRootData } from 'stores'
import { ISponsor, ISponsorContentItem } from 'stores/sponsors'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'
import { ISwagItem } from '../stores/swagbag'
import useSwagBag from './useSwagBag'
import { useSetVideo } from './VideoController'

export default function HeaderSearch({ onSearch }: { onSearch: (value: string) => any }) {
  const [searchText, setSearchText] = useState('')
  const [expanded, setExpanded] = useState(false)
  const location = useLocation()
  const { sponsorsList, documents, videos, fetchFullSponsors } = useRootData(
    (store) => store.sponsorsStore
  )
  const swagbag = useSwagBag()

  useEffect(() => {
    fetchFullSponsors()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSearchText('')
  }, [location])

  const handleCollapse = () => {
    setSearchText('')
    setExpanded(false)
  }

  const [count, foundSponsors, foundDocuments, foundVideos] = useMemo(() => {
    const sponsors = fuzzy
      .go(searchText, sponsorsList, { key: 'company', limit: 4 })
      .map((e) => e.obj)
    const docs = fuzzy.go(searchText, documents(), { key: 'title', limit: 4 }).map((e) => e.obj)
    const vids = fuzzy.go(searchText, videos(), { key: 'title', limit: 4 }).map((e) => e.obj)
    const n = sponsors.length + vids.length + docs.length

    return [n, sponsors, docs, vids]
  }, [searchText, sponsorsList, documents, videos])

  const emptyResults = count < 1

  return (
    <InputWrapper>
      <OutsideClickHandler onOutsideClick={handleCollapse}>
        <SearchInput
          type="text"
          value={searchText}
          placeholder="Search"
          onChange={(e) => setSearchText(e.currentTarget.value)}
          onKeyPress={(e) => e.key === 'Enter' && onSearch(searchText)}
          onFocus={() => setExpanded(true)}
          expanded={expanded}
          attached={searchText.length > 0}
        />
        <SearchButton title="Open full search" onClick={() => onSearch(searchText)}>
          <SearchIcon size="18" color={expanded ? '#333' : '#fff'} />
        </SearchButton>

        {searchText && (
          <Results>
            {emptyResults && <EmptyText>Nothing found</EmptyText>}

            {foundSponsors.length > 0 && (
              <>
                <Title>Companies</Title>
                {foundSponsors.map((sponsor) => (
                  <CompanyCard key={sponsor.id} sponsor={sponsor} />
                ))}
              </>
            )}

            {foundDocuments.length > 0 && (
              <>
                <Title>Documents</Title>
                {foundDocuments.map((doc) => (
                  <DocumentCard
                    key={doc.id}
                    document={doc}
                    addSwagItem={swagbag.addItem}
                    addedToBag={swagbag.contains(doc.id)}
                  />
                ))}
              </>
            )}

            {foundVideos.length > 0 && (
              <>
                <Title>Videos</Title>
                {foundVideos.map((video) => (
                  <VideoCard
                    key={video.id}
                    video={video}
                    addSwagItem={swagbag.addItem}
                    addedToBag={swagbag.contains(video.id)}
                  />
                ))}
              </>
            )}

            <SeeAllButton onClick={() => onSearch(searchText)}>
              {emptyResults ? 'Go to Search page' : 'See all results'}
            </SeeAllButton>
          </Results>
        )}
      </OutsideClickHandler>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 15px;
  width: 100%;
  max-width: 400px;

  ${tablet} {
    display: none;
  }

  > div {
    display: flex;
    justify-content: flex-end;
  }
`
const SearchInput = styled.input<{ expanded?: boolean; attached?: boolean }>`
  width: ${({ expanded }) => (expanded ? '100%' : '200px')};
  padding: 10px 15px;
  font-size: 16px;
  background-color: ${({ expanded }) => (expanded ? '#fff' : '#7691e4')};
  border: none;
  border-radius: ${({ attached }) => (attached ? '4px 4px 0 0' : '4px')};
  transition: width 0.15s ease-in-out, background-color 0.15s;
  outline: none;

  &::placeholder {
    color: ${({ expanded }) => (expanded ? '#666' : '#fff')};
  }
`

const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: 50px;
  cursor: pointer;

  svg {
    width: 25px;
  }
`
const SeeAllButton = styled.button`
  background: #456dde;
  color: #fff;
  padding: 10px 15px;
  margin: 10px auto;
  border-radius: 5px;
  display: block;
  border: none;
  cursor: pointer;

  &:hover {
    text-shadow: 0 0 1px #fff;
  }
`

const CompanyCard = ({ sponsor }: { sponsor: ISponsor }) => (
  <CompanyWrapper to={`/sponsor/${sponsor.id}/`}>
    <Logo img={sponsor.logoUrl || sponsor.logoWhiteUrl} />
    <SponsorTitle>{sponsor.company}</SponsorTitle>
  </CompanyWrapper>
)

const CompanyWrapper = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  margin: 5px 0;
  cursor: pointer;
  overflow: hidden;
  color: #333;
  text-decoration: none;
  transition: color 0.15s, background-color 0.15s;

  &:hover {
    color: #fff;
    background-color: #456dde;
  }
`

const Logo = styled.div<{ img: string }>`
  flex-shrink: 0;
  width: 70px;
  height: 30px;
  background: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const SponsorTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  word-break: break-word;
`

interface IDocumentCardProps {
  document: ISponsorContentItem
  addSwagItem: (item: ISwagItem) => Promise<any>
  addedToBag: boolean
}

export const DocumentCard = ({ document, addSwagItem, addedToBag }: IDocumentCardProps) => {
  return (
    <DocumentCardWrapper>
      <DocumentBody>
        <File />
        <DocumentTitle href={document.url}>{document.title}</DocumentTitle>
        <ViewButton href={document.url}>
          <Eye size="18" />
        </ViewButton>
        <BagButton
          size="18"
          className={addedToBag ? 'bagDisabled' : ''}
          onClick={() => addSwagItem(document)}
        />
      </DocumentBody>
    </DocumentCardWrapper>
  )
}

const DocumentCardWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color: #333;
  transition: color 0.15s, background-color 0.15s;

  &:hover {
    color: #fff;
    background-color: #456dde;

    & svg {
      color: #fff !important;
    }
  }
`

const DocumentBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`

const DocumentTitle = styled.a`
  text-decoration: none;
  width: 60%;
  color: unset;
  font-size: 16px;
  padding-right: 10px;
  margin: 0 auto 0 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > svg {
    color: #333;
  }

  ${DocumentCardWrapper}:hover {
    color: #fff;
  }
`

const BagButton = styled(Briefcase)`
  color: #456dde;
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
  &.bagDisabled {
    cursor: default;
    pointer-events: none;
    stroke: #ccc;
  }
`

const ViewButton = styled.a`
  color: #456dde;
  height: 18px;
  margin-left: 10px;
`

interface IVideoCardProps {
  video: ISponsorContentItem
  addSwagItem: (item: ISwagItem) => Promise<any>
  addedToBag: boolean
}

export const VideoCard = ({ video, addedToBag, addSwagItem }: IVideoCardProps) => {
  const setVideo = useSetVideo()
  const openPopup = () => setVideo(video.url)

  return (
    <VideoCardWrapper>
      <Video onClick={openPopup}>
        {video.thumbnail && <img src={video.thumbnail} alt={video.title} />}
        <Play color="#333" />
      </Video>
      <VideoDetails>
        <VideoTitle onClick={openPopup}>{video.title}</VideoTitle>
        <BagButton className={addedToBag ? 'bagDisabled' : ''} onClick={() => addSwagItem(video)} />
      </VideoDetails>
    </VideoCardWrapper>
  )
}

const VideoCardWrapper = styled.div`
  width: 100%;
  padding: 10px 15px;
  margin: 5px 0;
  display: flex;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: center;
  color: #333;
  cursor: pointer;
  transition: color 0.15s, background-color 0.15s;

  &:hover {
    color: #fff;
    background-color: #456dde;

    & svg {
      color: #fff !important;
    }
  }
`

const Video = styled.div`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  opacity: 0.8;
  background: #ccc;

  &:hover {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    fill: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const VideoDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: calc(100% - 100px - 15px);
  margin-left: 10px;
`

const VideoTitle = styled.div`
  text-decoration: none;
  width: 60%;
  color: unset;
  font-size: 16px;
  padding-right: 10px;
  margin: 0 auto 0 5px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Results = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 400px;
  background: white;
  overflow-y: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 4px 4px;
  z-index: 2;
`

const EmptyText = styled.p`
  padding: 20px 15px;
  color: #333;
`

const Title = styled.span`
  width: 100%;
  display: table;
  font-size: 14px;
  padding: 10px 15px;
  text-align: center;
  white-space: nowrap;
  color: #999;

  &:before,
  &:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, #ccc 0, #ccc 100%);
    background-size: 100% 1px;
  }
  &:before {
    background-position: -10px 8px;
  }
  &:after {
    background-position: 10px 8px;
  }
`
