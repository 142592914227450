import React, { memo } from 'react'
import { Eye, FileText, Video } from 'react-feather'
import { ISponsorContentItem } from 'stores/sponsors'
import { ISwagItem } from 'stores/swagbag'
import styled from 'styled-components/macro'
import { tablet } from 'utils/responsive'
import AddToGoodyBag from './AddToGoodyBag'

interface IDocumentCardProps {
  addSwagItem: (item: ISwagItem) => Promise<any>
  document: ISponsorContentItem
  onDocumentOpen?: () => any
  addedToSwag: boolean
}

export default memo(
  ({ document, addSwagItem, onDocumentOpen, addedToSwag }: IDocumentCardProps) => {
    return (
      <CardWrapper>
        <ItemIcon>
          {document.contentType === 'Content' ? (
            <FileText size="72" strokeWidth="1" />
          ) : (
            <Video size="72" strokeWidth="1" />
          )}
        </ItemIcon>
        <Details>
          <Title>{document.title}</Title>
          <Footer>
            <View
              href={document.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onDocumentOpen}
              className="ga_document_click"
              data-ga-id={document.id}
            >
              <Eye color="#3c62d9" />
              <span>View</span>
            </View>
            <AddToGoodyBag
              gaClass="ga_bag_document_click"
              onClick={addedToSwag ? undefined : () => addSwagItem(document)}
              addedToSwag={addedToSwag}
              data-ga-id={document.id}
            />
          </Footer>
        </Details>
      </CardWrapper>
    )
  }
)

const CardWrapper = styled.div`
  padding: 4px 10px 15px;
  display: flex;
  text-align: left;

  ${tablet} {
    width: 100%;
    margin: 0 0 20px;
  }
`

const ItemIcon = styled.div`
  padding: 0 10px 0 0;

  svg {
    color: #666;

    ${tablet} {
      width: 50px;
      height: auto;
    }
  }
`

const Title = styled.div`
  margin: 0 0 15px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
  text-decoration: none;
  text-overflow: ellipsis;
`

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const Footer = styled.div`
  display: flex;
`

const View = styled.a`
  margin: 0 10px 0 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3c62d9;
  background-color: #fff;
  border: 1px solid #3c62d9;
  border-radius: 40px;
  text-decoration: none;
  height: 40px;

  span {
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
  }

  ${tablet} {
    padding: 0 15px;
  }
`
